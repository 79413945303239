/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Dictionary } from '../model/dictionary';
import { ErrorPhotoUploadResponse } from '../model/errorPhotoUploadResponse';
import { PhotoFile } from '../model/photoFile';
import { Translation } from '../model/translation';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserToolsService {

    protected basePath = 'https://virtserver.swaggerhub.com/e-Lunch.pl/kiosk/1.0.1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * get dictionary tool
     * 
     * @param userId 
     * @param type 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDictionary(userId: number, type: string, language: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Dictionary>>;
    public getDictionary(userId: number, type: string, language: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Dictionary>>>;
    public getDictionary(userId: number, type: string, language: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Dictionary>>>;
    public getDictionary(userId: number, type: string, language: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getDictionary.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getDictionary.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getDictionary.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Dictionary>>('get',`${this.basePath}/user/tools/getDictionary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get translate tool
     * 
     * @param userId 
     * @param id 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTranslate(userId: number, id: number, type: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Translation>>;
    public getTranslate(userId: number, id: number, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Translation>>>;
    public getTranslate(userId: number, id: number, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Translation>>>;
    public getTranslate(userId: number, id: number, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getTranslate.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTranslate.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getTranslate.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Translation>>('get',`${this.basePath}/user/tools/getTranslate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * translate tool
     * 
     * @param userId 
     * @param from 
     * @param to 
     * @param text 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trasnalteText(userId: number, from: string, to: string, text: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public trasnalteText(userId: number, from: string, to: string, text: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public trasnalteText(userId: number, from: string, to: string, text: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public trasnalteText(userId: number, from: string, to: string, text: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling trasnalteText.');
        }

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling trasnalteText.');
        }

        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling trasnalteText.');
        }

        if (text === null || text === undefined) {
            throw new Error('Required parameter text was null or undefined when calling trasnalteText.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (text !== undefined && text !== null) {
            queryParameters = queryParameters.set('text', <any>text);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('post',`${this.basePath}/user/tools/translate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * upload photo
     * 
     * @param userId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadPhotoForm(userId: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<PhotoFile>;
    public uploadPhotoForm(userId: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PhotoFile>>;
    public uploadPhotoForm(userId: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PhotoFile>>;
    public uploadPhotoForm(userId: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling uploadPhoto.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<PhotoFile>('post',`${this.basePath}/user/tools/uploadPhoto`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
