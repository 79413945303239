import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastService} from '../../_notification/toast.service';
import {UserAuthService} from '../../../api';
import {TranslateService} from '@ngx-translate/core';
import {AppService} from '../../_app/app.service';
import {Preferences} from '../../_interfaces/preferences';
import {takeUntil, take, map, tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.page.html',
    styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage implements OnInit, OnDestroy {


    public onForgotPasswordForm: FormGroup;
    public preferences: Preferences;
    private readonly destroy$: Subject<void> = new Subject();

    constructor(private appService: AppService,
                private translate: TranslateService,
                private userAuth: UserAuthService,
                private toast: ToastService,
                private modalController: ModalController,
                private formBuilder: FormBuilder) {
    }


    ngOnInit() {


        this.onForgotPasswordForm = this.formBuilder.group({
            'login': [null, Validators.compose([
                Validators.required
            ])]
        });

        this.appService.currentPreferences.pipe(take(1), takeUntil(this.destroy$)).subscribe(res => {

            this.preferences = res;
        });

    }

    resetPassword() {
        if (!this.onForgotPasswordForm.valid) {
            this.toast.show(this.translate.instant('ALERT.Wprowadz-poprawne-dane'));
        } else {

            this.userAuth.userForgotPassword(this.onForgotPasswordForm.value.login, environment.newPasswordHost + '/' + this.preferences.language + '/{TOKEN}', this.preferences.language).subscribe(next => {
                    this.toast.show(this.translate.instant('ALERT_SUCCESS.Sprawdz-poczte-e-mail-Wyslalismy-wiadomosc-z-linke'), null, 10000, 'top', 'success');
                    this.closeModal();
                },
                error2 => {
                    this.toast.show(this.translate.instant('ERRORS.Login-nie-zostal-odnaleziony-w-naszej-bazie-danych'), null, 2000, 'top', 'danger');
                });
        }
    }

    async closeModal() {

        await this.modalController.dismiss();
    }


    ngOnDestroy(): void {

        this.destroy$.next();
        this.destroy$.complete();
    }
}
