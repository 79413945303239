import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Complaint} from '../../../api';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-complaint',
    templateUrl: './complaint.page.html',
    styleUrls: ['./complaint.page.scss'],
})
export class ComplaintPage implements OnInit {
    @Input() complaint: Complaint;

    constructor(private modalController: ModalController,
                private translate: TranslateService) {
    }

    ngOnInit() {
    }

    async closeModal() {

        await this.modalController.dismiss();

    }

}
