/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type UpdateUserProfileErrorInfo = 'ERRORS.DUPLICATED_LOGIN' | 'ERRORS.GENERAL_FAILURE';

export const UpdateUserProfileErrorInfo = {
    DUPLICATEDLOGIN: 'ERRORS.DUPLICATED_LOGIN' as UpdateUserProfileErrorInfo,
    GENERALFAILURE: 'ERRORS.GENERAL_FAILURE' as UpdateUserProfileErrorInfo
};