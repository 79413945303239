import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ToastService} from '../../_notification/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {IonInput, ModalController} from '@ionic/angular';
import {User, UserAuthService, UserProfileService} from '../../../api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-update-card-number',
    templateUrl: './update-card-number.page.html',
    styleUrls: ['./update-card-number.page.scss'],
})
export class UpdateCardNumberPage implements OnInit {

    public cardForm: FormGroup;
    @Input() user: User;
    @ViewChild('inputId', {static: false}) inputElement: IonInput;

    constructor(private translate: TranslateService,
                private userAuth: UserAuthService,
                private toast: ToastService,
                private userProfile: UserProfileService,
                private formBuilder: FormBuilder,
                private modalController: ModalController) {
    }

    ngOnInit() {
        this.cardForm = this.formBuilder.group({
            'cardNumber': [null, Validators.compose([
                Validators.required,
            ])]
        });
    }

    ngAfterViewInit() {
        if (this.inputElement) {
            setTimeout(() => {
                this.inputElement.setFocus();
            }, 500);
        }
    }

    updateCard() {
        if (!this.cardForm.valid) {
            this.toast.show(this.translate.instant('ALERT.Wczytaj-swoja-karte'));
            this.inputElement.setFocus();
        } else {
            this.userProfile.userProfileUpdateCardNumber(this.user.id, this.cardForm.value.cardNumber).subscribe((res) => {
                this.toast.show(this.translate.instant('AUTH.Twoja-karta-zostala-aktywowana'), this.translate.instant('AUTH.Od-teraz-mozesz-uzywac-jej-do-logowania'), 5000, 'top', 'success');
                this.closeModal();

            }, error2 => {
                this.cardForm.reset();
                this.inputElement.setFocus();
                if (error2.status !== 401 && error2.status !== 403) {
                    if (error2.error === 'ERRORS.DUPLICATED_LOGIN') {
                        this.toast.show(this.translate.instant('AUTH.Blad-aktywacji-karty'), this.translate.instant('AUTH.Karta-jest-juz-przypisana-do-innego-konta'), 4000, 'top', 'danger');
                    } else {
                        this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-przesylania-danych-dane-nie'), null, 2000, 'top', 'danger');
                    }
                } else {
                    this.closeModal();
                }


            });

        }
    }
    resetForm() {
        this.cardForm.reset();
        this.inputElement.setFocus();
    }

    focusCardNumberInput(event) {

        if (!event.target.id) {
            this.inputElement.setFocus();

        }
        return true;
    }

    async closeModal() {
        await this.modalController.dismiss();
    }

}
