import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventService {

    private refreshAfterDeletedOrderAnnouncedSource = new Subject();
    refreshAfterDeletedOrderSource$ = this.refreshAfterDeletedOrderAnnouncedSource.asObservable();

    constructor() {
    }

    publishRefreshAfterDeletedOrder() {
        this.refreshAfterDeletedOrderAnnouncedSource.next();
    }



}

