import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../_app/app.service';
import {ToastService} from '../../_notification/toast.service';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserAuthService, UserInvoiceAddress, UserProfileService} from '../../../api';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-invoice-address',
    templateUrl: './invoice-address.page.html',
    styleUrls: ['./invoice-address.page.scss'],
})
export class InvoiceAddressPage implements OnInit, OnDestroy {

    private readonly destroy$: Subject<void> = new Subject();
    public invoiceAddressForm: FormGroup;
    public invoiceAddress: UserInvoiceAddress;
    public companyType: string;
    @Input() userId;
    @Input() invoiceAddresUserEdit: UserInvoiceAddress;
    @Input() fromProfileEdit: boolean;

    constructor(private appService: AppService,
                private translate: TranslateService,
                private toast: ToastService,
                private userProfile: UserProfileService,
                private modalController: ModalController,
                private formBuilder: FormBuilder) {
    }

    // TODO obsluga w profilu - zapisuje niepotrzebnie zmiany po wejsciu ponownym. tlumaczenia, mobile, nowy user adres


    ngOnInit() {
        this.companyType = 'company';
        this.invoiceAddressForm = this.formBuilder.group({
            'companyName': [null, Validators.compose([
                Validators.required
            ])],
            // TODO validator NIP
            'taxId': [null, Validators.compose([
                Validators.required
            ])],
            'zipCode': [null, Validators.compose([
                Validators.required
            ])],
            'city': [null, Validators.compose([
                Validators.required
            ])],
            'street': [null, Validators.compose([
                Validators.required
            ])],
            'isInvoiceNeeded': [null]
        });

        if (this.invoiceAddresUserEdit) {
            this.invoiceAddress = this.invoiceAddresUserEdit;
            this.updateInputsValidators();
        } else if(this.userId) {

            this.userProfile.getUserInvoiceAddress(this.userId).subscribe(res => {
                if (res) {
                    if (res.isInvoiceNeeded) {
                        this.invoiceAddress = res;
                        this.updateInputsValidators();
                    }
                }

            }, error2 => {
                if (error2.status !== 401 && error2.status !== 403 && error2.status !== 404) {
                    this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
                }
            });
        }
    }

    updateInputsValidators() {
        this.invoiceAddressForm.get('companyName').setValue(this.invoiceAddress.copmanyName);
        this.invoiceAddressForm.get('companyName').updateValueAndValidity();


        if (this.invoiceAddress.taxId) {
            this.invoiceAddressForm.get('taxId').setValue(this.invoiceAddress.taxId);
            this.invoiceAddressForm.get('taxId').updateValueAndValidity();
            this.companyType = 'company';

        } else {
            this.invoiceAddressForm.get('taxId').setValue('');
            this.invoiceAddressForm.get('taxId').updateValueAndValidity();
            this.companyType = 'person';

        }
        this.checkValidators();

        this.invoiceAddressForm.get('zipCode').setValue(this.invoiceAddress.zipCode);
        this.invoiceAddressForm.get('zipCode').updateValueAndValidity();

        this.invoiceAddressForm.get('city').setValue(this.invoiceAddress.city);
        this.invoiceAddressForm.get('city').updateValueAndValidity();

        this.invoiceAddressForm.get('street').setValue(this.invoiceAddress.street);
        this.invoiceAddressForm.get('street').updateValueAndValidity();
    }

    updateInvoiceAddress() {

        if (!this.invoiceAddressForm.valid) {


            this.toast.show(this.translate.instant('APP.Prosze-uzupelnij-wymagane-pola'));
        } else {
            this.invoiceAddress = {
                copmanyName: this.invoiceAddressForm.value.companyName.trim(),
                taxId: (this.invoiceAddressForm.value.taxId) ? this.invoiceAddressForm.value.taxId.trim() : null,
                zipCode: this.invoiceAddressForm.value.zipCode.trim(),
                city: this.invoiceAddressForm.value.city.trim(),
                street: this.invoiceAddressForm.value.street.trim(),
                isInvoiceNeeded: true
            }

            if (this.invoiceAddressForm.value.isInvoiceNeeded || this.fromProfileEdit) {
                this.userProfile.updateUserInvoiceAddress(this.invoiceAddress, this.userId).subscribe(res => {

                    this.toast.show(this.translate.instant('ALERT_SUCCESS.Zmiany-zostaly-zapisane'), null, 2000, 'top', 'success');
                    this.closeModal();

                }, error2 => {
                    if (error2.status !== 401 && error2.status !== 403 && error2.status !== 404) {
                        this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-przesylania-danych-dane-nie'), null, 2000, 'top', 'danger');
                    }
                });
            } else {
                this.closeModal();
            }
        }

    }

    invoiceType(event) {
        this.checkValidators();

    }


    checkValidators() {
        if (this.companyType === 'person') {
            this.invoiceAddressForm.get('taxId').setValue('');
            this.invoiceAddressForm.get('taxId').clearValidators();
        } else {
            this.invoiceAddressForm.get('taxId').setValidators(Validators.compose([
                Validators.required
            ]));
        }
        this.invoiceAddressForm.get('taxId').updateValueAndValidity();
    }


    async closeModal(cancel?: boolean) {
        if (cancel) {
            await this.modalController.dismiss();
        }
        else {
            await this.modalController.dismiss(this.invoiceAddress);
        }
    }


    ngOnDestroy(): void {

        this.destroy$.next();
        this.destroy$.complete();
    }

}
