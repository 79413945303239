import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {BankListPage} from './bank-list.page';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {TranslateModule} from '@ngx-translate/core';

const routes: Routes = [
    {
        path: '',
        component: BankListPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        IonicModule,
        RouterModule.forChild(routes)
    ],
    providers: [
        InAppBrowser // add
    ],
    declarations: [BankListPage]
})
export class BankListPageModule {
}
