import {Component, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
    selector: 'app-terms',
    templateUrl: './terms.page.html',
    styleUrls: ['./terms.page.scss'],
})
export class TermsPage implements OnInit {

    public body: any;
    public title: string;

    constructor(private sanitizer: DomSanitizer,
                private http: HttpClient,
                private modalController: ModalController,
                private navParams: NavParams) {
    }

    ngOnInit() {

        this.title = this.navParams.data.title;

        let url = this.navParams.data.body.match(/\bhttps?:\/\/\S+/gi);
        url = url[0].replace('"', '');
        this.body = this.sanitizer.bypassSecurityTrustResourceUrl(url);

        // this.http.get(this.body, {responseType: 'text'}).subscribe(data => {
        //     this.body = data;
        // });
    }

    async closeModal() {

        await this.modalController.dismiss();
    }
}
