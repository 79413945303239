import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrdersSimpleService {

    private _reloadOrders = new BehaviorSubject(false);


    get reloadOrders(): BehaviorSubject<boolean> {
        return this._reloadOrders;
    }

    set reloadOrders(value: BehaviorSubject<boolean>) {
        this._reloadOrders = value;
    }

    constructor() {
    }


}
