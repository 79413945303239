import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {CartService} from '../../_cart/cart.service';
import {AlertController} from '@ionic/angular';
import {Observable, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../_notification/toast.service';
import {take, takeUntil, tap} from 'rxjs/operators';
import {User} from '../../../api';
import {AuthService} from '../../_auth/auth.service';

// https://stackoverflow.com/questions/44130580/generic-type-candeactivatet-requires-1-type-arguments
export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: 'root'
})

export class CanDeactiveAgreementsService implements CanDeactivate<CanComponentDeactivate> {
    public user$: Observable<User>;
    private user: User;
    private readonly destroy$: Subject<void> = new Subject();


    constructor(private translate: TranslateService,
                private authService: AuthService,
                private toast: ToastService) {

        this.authService.currentUser.pipe().subscribe(user => this.user = user);


    }

    canDeactivate() {
        if (!this.user) {
            return true;
        }
        let isChecked: boolean = true;
        this.user.agreements.forEach(item => {

            if (!item.isChecked) {
                isChecked = false;
                false;
            }
        });
        if (isChecked) {
            return true;
        }

        this.toast.show(this.translate.instant('AGREEMENTS.Informacja'), this.translate.instant('AGREEMENTS.Wymagane-zgody-oznaczone-znakiem-sa-obowiazkowe-do'));
        return false;
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }


}
