import {Component, Input, OnInit} from '@angular/core';
import {AlertController, LoadingController, ModalController, Platform} from '@ionic/angular';
import {Bank, Company, OrderService, UserProfileService} from '../../../api';
import {ToastService} from '../../_notification/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {InAppBrowserEvent} from '@ionic-native/in-app-browser';

@Component({
    selector: 'app-bank-list',
    templateUrl: './bank-list.page.html',
    styleUrls: ['./bank-list.page.scss'],
})
export class BankListPage implements OnInit {
    @Input() email;
    @Input() firstSecondName;
    @Input() orderId;
    @Input() toPayValue;
    @Input() isLunchMenu;
    @Input() restaurantId;
    @Input() lang;
    public company: Company;
    public bankList: Bank[];
    public bankGroupId: number;
    public regunations: any;
    private loading: any;

    constructor(private modalController: ModalController,
                private iab: InAppBrowser,
                private platform: Platform,
                public loadingController: LoadingController,
                private orderService: OrderService,
                private alertController: AlertController,
                private userProfile: UserProfileService,
                private translate: TranslateService,
                public toast: ToastService) {
    }

    ngOnInit() {

        this.userProfile.getCompanyObject(this.restaurantId).subscribe(res => {
            this.company = res;
        }, error2 => {
            this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
        });

        this.orderService.getBanksListObject(this.restaurantId, true, this.lang).subscribe(res => {
            this.bankList = res;
        }, error2 => {
            this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
        });

    }

    openRegulations() {
        const browser = this.iab.create(this.company.regulationUrlFile, '_system', 'location=yes,closebuttoncaption=' + this.translate.instant('APP.Zamknij').toUpperCase() + ',toolbarcolor=#212737,footer=no,hidenavigationbuttons=yes,hideurlbar=yes,zoom=no,closebuttoncolor=#ffffff');
    }


    async closeAlert() {
        const alert = await this.alertController.create({
            header: this.translate.instant('APP.Czy-napewno-chcesz-zamknac-platnosc'),
            message: this.translate.instant('APP.Twoje-zamowienie-nie-jest-jeszcze-oplacone-i-nie-z'),
            buttons: [
                {
                    text: this.translate.instant('APP.Nie'),
                    role: 'cancel',
                    handler: () => {

                    }
                }, {
                    text: this.translate.instant('APP.Tak'),
                    handler: () => {
                        this.closeModal(false);
                    }
                }
            ]
        });

        await alert.present();
    }

    setBank(bank: Bank) {
        this.bankGroupId = bank.id;
    }

    async closeModal(paymentInit?: boolean) {
        await this.modalController.dismiss(paymentInit);

    }

    payOrder() {
        if (!this.bankGroupId) {
            this.toast.show(this.translate.instant('APP.Prosze-wybrac-bank-z-listy'));
        } else if (!this.regunations) {
            this.toast.show(this.translate.instant('PAYMENTS.Akceptacja-regulaminu-jest-wymagana'));
        } else {

            this.presentLoading();
            this.orderService.payOrder(this.email, this.firstSecondName, this.orderId, this.restaurantId, this.toPayValue, this.bankGroupId, (this.platform.is('cordova')) ? window.location.href : window.location.origin + '/#/my-restaurants/paymentConfirm', this.isLunchMenu, this.lang).subscribe(res => {

                this.toast.show(this.translate.instant('PAYMENTS.Za-chwile-zostaniesz-przekierowany-na-strone-banku'));
                this.closeModal(true);

                const browser = this.iab.create(res, '_blank', 'location=yes,closebuttoncaption=' + this.translate.instant('APP.Zamknij').toUpperCase() + ',toolbarcolor=#212737,footer=no,hidenavigationbuttons=yes,hideurlbar=yes,zoom=no,closebuttoncolor=#ffffff');
                browser.on('loadstart').subscribe((event: InAppBrowserEvent) => {
                    if (event.url === window.location.href) {
                        browser.close();
                    }
                });


            }, error2 => {
                if (error2.status !== 401 && error2.status !== 403 && error2.status === 500) {
                    this.toast.show(this.translate.instant(error2.error), null, 2000, 'top', 'danger');
                } else if (error2.status === 500) {
                    this.toast.show('(Error 500) ' + this.translate.instant('CART.Skontaktuj-sie-z-pomoca-techniczna'), null, 2000, 'top', 'danger');
                }
                else if (error2.status === 400) {
                    this.toast.show('(Error 400) ' + this.translate.instant('CART.Skontaktuj-sie-z-pomoca-techniczna'), null, 2000, 'top', 'danger');
                }
                this.loading.dismiss();
            });
        }
    }

    async presentLoading() {

        this.loading = await this.loadingController.create({
            //  cssClass: '',
            mode: 'md',
            duration: 10000,
            message: this.translate.instant('APP.Prosze-czekac') + '...'
        });
        await this.loading.present();

    }

}
