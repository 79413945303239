/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Company } from '../model/company';
import { Restaurant } from '../model/restaurant';
import { Shift } from '../model/shift';
import { UpdateUserProfileErrorInfo } from '../model/updateUserProfileErrorInfo';
import { UserAgreement } from '../model/userAgreement';
import { UserFundingsLimits } from '../model/userFundingsLimits';
import { UserInvoiceAddress } from '../model/userInvoiceAddress';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserProfileService {

    protected basePath = 'https://virtserver.swaggerhub.com/e-Lunch.pl/kiosk/1.0.1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * get client&#x27;s shifts
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientShifts(userId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Shift>>;
    public getClientShifts(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Shift>>>;
    public getClientShifts(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Shift>>>;
    public getClientShifts(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getClientShifts.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Shift>>('get',`${this.basePath}/user/clientShifts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get company
     * 
     * @param userId 
     * @param restaurantId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompany(userId: number, restaurantId?: number, observe?: 'body', reportProgress?: boolean): Observable<Company>;
    public getCompany(userId: number, restaurantId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Company>>;
    public getCompany(userId: number, restaurantId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Company>>;
    public getCompany(userId: number, restaurantId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getCompany.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Company>('get',`${this.basePath}/user/companies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get company
     * 
     * @param restaurantId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyObject(restaurantId: number, userId?: number, observe?: 'body', reportProgress?: boolean): Observable<Company>;
    public getCompanyObject(restaurantId: number, userId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Company>>;
    public getCompanyObject(restaurantId: number, userId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Company>>;
    public getCompanyObject(restaurantId: number, userId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getCompanyObject.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Company>('get',`${this.basePath}/user/company`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get user agreements
     * 
     * @param userId 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserAgreements(userId: number, language: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserAgreement>>;
    public getUserAgreements(userId: number, language: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserAgreement>>>;
    public getUserAgreements(userId: number, language: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserAgreement>>>;
    public getUserAgreements(userId: number, language: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserAgreements.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getUserAgreements.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserAgreement>>('get',`${this.basePath}/user/agreements`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get user fundings and limits
     * 
     * @param userId 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserFundingsLimits(userId: number, language: string, observe?: 'body', reportProgress?: boolean): Observable<UserFundingsLimits>;
    public getUserFundingsLimits(userId: number, language: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserFundingsLimits>>;
    public getUserFundingsLimits(userId: number, language: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserFundingsLimits>>;
    public getUserFundingsLimits(userId: number, language: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserFundingsLimits.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getUserFundingsLimits.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserFundingsLimits>('get',`${this.basePath}/user/fundingsLimits`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get invoice address
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserInvoiceAddress(userId: number, observe?: 'body', reportProgress?: boolean): Observable<UserInvoiceAddress>;
    public getUserInvoiceAddress(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserInvoiceAddress>>;
    public getUserInvoiceAddress(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserInvoiceAddress>>;
    public getUserInvoiceAddress(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserInvoiceAddress.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserInvoiceAddress>('get',`${this.basePath}/user/profile/getInvoiceAddress`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get user restaurants
     * 
     * @param userId 
     * @param shiftId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserRestaurants(userId: number, shiftId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Restaurant>>;
    public getUserRestaurants(userId: number, shiftId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Restaurant>>>;
    public getUserRestaurants(userId: number, shiftId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Restaurant>>>;
    public getUserRestaurants(userId: number, shiftId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserRestaurants.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (shiftId !== undefined && shiftId !== null) {
            queryParameters = queryParameters.set('shiftId', <any>shiftId);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Restaurant>>('get',`${this.basePath}/user/restaurants`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update UUID
     * 
     * @param userId 
     * @param UUID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUUID(userId: number, UUID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateUUID(userId: number, UUID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateUUID(userId: number, UUID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateUUID(userId: number, UUID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling updateUUID.');
        }

        if (UUID === null || UUID === undefined) {
            throw new Error('Required parameter UUID was null or undefined when calling updateUUID.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (UUID !== undefined && UUID !== null) {
            queryParameters = queryParameters.set('UUID', <any>UUID);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/user/profile/updateUUID`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update invoice address
     * 
     * @param body 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserInvoiceAddress(body: UserInvoiceAddress, userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateUserInvoiceAddress(body: UserInvoiceAddress, userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateUserInvoiceAddress(body: UserInvoiceAddress, userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateUserInvoiceAddress(body: UserInvoiceAddress, userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateUserInvoiceAddress.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling updateUserInvoiceAddress.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/user/profile/invoiceAddress`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update user agreements
     * 
     * @param body 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userAgreementsUpdate(body: Array<UserAgreement>, userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userAgreementsUpdate(body: Array<UserAgreement>, userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userAgreementsUpdate(body: Array<UserAgreement>, userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userAgreementsUpdate(body: Array<UserAgreement>, userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling userAgreementsUpdate.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userAgreementsUpdate.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/user/agreements/update`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * email activation
     * 
     * @param userId 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userEmailActivation(userId: number, language: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userEmailActivation(userId: number, language: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userEmailActivation(userId: number, language: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userEmailActivation(userId: number, language: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userEmailActivation.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling userEmailActivation.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/user/profile/emailActivation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * email activation code
     * 
     * @param userId 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userEmailActivationCode(userId: number, code: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userEmailActivationCode(userId: number, code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userEmailActivationCode(userId: number, code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userEmailActivationCode(userId: number, code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userEmailActivationCode.');
        }

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling userEmailActivationCode.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/user/profile/emailActivation/code`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * email notification update
     * 
     * @param userId 
     * @param emailNotificationEnabled 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userEmailNotification(userId: number, emailNotificationEnabled: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userEmailNotification(userId: number, emailNotificationEnabled: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userEmailNotification(userId: number, emailNotificationEnabled: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userEmailNotification(userId: number, emailNotificationEnabled: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userEmailNotification.');
        }

        if (emailNotificationEnabled === null || emailNotificationEnabled === undefined) {
            throw new Error('Required parameter emailNotificationEnabled was null or undefined when calling userEmailNotification.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (emailNotificationEnabled !== undefined && emailNotificationEnabled !== null) {
            queryParameters = queryParameters.set('emailNotificationEnabled', <any>emailNotificationEnabled);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/user/profile/emailNotification`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * email notification language update
     * 
     * @param userId 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userEmailNotificationLanguage(userId: number, language: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userEmailNotificationLanguage(userId: number, language: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userEmailNotificationLanguage(userId: number, language: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userEmailNotificationLanguage(userId: number, language: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userEmailNotificationLanguage.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling userEmailNotificationLanguage.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/user/profile/emailNotificationLanguage`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * change user password
     * 
     * @param userId 
     * @param password 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userPasswordChange(userId: number, password: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userPasswordChange(userId: number, password: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userPasswordChange(userId: number, password: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userPasswordChange(userId: number, password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userPasswordChange.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling userPasswordChange.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/user/profile/changePassword`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update user profile
     * 
     * @param userId 
     * @param login 
     * @param firstName 
     * @param lastName 
     * @param phone 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userProfileUpdate(userId: number, login: string, firstName: string, lastName: string, phone?: string, email?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userProfileUpdate(userId: number, login: string, firstName: string, lastName: string, phone?: string, email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userProfileUpdate(userId: number, login: string, firstName: string, lastName: string, phone?: string, email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userProfileUpdate(userId: number, login: string, firstName: string, lastName: string, phone?: string, email?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userProfileUpdate.');
        }

        if (login === null || login === undefined) {
            throw new Error('Required parameter login was null or undefined when calling userProfileUpdate.');
        }

        if (firstName === null || firstName === undefined) {
            throw new Error('Required parameter firstName was null or undefined when calling userProfileUpdate.');
        }

        if (lastName === null || lastName === undefined) {
            throw new Error('Required parameter lastName was null or undefined when calling userProfileUpdate.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (phone !== undefined && phone !== null) {
            queryParameters = queryParameters.set('phone', <any>phone);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (login !== undefined && login !== null) {
            queryParameters = queryParameters.set('login', <any>login);
        }
        if (firstName !== undefined && firstName !== null) {
            queryParameters = queryParameters.set('firstName', <any>firstName);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/user/profile/update`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update user card number
     * 
     * @param userId 
     * @param cardNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userProfileUpdateCardNumber(userId: number, cardNumber: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userProfileUpdateCardNumber(userId: number, cardNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userProfileUpdateCardNumber(userId: number, cardNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userProfileUpdateCardNumber(userId: number, cardNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userProfileUpdateCardNumber.');
        }

        if (cardNumber === null || cardNumber === undefined) {
            throw new Error('Required parameter cardNumber was null or undefined when calling userProfileUpdateCardNumber.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (cardNumber !== undefined && cardNumber !== null) {
            queryParameters = queryParameters.set('cardNumber', <any>cardNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/user/profile/update/cardNumber`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update user email
     * 
     * @param userId 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userProfileUpdateEmail(userId: number, email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userProfileUpdateEmail(userId: number, email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userProfileUpdateEmail(userId: number, email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userProfileUpdateEmail(userId: number, email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userProfileUpdateEmail.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling userProfileUpdateEmail.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/user/profile/update/email`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
