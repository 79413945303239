import {Injectable} from '@angular/core';
import {AppService} from '../_app/app.service';
import {PoStorage} from '../_interfaces/poStorage';
import {Preferences} from '../_interfaces/preferences';
import {DatePipe} from '@angular/common';
import {AppLanguage, PublicOrdersService, Restaurant} from '../../api';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {skipWhile} from 'rxjs/internal/operators';

@Injectable({
    providedIn: 'root'
})
export class PoService {


    private preferences: Preferences;
    private poStorage: PoStorage;
    private readonly destroy$: Subject<void> = new Subject();


    public isRestaurant = new BehaviorSubject(false);

    constructor(public appService: AppService,
                private datePipe: DatePipe,
                private publicOrdersService: PublicOrdersService) {


        this.appService.currentPreferences.pipe(skipWhile(v => !v), takeUntil(this.destroy$)).subscribe(preferences => {
            this.preferences = preferences;
        });


        this.appService.currentPoStorage.pipe(skipWhile(v => !v), takeUntil(this.destroy$)).subscribe(po => {
            this.poStorage = po;
        });
    }


    async init() {


        if (!this.preferences) {

            const preferences: Preferences = {
                language: 'PL' as AppLanguage,
                menu: {
                    date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
                    isPublicMenu: true,
                }
            };
            this.appService.setPreferences(preferences);

        } else {

            const copyPreferences = JSON.parse(JSON.stringify(this.preferences));
            copyPreferences.menu = {
                date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
                isPublicMenu: true
            };


            this.appService.setPreferences(copyPreferences);

        }

    }

    async getRestaurant(id: number): Promise<any> {

        return new Promise((resolve, reject) => {
            this.init().then(on => {
                this.publicOrdersService.getRestaurant(id, 'PL').subscribe(res => {

                        const copyPreferences = JSON.parse(JSON.stringify(this.preferences));
                        copyPreferences.restaurants = [res];
                        copyPreferences.restaurantId = res.id;
                        this.appService.setPreferences(copyPreferences);

                        this.isRestaurant.next(false);
                        this.setRestaurant(res).then(on => {
                            resolve(true);
                        }).catch(rej => {
                            reject(false);
                        });

                    },
                    error => {

                        const copyPreferences = JSON.parse(JSON.stringify(this.preferences));
                        copyPreferences.restaurants = null;
                        copyPreferences.restaurantId = null;
                        this.appService.setPreferences(copyPreferences);
                        this.isRestaurant.next(true);
                        reject(false);


                    }
                );
            });
        });
    }


    async setRestaurant(restaurant?: Restaurant): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.poStorage) {

                const copyPoStorage = JSON.parse(JSON.stringify(this.poStorage));
                if (copyPoStorage.restaurants) {

                    // szukam czy restauracja jest juz zapisana w localStorage, jesli tak to robie jej update;
                    const targetIdx = copyPoStorage.restaurants.map(item => item.id).indexOf(restaurant.id);
                    if (targetIdx >= 0) {
                        copyPoStorage.restaurants[targetIdx] = restaurant;
                    } else {
                        copyPoStorage.restaurants.push(restaurant);
                    }

                } else {
                    copyPoStorage.restaurants = [restaurant];
                }
                this.appService.setPoStorage(copyPoStorage);
                resolve(true);
            } else {

                const poStorage: PoStorage = {
                    restaurants: [restaurant]
                }
                this.appService.setPoStorage(poStorage);
                resolve(true);
            }
        });
    }


    setDeliveryAddress(city: string, zipCode: string, street: string, buildingNumber: string) {
        // zamieniam znaki z parametrow przekazanych url
        const replaceChars = {'_': ' ', '|': '/', '+': ' '};

        const userDeliveryAddress: any = {

            city: city.replace(/\||_/g, function (match) {
                return replaceChars[match];
            }),
            zipCode: zipCode.replace(/\||_/g, function (match) {
                return replaceChars[match];
            }),
            street: street.replace(/\||_+/g, function (match) {
                return replaceChars[match];
            }),
            buildingNumber: buildingNumber.replace(/\||_/g, function (match) {
                return replaceChars[match];
            })

        }


        let copyPoStorage: PoStorage;

        if (this.poStorage) {
            copyPoStorage = JSON.parse(JSON.stringify(this.poStorage));
            copyPoStorage.deliveryAddress = userDeliveryAddress;
        } else {


            copyPoStorage = {
                deliveryAddress: userDeliveryAddress
            };
        }

        this.appService.setPoStorage(copyPoStorage);
    }

    addPickupPoint(restaurantId: number, pickupPointId: number) {

        let copyPoStorage: PoStorage;

        if (this.poStorage) {
            copyPoStorage = JSON.parse(JSON.stringify(this.poStorage));

            const point = {
                restaurantId: restaurantId,
                pickupPointId: pickupPointId
            }
            if (copyPoStorage.myPickupPoints) {
                if (!copyPoStorage.myPickupPoints.some(e => e.pickupPointId === pickupPointId && e.restaurantId === restaurantId)) {


                    copyPoStorage.myPickupPoints.push(point);
                }
            } else {
                copyPoStorage.myPickupPoints = [point];
            }

        } else {

            copyPoStorage = {
                myPickupPoints: [{
                    restaurantId: restaurantId,
                    pickupPointId: pickupPointId
                }]
            };
        }

        this.appService.setPoStorage(copyPoStorage);

    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();

    }


}
