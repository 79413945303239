import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {ApiModule, Configuration, ConfigurationParameters} from '../api';
import {BASE_PATH} from '../api';
import {IonicStorageModule} from '@ionic/storage';
import {GuardService} from './_auth/guard.service';
import {ErrorInterceptor} from './_class/error-interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {environment} from '../environments/environment';
import {CurrencyPipe, HashLocationStrategy, LocationStrategy} from '@angular/common';

import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HelpPageModule} from './_modal/help/help.module';
import {ForgotPasswordPageModule} from './_modal/forgot-password/forgot-password.module';
import {AuthService} from './_auth/auth.service';
import {DatePipe} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ComponentsMenuModule} from './menu/_components/components-menu.module';
import {UserIdleModule} from 'angular-user-idle';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';

import {registerLocaleData} from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localeUk from '@angular/common/locales/uk';
import {TermsPageModule} from './agreements/terms/terms.module';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {RateOrderPageModule} from './_modal/rate-order/rate-order.module';
import {OneSignal} from '@ionic-native/onesignal/ngx';
import {OpenNativeSettings} from '@ionic-native/open-native-settings/ngx';
import {InvoiceAddressPageModule} from './_modal/invoice-address/invoice-address.module';
import {AdvertisementPageModule} from './_modal/advertisement/advertisement.module';
import {FingerprintAIO} from '@ionic-native/fingerprint-aio/ngx';
import {Device} from '@ionic-native/device/ngx';
import {ComplaintPageModule} from './_modal/complaint/complaint.module';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';

import {Geolocation} from '@ionic-native/geolocation/ngx';
import {DeliveryAddressPageModule} from './_modal/delivery-address/delivery-address.module';
import {RestaurantInfoPageModule} from './_modal/restaurant-info/restaurant-info.module';
import {OrderPickupDetailPageModule} from './restaurantPanel/orders/order-pickup/order-pickup-detail/order-pickup-detail.module';
import {ContactlessPaymentPageModule} from './menu/_components/contactless-payment/contactless-payment.module';
//import {Deeplinks} from '@ionic-native/deeplinks';
import {OrderModule} from 'ngx-order-pipe';
import {BankListPageModule} from './_modal/bank-list/bank-list.module';
import {OrderDetailsPageModule} from './orders/public-orders-simple/order-details/order-details.module';
import {AcceptOrderPageModule} from './_components/publicOrder/order-details/_modal/accept-order/accept-order.module';
import {RejectOrderPageModule} from './_components/publicOrder/order-details/_modal/reject-order/reject-order.module';
import {NativeAudio} from '@ionic-native/native-audio/ngx';
import {Insomnia} from '@ionic-native/insomnia/ngx';
import {UpdateCardNumberPageModule} from './profile/update-card-number/update-card-number.module';
import {CustomDateAdapter} from './_app/custom-date-adapter.service';
import {DateAdapter} from '@angular/material';
import {SplitButtonModule} from 'primeng';


registerLocaleData(localePl, 'pl');
registerLocaleData(localeUk, 'uk');


export function apiConfigFactory() {

    const params: ConfigurationParameters = {
        apiKeys: {},
        withCredentials: true

    };
    return new Configuration(params);
}


@NgModule({
    declarations: [AppComponent],
    imports: [

        ComponentsMenuModule,
        FormsModule,
        BrowserModule,
        UserIdleModule.forRoot({idle: 40, timeout: 10, ping: 120}), //30 , 10 default
        ApiModule.forRoot(apiConfigFactory),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        IonicModule.forRoot({
            mode: 'md' // wymusza styl material design dla wszystki platform
        }),

        AppRoutingModule,
        BrowserAnimationsModule,
        HelpPageModule,
        ComplaintPageModule,
        TermsPageModule,
        ForgotPasswordPageModule,
        DeliveryAddressPageModule,
        InvoiceAddressPageModule,
        OrderDetailsPageModule,
        ContactlessPaymentPageModule,
        RateOrderPageModule,
        AdvertisementPageModule,
        RestaurantInfoPageModule,
        BankListPageModule,
        UpdateCardNumberPageModule,
        OrderModule,
        AcceptOrderPageModule,
        RejectOrderPageModule,
        OrderPickupDetailPageModule,

        IonicStorageModule.forRoot(),

    ],
    providers: [
        {provide: DateAdapter, useClass: CustomDateAdapter },
        ScreenOrientation,
        StatusBar,
        SplashScreen,
        GuardService,
        AuthService,
        DatePipe,
        AppVersion,
        OneSignal,
        CurrencyPipe,
        BarcodeScanner,
        OpenNativeSettings,
        Insomnia,
        FingerprintAIO,
        Device,
        NativeAudio,
        Geolocation,
        //   Deeplinks,
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: BASE_PATH, useValue: environment.apiBasePath}
    ],

    bootstrap: [AppComponent]
})
export class AppModule {


}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}