/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PaginationSortingParametersFilters { 
    name?: string;
    value?: Array<string>;
    matchMode?: PaginationSortingParametersFilters.MatchModeEnum;
}
export namespace PaginationSortingParametersFilters {
    export type MatchModeEnum = 'EQUAL' | 'MIN' | 'MAX' | 'CONTAIN';
    export const MatchModeEnum = {
        EQUAL: 'EQUAL' as MatchModeEnum,
        MIN: 'MIN' as MatchModeEnum,
        MAX: 'MAX' as MatchModeEnum,
        CONTAIN: 'CONTAIN' as MatchModeEnum
    };
}