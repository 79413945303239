import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Input} from '@angular/core';
import {Order, OrderRateItem, OrderService, User} from '../../../api';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../_app/app.service';
import {ToastService} from '../../_notification/toast.service';
import {take, takeUntil} from 'rxjs/internal/operators';
import {tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {AuthService} from '../../_auth/auth.service';

@Component({
    selector: 'app-rate-order',
    templateUrl: './rate-order.page.html',
    styleUrls: ['./rate-order.page.scss'],
})
export class RateOrderPage implements OnInit, OnDestroy {

    @Input() order: Order;
    public onRateOrderForm: FormGroup;
    public averageRating: number;
    public selectedLng: string;
    public user$: Observable<User>;
    private user: User;
    private readonly destroy$: Subject<void> = new Subject();
    private isSaved = false;


    constructor(private modalController: ModalController,
                private translate: TranslateService,
                private appService: AppService,
                private toast: ToastService,
                private authService: AuthService,
                private orderService: OrderService,
                private formBuilder: FormBuilder) {
    }

    ngOnInit() {

        this.user$ = this.authService.currentUser.pipe(take(1), tap(user => this.user = user), takeUntil(this.destroy$));
        this.selectedLng = this.appService.getSelectedLngIsoCode();

        this.onRateOrderForm = this.formBuilder.group({
            'avrgRating': [null],
            dishesArray: new FormArray([]),
            'comment': [this.order.ratingComment, Validators.compose([
                Validators.minLength(3)
            ])]
        });

        this.order.dishesList.forEach((o, i) => {
            const control = new FormControl(o);
            control.setValue(o.rating);
            (this.onRateOrderForm.controls.dishesArray as FormArray).push(control);
        });
        this.mapValues();

    }

    sendOrderRating() {

        if (!this.averageRating) {
            this.toast.show(this.translate.instant('RATING.Prosze-wybierz-swoja-ocene'), null, 2000, 'top', 'tertiary');
            return false;
        }
        const orderRateItems: OrderRateItem[] = [];
        this.order.dishesList.map((v, i) => {

            if (v.rating) {
                const rateItem = {
                    rate: v.rating,
                    rateId: v.rateId
                };
                orderRateItems.push(rateItem);
            }
        });

        this.orderService.rateOrder(orderRateItems, this.user.id, this.order.id, this.order.isLunchMenu, this.onRateOrderForm.value.comment).subscribe(res => {

                this.toast.show(this.user.firstName + ', ' + this.translate.instant('APP.dziekujemy') + ' :)', this.translate.instant('RATING.Twoja-ocena-zostala-do-nas-przeslana') + ' !', 2000, 'top', 'success');
                this.isSaved = true;
                this.closeModal();

            },
            error2 => {
                if (error2.status !== 401 && error2.status !== 403) {
                    this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-przesylania-Twojej-oceny'), null, 2000, 'top', 'danger');
                }
            });

    }

    mapValues(avrg?: boolean) {
        (avrg) ? this.averageRating = this.onRateOrderForm.value.avrgRating : this.averageRating = null;

        this.onRateOrderForm.value.dishesArray.map((v, i) => {
            if (avrg) {
                this.order.dishesList[i].rating = this.onRateOrderForm.value.avrgRating;

                (this.onRateOrderForm.controls.dishesArray as FormArray).controls[i].setValue(this.onRateOrderForm.value.avrgRating);

            } else {
                this.order.dishesList[i].rating = v;
                this.averageRating += v;
            }
        });
        if (!avrg) {
            this.averageRating = this.averageRating / this.order.dishesList.filter(value => value.rating > 0).length;
            this.onRateOrderForm.controls.avrgRating.setValue(this.averageRating);
        }

    }

    ngOnDestroy(): void {

        this.destroy$.next();
        this.destroy$.complete();
    }

    async closeModal() {

        await this.modalController.dismiss(this.isSaved);
    }

}
