import {Injectable} from '@angular/core';
import {Menu, OrderConditions} from '../../../api';
import {CartService} from '../../_cart/cart.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SingleMenuService {

    public singleMenu: Menu;
    private _isOrderingDisabled: boolean;

    private menuDaySource = new BehaviorSubject(false);
    currentDayMenu = this.menuDaySource.asObservable();

    constructor(private cartService: CartService) {
    }



    // zmieniamy ilosc w menu , potrzebne w przypadku gdy raz cos dodasz w menu koszykowym, zmienisz menu wrocicsz i chcesz z menu koszykowego zwiekszyc ten sam produkt

    updateSingleMenu(product) {
        if (this.singleMenu.categories) {
            for (const c of this.singleMenu.categories) {
                if (c.products) {
                    for (const [index, p] of c.products.entries()) {
                        // TODO WH dodalem && p.categoryId === product.categoryId
                        if (p.id === product.id && p.categoryId === product.categoryId) {

                            p.qtyOrdered = product.qtyOrdered;
                        }

                    }
                }
                //subkategorie sprawdzam
                if (c.categories) {
                    for (const cSub of c.categories) {
                        if (cSub.products) {
                            for (const [indexSub, pSub] of cSub.products.entries()) {

                                // TODO WH dodalem && p.categoryId === product.categoryId
                                if (pSub.id === product.id && pSub.categoryId === product.categoryId) {

                                    pSub.qtyOrdered = product.qtyOrdered;
                                }

                            }
                        }
                    }
                }

            }
        }
    }


    setSingleMenu(singleMenu: Menu) {
        this.singleMenu = singleMenu;

        //sprawdzam czy produkty sa w koszyku . na potrzeby chceckbox zaznaczenie w single menu
        if (this.singleMenu.categories) {
            for (const c of this.singleMenu.categories) {
                if (c.products) {
                    for (const [index, p] of c.products.entries()) {

                        // TODO WH - bierze numer koszyka z menu , zamieniłem na nową metody getProductByOrderConditions
                        //  const cartProduct = this.cartService.getProduct(p, this.singleMenu.id);
                        const cartProduct = this.cartService.getProductByOrderConditions(p, this.singleMenu);
                        if (cartProduct) {

                            p.isChecked = true;
                            p.qtyOrdered = cartProduct.qtyOrdered;
                        }
                        // TODO WH dodane else poniewaz jak bylo zapisane zamowienie z menu A i bedac na menu B usuwales towar z koszyka to nie lapal tego
                        else {
                            p.isChecked = false;
                            p.qtyOrdered = 0;
                        }

                    }
                }
                //subkategorie sprawdzam
                if (c.categories) {
                    for (const cSub of c.categories) {
                        if (cSub.products) {
                            for (const [indexSub, pSub] of cSub.products.entries()) {

                                // TODO WH - bierze numer koszyka z menu , zamieniłem na nową metody getProductByOrderConditions
                                //  const cartProductSub = this.cartService.getProduct(pSub, this.singleMenu.id);
                                const cartProductSub = this.cartService.getProductByOrderConditions(pSub, this.singleMenu);
                                if (cartProductSub) {
                                    pSub.isChecked = true;
                                    pSub.qtyOrdered = cartProductSub.qtyOrdered;
                                }
                                // TODO WH dodane else poniewaz jak bylo zapisane zamowienie z menu A i bedac na menu B usuwales towar z koszyka to nie lapal tego
                                else {
                                    pSub.isChecked = false;
                                    pSub.qtyOrdered = 0;
                                }
                            }
                        }
                    }
                }

            }
        }


    }

    getSingleMenu() {
        return this.singleMenu;
    }

    removeSingleMenu() {
        this.singleMenu = null;
    }

    updateOrderConditions(orderConditions: OrderConditions, isOrderingDisabled: boolean = false) {
        this.isOrderingDisabled = isOrderingDisabled;
        this.singleMenu.orderConditions = orderConditions;
    }


    get isOrderingDisabled(): boolean {
        return this._isOrderingDisabled;
    }

    set isOrderingDisabled(value: boolean) {
        this._isOrderingDisabled = value;
    }
    changeMenuDay(status: boolean) {
        this.menuDaySource.next(status);
    }
}
