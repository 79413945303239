import {Component, Input, OnInit} from '@angular/core';
import {Advertisement} from '../../../api';
import {ModalController} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-advertisement',
    templateUrl: './advertisement.page.html',
    styleUrls: ['./advertisement.page.scss'],
})
export class AdvertisementPage implements OnInit {
    @Input() ad: Advertisement;


    constructor(private modalController: ModalController,
                public sanitizer: DomSanitizer) {
    }

    ngOnInit() {

    }

    async closeModal() {

        await this.modalController.dismiss();

    }


}
