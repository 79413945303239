import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ModalController} from '@ionic/angular';
import {Order} from '../../../../../../api';
import {ToastService} from '../../../../../_notification/toast.service';

@Component({
    selector: 'app-reject-order',
    templateUrl: './reject-order.page.html',
    styleUrls: ['./reject-order.page.scss'],
})
export class RejectOrderPage implements OnInit {

    @Input() order: Order;
    public reason: any;
    public customReason: any;

    constructor(private modalController: ModalController,
                private toast: ToastService,
                private translate: TranslateService) {
    }


    ngOnInit() {
    }

    async closeModal() {
        await this.modalController.dismiss();
    }

    async rejectAndClose() {

        if (!this.reason) {
            this.toast.show(this.translate.instant('APP.Podaj-powod-odrzucenia'), null, 2000, 'top', 'danger');
        } else if (this.reason === 'other' && !this.customReason) {
            this.toast.show(this.translate.instant('APP.Wpisz-powod-odrzucenia'), null, 2000, 'top', 'danger');
        } else {
            const reason = (this.reason === 'other') ? this.customReason : this.reason;
            await this.modalController.dismiss(reason);
        }
    }
}
