import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {AppService} from '../../_app/app.service';
import {Restaurant, User, UserProfileService} from '../../../api';
import {AuthService} from '../../_auth/auth.service';
import {ToastService} from '../../_notification/toast.service';
import {Observable, Subject} from 'rxjs';
import {Preferences} from '../../_interfaces/preferences';
import {distinctUntilChanged, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-help',
    templateUrl: './help.page.html',
    styleUrls: ['./help.page.scss'],
})
export class HelpPage implements OnInit, OnDestroy {


    public restaurants: Restaurant[];
    public user$: Observable<User>;
    private user: User;
    public preferences: Preferences;
    public preferences$: Observable<Preferences>;
    private readonly destroy$: Subject<void> = new Subject();
    public versionNumber: string;
    public codeVersion: number;

    constructor(private modalController: ModalController,
                private navParams: NavParams,
                public platform: Platform,
                private appVersion: AppVersion,
                public appService: AppService,
                public authService: AuthService,
                public userProfile: UserProfileService) {

        this.codeVersion = environment.codeVersion;
        this.authService.currentUser
            .pipe(
                take(1),
                takeUntil(this.destroy$)).subscribe(user => {
            if (user) {
                this.user = user;
                this.userProfile.getUserRestaurants(this.user.id).subscribe(res => {
                    this.restaurants = res;
                });
            }

        });

        if (this.platform.is('cordova')) {
            this.appVersion.getVersionNumber().then(value => {
                this.versionNumber = value;
            }).catch(err => {

            });
        }

    }

    ngOnInit() {


    }

    async closeModal() {

        await this.modalController.dismiss();
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
