import {Component, EventEmitter} from '@angular/core';
import {ActionSheetController, AlertController, ModalController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AuthService} from './_auth/auth.service';
import {User} from '../api';
import {MenuController} from '@ionic/angular';
import {HelpService} from './_modal/help.service';
import {AppService} from './_app/app.service';
import {BehaviorSubject, Observable, Observer, Subject} from 'rxjs';
import {Preferences} from './_interfaces/preferences';
import {tap, takeUntil, take} from 'rxjs/operators';
import {UserIdleService} from 'angular-user-idle';
import {timer} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';


// import { Deeplinks } from '@ionic-native/deeplinks/ngx'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {

    public preferences: Preferences;
    public user$: Observable<User>;
    private user: User;
    private alertIdle: any;
    public isPaneVisible: boolean;
    private readonly destroy$: Subject<void> = new Subject();
    public selectedLng: string;

    public appPages = [];
    private appPages_ = [];

    constructor(//   private deeplinks: Deeplinks,
        private screenOrientation: ScreenOrientation,
        private actionSheetController: ActionSheetController,
        private modalCtrl: ModalController,
        private translate: TranslateService,
        public platform: Platform,
        private userIdle: UserIdleService,
        public alertController: AlertController,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private authService: AuthService,
        private menuCtrl: MenuController,
        public appService: AppService,
        public helpService: HelpService) {

        this.initializeApp();
        this.appService.checkDesktop();

        this.appPages = [
            {
                title: this.translate.instant('APP.Start'),
                url: '/welcome',
                token: true,
                isMobile: false,
                isDesktop: false,
                isKiosk: false,
                permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                companyTypes: ['CLIENT', 'RESTAURANT']
            },
            {
                title: this.translate.instant('APP.Pulpit'),
                url: '/dashboard',
                isMobile: true,
                isDesktop: true,
                isKiosk: false,
                permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                companyTypes: ['CLIENT']

            },
            {
                title: this.translate.instant('APP.Zamow'),
                url: '/shifts',
                icon: 'hand',
                isMobile: true,
                isDesktop: true,
                isKiosk: true,
                permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                companyTypes: ['CLIENT']
            },
            {
                title: this.translate.instant('APP.Zamowienia'),
                url: '/orders',
                icon: 'hand',
                isMobile: true,
                isDesktop: true,
                isKiosk: false,
                permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                companyTypes: ['CLIENT']
            },
            {
                title: this.translate.instant('APP.Zamowienia-publiczne'),
                url: '/public-orders-simple',
                icon: 'hand',
                isMobile: true,
                isDesktop: true,
                isKiosk: false,
                permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                companyTypes: ['RESTAURANT']
            },
            {
                title: this.translate.instant('APP.Odbior-zamowien'),
                url: '/restaurantPanel/order-pickup',
                icon: 'hand',
                isMobile: true,
                isDesktop: true,
                isKiosk: false,
                permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                companyTypes: ['RESTAURANT']
            },
            {
                title: this.translate.instant('CART.Menu'),
                //    url: 'payments',
                icon: 'hand',
                isMobile: true,
                isDesktop: true,
                isKiosk: false,
                permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                companyTypes: ['RESTAURANT'],
                children: [
                    {
                        title: this.translate.instant('Kategorie'),
                        url: '/restaurantPanel/categories',
                        icon: 'hand',
                        isMobile: true,
                        isDesktop: true,
                        isKiosk: false,
                        permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                        companyTypes: ['RESTAURANT']
                    },
                    {
                        title: this.translate.instant('APP.Potrawy'),
                        url: '/restaurantPanel/products',
                        icon: 'hand',
                        isMobile: true,
                        isDesktop: true,
                        isKiosk: false,
                        permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                        companyTypes: ['RESTAURANT']
                    }

                ]
            },
            {
                title: this.translate.instant('APP.Etykiety'),
                url: '/restaurantPanel/labels',
                icon: 'hand',
                isMobile: true,
                isDesktop: true,
                isKiosk: false,
                permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                companyTypes: ['RESTAURANT']
            },
            {
                title: this.translate.instant('APP.Raporty'),
                //   url: '/reports',
                icon: 'hand',
                isMobile: true,
                isDesktop: true,
                isKiosk: false,
                permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                companyTypes: ['CLIENT'],


                children: [
                    {
                        title: this.translate.instant('APP.Potrawy'),
                        url: '/reports/products',
                        icon: 'hand',
                        isMobile: true,
                        isDesktop: true,
                        isKiosk: false,
                        permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                        companyTypes: ['CLIENT']

                    },
                    {
                        title: this.translate.instant('APP.Rachunki'),
                        url: '/reports/bills',
                        icon: 'hand',
                        isMobile: true,
                        isDesktop: true,
                        isKiosk: false,
                        permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                        companyTypes: ['CLIENT']
                    }

                ]
            },
            {
                title: this.translate.instant('APP.Zgody'),
                url: '/agreements',
                icon: 'hand',
                isMobile: true,
                isDesktop: true,
                isKiosk: true,
                permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                companyTypes: ['CLIENT']
            },


            {
                title: this.translate.instant('APP.Platnosci'),
                //    url: 'payments',
                icon: 'hand',
                isMobile: true,
                isDesktop: true,
                isKiosk: false,
                permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                companyTypes: ['CLIENT'],
                children: [
                    {
                        title: this.translate.instant('APP.Platnosci-biezace'),
                        url: '/payments',
                        icon: 'hand',
                        isMobile: true,
                        isDesktop: true,
                        isKiosk: false,
                        permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                        companyTypes: ['CLIENT']
                    },
                    {
                        title: this.translate.instant('APP.Historia-i-zwroty'),
                        url: '/paymentsHistory',
                        icon: 'hand',
                        isMobile: true,
                        isDesktop: true,
                        isKiosk: false,
                        permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                        companyTypes: ['CLIENT']
                    }

                ]
            },
            {
                title: this.translate.instant('APP.Reklamacje'),
                url: 'complaints',
                icon: 'hand',
                isMobile: true,
                isDesktop: true,
                isKiosk: false,
                permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                companyTypes: ['CLIENT']
            },
            {
                title: this.translate.instant('APP.Pomoc'),
                url: '/',
                icon: 'hand',
                event: 'help',
                isMobile: true,
                isDesktop: true,
                isKiosk: true,
                permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
                companyTypes: ['CLIENT']
            },

        ];

        // Filtruje ze względu na platforme

        //mobile
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.appPages = this.appPages.filter(i => i.isMobile === true);
        } // desktop
        else if (this.appService.isDesktop && !this.platform.is('ios') && !this.platform.is('android')) {
            this.appPages = this.appPages.filter(i => i.isDesktop === true);
        } else // kiosk
        {
            this.appPages = this.appPages.filter(i => i.isKiosk === true);
        }
        this.appPages_ = this.appPages;

        this.user$ = this.authService.currentUser.pipe(tap(user => this.user = user));


        this.authService.currentUser
            .pipe(takeUntil(this.destroy$))
            .subscribe((user: User) => {

                if (user) {

                    const pages = this.appPages_.filter(i => {

                        return (this.authService.hasCompanyTypes(i.companyTypes));
                    });
                    this.appPages = pages.filter(i => {

                        return this.authService.hasPermissions(i.permissions);
                    });

                    //Start watching for user inactivity.
                    if (environment.userIdle && (!this.platform.is('ios') && !this.platform.is('android') && !this.appService.isDesktop)) {
                        this.userIdle.startWatching();
                    }
                    this.menuCtrl.enable(true, 'mainMenu');
                } else {
                    this.menuCtrl.enable(false, 'mainMenu');
                    //  this.stopWatching();


                }

            });

        this.appService.currentPreferences.pipe(takeUntil(this.destroy$)).subscribe((preferences: Preferences) => {
            this.preferences = preferences;

        });

    }


    initializeApp() {


        // Start watching when user idle is starting.
        this.userIdle.onTimerStart().subscribe(count => {

                if (count === 1) {
                    this.idleAlert();
                }


            }
        );

        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(() => {
            // this.stopWatching();

            this.alertIdle.dismiss().then(out => {
                this.closeAllModals();
            });
            this.authService.logout();

        });


        this.platform.ready().then(() => {

            this.appService.setInitialLanguage();
            this.user$ = this.authService.currentUser;

            if (this.platform.is('ios') || this.platform.is('android')) {
                // blokuje pionowa orientacje
                this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);

                this.statusBar.styleDefault();
                this.statusBar.show();
                this.statusBar.overlaysWebView(false);
                this.splashScreen.hide();

            }
            this.menuCtrl.enable(false, 'mainMenu');
            this.menuCtrl.enable(false, 'menuCart');
            this.menuCtrl.enable(false, 'menuProduct');
            this.menuCtrl.enable(false, 'menuOrder');
            this.menuCtrl.enable(false, 'createEditProduct');

        });

    }


    logout() {
        this.menuCtrl.enable(false, 'mainMenu');
        this.menuCtrl.enable(false, 'menuCart');
        this.menuCtrl.enable(false, 'menuProduct');
        this.menuCtrl.enable(false, 'menuOrder');
        this.menuCtrl.enable(false, 'createEditProduct');
        this.authService.logout();

    }

    closeMenu(id, disable = false) {
        this.menuCtrl.close(id);
        if (disable) {
            this.menuCtrl.enable(false, id);
        }

    }

    stopTimer() {
        this.userIdle.stopTimer();
    }

    stopWatching() {
        this.userIdle.stopWatching();
    }

    startWatching() {
        this.userIdle.startWatching();
    }

    restartTimer() {
        this.userIdle.resetTimer();
    }


    async closeAllModals() {
        //usuwam poprzednie alerty i modale
        const modal = await this.modalCtrl.getTop();
        if (modal) {
            modal.dismiss();
        }
        const alert = await this.alertController.getTop();
        if (alert) {
            alert.dismiss();
        }
        const actionSheet = await this.actionSheetController.getTop();
        if (actionSheet) {
            actionSheet.dismiss();
        }
    }


    async idleAlert() {


        this.alertIdle = await this.alertController.create({
            header: this.translate.instant('ALERT.Za-chwile-zostaniesz-wylogowany'),
            message: this.translate.instant('ALERT.Co-chcesz-teraz-zrobic'),
            cssClass: 'customAlert',
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('APP.Wyloguj-sie'),
                    role: 'cancel',
                    cssClass: 'alertBtnGrey',
                    handler: (data) => {
                        this.closeAllModals();
                        this.authService.logout();

                    }
                }, {
                    text: this.translate.instant('APP.Kontynuuj'),
                    cssClass: 'alertBtnSuccess',
                    handler: () => {
                        this.restartTimer();
                    }
                }
            ]
        });

        await this.alertIdle.present();

    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    splitPaneVisible(event) {
        this.isPaneVisible = event.detail.visible;

    }

    setLanguage(lang) {
        return;
    }
}
