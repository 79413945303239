import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { MenuService } from './api/menu.service';
import { OrderService } from './api/order.service';
import { PublicOrdersService } from './api/publicOrders.service';
import { RestaurantService } from './api/restaurant.service';
import { RestaurantMenuService } from './api/restaurantMenu.service';
import { UserAdvertisementsService } from './api/userAdvertisements.service';
import { UserAuthService } from './api/userAuth.service';
import { UserCalendarService } from './api/userCalendar.service';
import { UserComplaintService } from './api/userComplaint.service';
import { UserMessagesService } from './api/userMessages.service';
import { UserPaymentService } from './api/userPayment.service';
import { UserProfileService } from './api/userProfile.service';
import { UserRegisterService } from './api/userRegister.service';
import { UserReportsService } from './api/userReports.service';
import { UserToolsService } from './api/userTools.service';
import { WelcomeService } from './api/welcome.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    MenuService,
    OrderService,
    PublicOrdersService,
    RestaurantService,
    RestaurantMenuService,
    UserAdvertisementsService,
    UserAuthService,
    UserCalendarService,
    UserComplaintService,
    UserMessagesService,
    UserPaymentService,
    UserProfileService,
    UserRegisterService,
    UserReportsService,
    UserToolsService,
    WelcomeService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
