/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PublicOrderStatus = 'NEW' | 'IN_PROGRESS' | 'COMPLETED' | 'CANCELLED' | 'REJECTED';

export const PublicOrderStatus = {
    NEW: 'NEW' as PublicOrderStatus,
    INPROGRESS: 'IN_PROGRESS' as PublicOrderStatus,
    COMPLETED: 'COMPLETED' as PublicOrderStatus,
    CANCELLED: 'CANCELLED' as PublicOrderStatus,
    REJECTED: 'REJECTED' as PublicOrderStatus
};