import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ModalController} from '@ionic/angular';
import {Order} from '../../../../../../api';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-accept-order',
    templateUrl: './accept-order.page.html',
    styleUrls: ['./accept-order.page.scss'],
})
export class AcceptOrderPage implements OnInit {
    public time: any;
    @Input() order: Order;

    constructor(private modalController: ModalController,
                private datePipe: DatePipe,
                private translate: TranslateService) {
    }


    ngOnInit() {
        this.time = new Date(this.order.publicOrder.estimatedDeliveryTime);
    }


    async closeModal() {
        await this.modalController.dismiss();
    }

    async acceptAndClose() {

         // TODO przestawic dzien jak zmieni się godzina po polnocy (Adam cos mowil ze moze trzeba sprawdzac roznice w czasie o 12h)
        await this.modalController.dismiss(this.time);

    }

}
