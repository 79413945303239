import {Component, Input, OnInit} from '@angular/core';
import {Order} from '../../../../../api';
import {TranslateService} from '@ngx-translate/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-order-pickup-detail',
    templateUrl: './order-pickup-detail.page.html',
    styleUrls: ['./order-pickup-detail.page.scss'],
})
export class OrderPickupDetailPage implements OnInit {

    @Input() order: Order;

    constructor(private translate: TranslateService, private modalController: ModalController) {

    }

    ngOnInit() {


    }


    async closeModal(pickupOrder?: boolean) {
        if (pickupOrder) {
            await this.modalController.dismiss(this.order);
        }
        else {
            await this.modalController.dismiss();
        }
    }
}
