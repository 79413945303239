import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {Order, OrderService, PublicOrdersService, PublicOrderStatus, Restaurant, User} from '../../../../api';
import {GoogleMapsService} from '../../../_googleMaps/google-maps.service';
import {ToastService} from '../../../_notification/toast.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {OrdersSimpleService} from '../../../orders/public-orders-simple/_serivce/orders-simple.service';
import {CurrencyPipe, DatePipe} from '@angular/common';
import {InvoiceAddressPage} from '../../../_modal/invoice-address/invoice-address.page';
import {AcceptOrderPage} from './_modal/accept-order/accept-order.page';
import {RejectOrderPage} from './_modal/reject-order/reject-order.page';
import {FontsPdfService} from '../../../_app/fonts-pdf.service';
import {TranslateService} from '@ngx-translate/core';

declare var google: any;

@Component({
    selector: 'app-order-details-component',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit, OnChanges {
    @Input() order: Order;
    @Input() user: User;

    @ViewChild('contactMap', null) contactMap: any;
    private googleMap: any;
    private directionsService: any;
    private directionsRenderer: any;
    private originGeo: any;
    public progressBar: boolean;

    constructor(private toast: ToastService,
                private datePipe: DatePipe,
                private modalCtrl: ModalController,
                public platform: Platform,
                private currency: CurrencyPipe,
                private translate: TranslateService,
                private fontsPdfService: FontsPdfService,
                private alertController: AlertController,
                private ordersSimpleService: OrdersSimpleService,
                private publicOrders: PublicOrdersService,
                private orderServeice: OrderService,
                private googleMapsService: GoogleMapsService) {
    }

    ngOnChanges(changes) {
        if (changes['order'] && this.order) {

            if (!this.order.publicOrder.pickupPoint.isPersonalPickup) {

                this.initMap();
            }
            this.progressBar = true;

            setTimeout(() => {
                this.progressBar = false;

            }, 1000);

        }
    }

    ngOnInit() {


    }


    initMap(): void {


        this.googleMap = null;


        this.googleMapsService.geoCodeAddress(this.order.publicOrder.pickupPoint.address.street,
            this.order.publicOrder.pickupPoint.address.buildingNumber,
            this.order.publicOrder.pickupPoint.address.zipCode,
            this.order.publicOrder.pickupPoint.address.city).then(on => {

            const directionsRenderer = new google.maps.DirectionsRenderer();
            const directionsService = new google.maps.DirectionsService();

            const mapProp = {
                gestureHandling: 'cooperative',
                //  center: on[0].geometry.location,
                zoom: 13,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                clickableIcons: false
            };
            this.googleMap = new google.maps.Map(this.contactMap.nativeElement, mapProp);


            directionsRenderer.setMap(this.googleMap);


            this.calculateAndDisplayRoute(directionsService, directionsRenderer, on[0].geometry.location);
            this.directionsService = directionsService;
            this.directionsRenderer = directionsRenderer;
            this.originGeo = on[0].geometry.location;


        }).catch(reason => {
            this.toast.show(this.translate.instant('APP.Wystapil-blad-podczas-ladowania-mapy-dojazdu'), reason, 2000, 'top', 'danger');
        });

    }

    onModeChange() {
        this.calculateAndDisplayRoute(this.directionsService, this.directionsRenderer, this.originGeo);
    }

    calculateAndDisplayRoute(directionsService, directionsRenderer, destination) {


        this.publicOrders.getRestaurant(this.order.restaurantId, 'PL').subscribe((restaurant: Restaurant) => {

            this.googleMapsService.geoCodeAddress(restaurant.street,
                restaurant.buildingNumber,
                restaurant.zipCode,
                restaurant.city).then(on => {
                const selectedMode = (document.getElementById('mode') as HTMLInputElement).value;

                directionsService.route(
                    {
                        origin: on[0].geometry.location,
                        destination: destination,
                        // Note that Javascript allows us to access the constant
                        // using square brackets and a string value as its
                        // "property."
                        // @ts-ignore
                        travelMode: google.maps.TravelMode[selectedMode],
                    },
                    (response, status) => {
                        if (status === 'OK') {
                            directionsRenderer.setDirections(response);
                        } else {
                            this.toast.show(this.translate.instant('APP.Wystapil-blad-podczas-ladowania-mapy-dojazdu'), status, 3000, 'top', 'danger');
                        }
                    }
                );

            }).catch(reason => {
                this.toast.show(this.translate.instant('APP.Wystapil-blad-podczas-ladowania-mapy-dojazdu'), reason, 2000, 'top', 'danger');
            });

        }, error2 => {

            if (error2.status !== 401 && error2.status !== 403) {
                this.toast.show(this.translate.instant('APP.Wystapil-blad-podczas-ladowania-mapy-dojazdu'), status, 3000, 'top', 'danger');
            }

        });

    }


    async acceptOrder() {

        const acceptWindow = await this.modalCtrl.create({
            component: AcceptOrderPage,
            animated: true,
            componentProps: {
                order: this.order
            }

        });
        acceptWindow.onDidDismiss()
            .then((data) => {
                if (data['data']) {

                    this.setStatus('IN_PROGRESS', null, data['data']);
                }
            });

        await acceptWindow.present();

    }

    async rejectOrder() {


        const rejectWindow = await this.modalCtrl.create({
            component: RejectOrderPage,
            animated: true,
            componentProps: {
                order: this.order
            }

        });

        rejectWindow.onDidDismiss()
            .then((data) => {
                if (data['data']) {
                    this.setStatus('REJECTED', data['data']);
                }
            });


        await rejectWindow.present();

    }


    completeOrder() {
        this.setStatus('COMPLETED');
    }

    setStatus(status: PublicOrderStatus, comment?: string, dateTimeDelivery?: Date) {

        this.orderServeice.publicOrderSetStatus(this.user.id, this.order.id, this.order.isLunchMenu, status, comment, dateTimeDelivery).subscribe(res => {
            this.ordersSimpleService.reloadOrders.next(true);

            // zmieniamy status dla mobile, poniewaz modal nie jest przeładowywany i nie chcemy robic dodatkowego requesta
            if ((this.platform.is('ios') || this.platform.is('android'))) {
                this.order.publicOrder.status = status;
                if (dateTimeDelivery) {
                    this.order.publicOrder.estimatedDeliveryTime = dateTimeDelivery;
                }
                if (comment) {
                    this.order.publicOrder.statusComment = comment;
                }

            }

            switch (status) {
                case 'COMPLETED':
                    this.toast.show(this.translate.instant('APP.Zamowienie-nr') + ' ' + this.order.id + ' ' + this.translate.instant('APP.zostalo-zrealizowane'), null, 2000, 'top', 'success');
                    break;
                case 'IN_PROGRESS':
                    this.toast.show(this.translate.instant('APP.Zamowienie-nr') + ' ' + this.order.id + '  ' + this.translate.instant('APP.zostalo-przyjete-do-realizacji'), null, 2000, 'top', 'success');
                    break;
                case 'REJECTED':
                    this.toast.show(this.translate.instant('APP.Zamowienie-nr') + ' ' + this.order.id + ' ' + this.translate.instant('APP.zostalo-odrzucone'), (this.order.paymentDetails.status === 'PAID') ? this.translate.instant('APP.Srodki-zostana-automatycznie-zwrocone-na-konto-kli') : null, 3000, 'top', 'success');
                    break;
            }


        }, error2 => {

            if (error2.status !== 401 && error2.status !== 403) {
                this.toast.show(this.translate.instant('APP.Wystapil-blad-podczas-zamiany-statusu-zamowienia'), error2.error, 3000, 'top', 'danger');
            }
        });
    }

    printOrder() {
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(x => {
                const doc = new jsPDF.default({
                    orientation: 'p',
                    unit: 'mm',
                    format: 'a4'
                });

                this.fontsPdfService.addFont(doc, 'robotoRegular');


                doc.autoTable({
                    tableWidth: 180,
                    pageBreak: 'auto',
                    rowPageBreak: 'avoid',
                    startY: 10,

                    // margin: {left: 0, bottom: 0, top: 0, right: 0},
                    theme: 'grid',
                    styles: {
                        // minCellHeight: 10,
                        fontSize: 10,
                        cellWidth: 'wrap',

                        halign: 'center',
                        font: 'robotoRegular'
                    },
                    // willDrawCell: (data) => {
                    //     data.row.height = 100;
                    //     data.row.maxHeight = 100;
                    // },
                    body: [
                        [{
                            content: this.translate.instant('APP.Zamowienie-nr') + ' ' + this.order.id + ' ' + this.translate.instant('APP.z-dnia') + ' ' + this.datePipe.transform(this.order.timePlaceOrder, 'dd.MM.yyyy HH:mm'),
                            styles: {
                                fillColor: [240, 240, 245],
                                cellWidth: 180,
                                fontSize: 15,
                                fontStyle: 'normal',
                                overflow: 'linebreak',
                                cellPadding: 4,
                                halign: 'center'
                            }
                        }],
                        [{
                            content: this.translate.instant('APP.status').toUpperCase() + ': ' + this.translate.instant(this.order.publicOrder.status),
                            styles: {
                                fontSize: 10,
                                fontStyle: 'normal',
                                overflow: 'linebreak',
                                cellPadding: 2,
                                halign: 'center'
                            }
                        }],
                        [{
                            content: this.translate.instant('CART.Platnosc') + ': ' + this.translate.instant(this.order.paymentMethod) + ' ' + ((this.order.paymentDetails.status === 'PAID' || this.order.paymentDetails.status === 'REQUIRED') ? ' - ' + this.translate.instant(this.order.paymentDetails.status) : ''),
                            styles: {
                                fontSize: 10,
                                fontStyle: 'normal',
                                overflow: 'linebreak',
                                cellPadding: 2,
                                halign: 'center'
                            }
                        }]
                    ],

                });

                let ASAP;
                (this.order.publicOrder.isASAP) ? ASAP = this.translate.instant('APP.jak-najszybciej') + ' - ' : ASAP = '';

                doc.autoTable({

                    styles: {
                        fontSize: 10,
                        cellWidth: 'wrap',
                        // halign: 'center',
                        font: 'robotoRegular',
                        fontStyle: 'normal',
                        theme: 'grid'
                    },
                    headStyles: {
                        0: {cellWidth: 40, halign: 'left'},
                        1: {cellWidth: 140}
                    },
                    columnStyles: {
                        0: {cellWidth: 40, halign: 'left'},
                        1: {cellWidth: 140}
                    },
                    head: [
                        [(this.order.publicOrder.pickupPoint.isPersonalPickup) ? this.translate.instant('APP.Odbior-osobisty').toUpperCase() + ': ' : this.translate.instant('APP.Dostawa').toUpperCase() + ': ', ASAP + this.datePipe.transform(this.order.publicOrder.estimatedDeliveryTime, 'HH:mm dd.MM.yyyy')]
                    ],
                    body: [
                        [this.translate.instant('APP.Ulica-i-nr-budynku') + ':', this.order.publicOrder.pickupPoint.address.street + ' ' + this.order.publicOrder.pickupPoint.address.buildingNumber],
                        [this.translate.instant('APP.Miejscowosc') + ':', ((this.order.publicOrder.pickupPoint.address.zipCode) ? this.order.publicOrder.pickupPoint.address.zipCode : '') + ' ' + this.order.publicOrder.pickupPoint.address.city]
                    ]
                });


                doc.autoTable({

                    styles: {
                        fontSize: 10,
                        cellWidth: 'wrap',
                        // halign: 'center',
                        font: 'robotoRegular',
                        fontStyle: 'normal',
                        theme: 'grid'
                    },
                    headStyles: {
                        0: {cellWidth: 40, halign: 'left'},
                        1: {cellWidth: 140}
                    },
                    columnStyles: {
                        0: {cellWidth: 40, halign: 'left'},
                        1: {cellWidth: 140}
                    },
                    head: [
                        [this.translate.instant('APP.Dane-klienta').toUpperCase() + ':', this.order.publicOrder.firstSecondName],

                    ],
                    body: [
                        [this.translate.instant('APP.Telefon') + ':', this.order.publicOrder.phone],
                        [this.translate.instant('APP.e-mail') + ':', this.order.publicOrder.email]
                    ]
                });


                if (this.order.invoiceAddress) {
                    doc.autoTable({

                        styles: {
                            fontSize: 10,
                            cellWidth: 'wrap',
                            // halign: 'center',
                            font: 'robotoRegular',
                            fontStyle: 'normal',
                            theme: 'grid'
                        },
                        headStyles: {
                            0: {cellWidth: 40, halign: 'left'},
                            1: {cellWidth: 140}
                        },
                        columnStyles: {
                            0: {cellWidth: 40, halign: 'left'},
                            1: {cellWidth: 140}
                        },
                        head: [
                            [this.translate.instant('INVOICE.Dane-do-faktury-VAT').toUpperCase() + ':', ''],

                        ],
                        body: [
                            [this.translate.instant('APP.Nazwa') + ':', this.order.invoiceAddress.copmanyName],
                            [this.translate.instant('PAYMENTS.NIP') + ':', this.order.invoiceAddress.taxId],
                            [this.translate.instant('APP.Miejscowosc') + ':', this.order.invoiceAddress.zipCode + ' ' + this.order.invoiceAddress.city],
                            [this.translate.instant('APP.Ulica-i-nr-budynku') + ':', this.order.invoiceAddress.street],
                        ]
                    });

                }

                const dishesList = [];

                this.order.dishesList.forEach(item => {
                    const obj = [item.qtyOrdered + 'x', item.name, this.currency.transform(item.priceGross * item.qtyOrdered, 'PLN', 'symbol', undefined, 'PL')];
                    dishesList.push(obj);
                })


                doc.autoTable({

                    styles: {
                        fontSize: 10,
                        cellWidth: 'wrap',
                        // halign: 'center',
                        font: 'robotoRegular',
                        fontStyle: 'normal'
                    },

                    headStyles: {
                        0: {cellWidth: 10},
                        1: {cellWidth: 130},
                        2: {cellWidth: 40}
                    },
                    bodyStyles: {
                        0: {cellWidth: 10},
                        1: {cellWidth: 130},
                        2: {cellWidth: 40}
                    },
                    columnStyles: {
                        0: {cellWidth: 10},
                        1: {cellWidth: 130},
                        2: {cellWidth: 40}
                    },
                    head: [[this.translate.instant('APP.Ilosc'), this.translate.instant('APP.Nazwa'), this.translate.instant('APP.Wartosc')]],
                    body: dishesList
                });


                doc.autoTable({

                    styles: {
                        fontSize: 10,
                        cellWidth: 'wrap',
                        // halign: 'center',
                        font: 'robotoRegular',
                        fontStyle: 'normal'
                    },
                    columnStyles: {
                        0: {cellWidth: 143, halign: 'right'},
                        1: {cellWidth: 38}
                    },

                    body: [[this.translate.instant('CART.Wartosc-brutto'), this.currency.transform(this.order.valueGross, 'PLN', 'symbol', undefined, 'PL')], [this.translate.instant('CART.Koszt-dostawy'), this.currency.transform(this.order.deliveryPrice, 'PLN', 'symbol', undefined, 'PL')], [this.translate.instant('CART.Rabat'), this.currency.transform(this.order.discount.discountValue, 'PLN', 'symbol', undefined, 'PL')], [this.translate.instant('APP.Razem').toUpperCase(), this.currency.transform(this.order.valueGrossDiscounted, 'PLN', 'symbol', undefined, 'PL')]]
                });

                if (this.order.orderComment) {

                    doc.autoTable({
                        theme: 'grid',
                        styles: {
                            fontSize: 10,
                            cellWidth: 'wrap',
                            // halign: 'center',
                            font: 'robotoRegular',
                            fontStyle: 'normal'
                        },
                        columnStyles: {
                            0: {cellWidth: 40, halign: 'right'},
                            1: {cellWidth: 140}
                        },

                        body: [[this.translate.instant('CART.Komentarz').toUpperCase(), this.order.orderComment]]
                    });
                }

                doc.save('order_' + this.order.id + '.pdf');
            });
        });
    }
}
