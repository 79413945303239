/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PlaceOrderErrorResponse = 'ERRORS.GENERAL_FAILURE' | 'ERRORS.DUPLICATED_LOGIN' | 'ERRORS.INVOICE_NOT_POSSIBLE' | 'ERRORS.NO_MENU_ERROR' | 'ERRORS.ORDER_CONDITIONS_ERROR' | 'ERRORS.QUANTITY_ERROR' | 'ERRORS.COMBINATION_ERROR' | 'ERRORS.MINIMAL_ORDER_ERROR' | 'ERRORS.DELIVERY_PRICE_ERROR' | 'ERRORS.LIMITS_ERROR' | 'ERRORS.MAX_DUE_LIMITS_ERROR' | 'ERRORS.ALREADY_PAID_ERROR' | 'ERRORS.PREORDER_TIME_ERROR' | 'ERRORS.ALREADY_ORDERED_ERROR' | 'ERRORS.BUSINESS_TERMS_ASSIGNMENT_ERROR' | 'ERRORS.USER_ORDER_SETTINGS_ERROR' | 'ERRORS.DELIVERY_HOURS_ERROR' | 'ERRORS.HOLIDAYS_ERROR' | 'ERRORS.NO_DELIVERY_DAYS_ERROR';

export const PlaceOrderErrorResponse = {
    GENERALFAILURE: 'ERRORS.GENERAL_FAILURE' as PlaceOrderErrorResponse,
    DUPLICATEDLOGIN: 'ERRORS.DUPLICATED_LOGIN' as PlaceOrderErrorResponse,
    INVOICENOTPOSSIBLE: 'ERRORS.INVOICE_NOT_POSSIBLE' as PlaceOrderErrorResponse,
    NOMENUERROR: 'ERRORS.NO_MENU_ERROR' as PlaceOrderErrorResponse,
    ORDERCONDITIONSERROR: 'ERRORS.ORDER_CONDITIONS_ERROR' as PlaceOrderErrorResponse,
    QUANTITYERROR: 'ERRORS.QUANTITY_ERROR' as PlaceOrderErrorResponse,
    COMBINATIONERROR: 'ERRORS.COMBINATION_ERROR' as PlaceOrderErrorResponse,
    MINIMALORDERERROR: 'ERRORS.MINIMAL_ORDER_ERROR' as PlaceOrderErrorResponse,
    DELIVERYPRICEERROR: 'ERRORS.DELIVERY_PRICE_ERROR' as PlaceOrderErrorResponse,
    LIMITSERROR: 'ERRORS.LIMITS_ERROR' as PlaceOrderErrorResponse,
    MAXDUELIMITSERROR: 'ERRORS.MAX_DUE_LIMITS_ERROR' as PlaceOrderErrorResponse,
    ALREADYPAIDERROR: 'ERRORS.ALREADY_PAID_ERROR' as PlaceOrderErrorResponse,
    PREORDERTIMEERROR: 'ERRORS.PREORDER_TIME_ERROR' as PlaceOrderErrorResponse,
    ALREADYORDEREDERROR: 'ERRORS.ALREADY_ORDERED_ERROR' as PlaceOrderErrorResponse,
    BUSINESSTERMSASSIGNMENTERROR: 'ERRORS.BUSINESS_TERMS_ASSIGNMENT_ERROR' as PlaceOrderErrorResponse,
    USERORDERSETTINGSERROR: 'ERRORS.USER_ORDER_SETTINGS_ERROR' as PlaceOrderErrorResponse,
    DELIVERYHOURSERROR: 'ERRORS.DELIVERY_HOURS_ERROR' as PlaceOrderErrorResponse,
    HOLIDAYSERROR: 'ERRORS.HOLIDAYS_ERROR' as PlaceOrderErrorResponse,
    NODELIVERYDAYSERROR: 'ERRORS.NO_DELIVERY_DAYS_ERROR' as PlaceOrderErrorResponse
};