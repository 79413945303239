/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { RegistrationSetup } from '../model/registrationSetup';
import { UserAgreement } from '../model/userAgreement';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserRegisterService {

    protected basePath = 'https://virtserver.swaggerhub.com/e-Lunch.pl/kiosk/1.0.1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * user register
     * 
     * @param firstName 
     * @param lastName 
     * @param login 
     * @param password 
     * @param email 
     * @param token 
     * @param language 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userRegister(firstName: string, lastName: string, login: string, password: string, email: string, token: string, language: string, body?: Array<UserAgreement>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userRegister(firstName: string, lastName: string, login: string, password: string, email: string, token: string, language: string, body?: Array<UserAgreement>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userRegister(firstName: string, lastName: string, login: string, password: string, email: string, token: string, language: string, body?: Array<UserAgreement>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userRegister(firstName: string, lastName: string, login: string, password: string, email: string, token: string, language: string, body?: Array<UserAgreement>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (firstName === null || firstName === undefined) {
            throw new Error('Required parameter firstName was null or undefined when calling userRegister.');
        }

        if (lastName === null || lastName === undefined) {
            throw new Error('Required parameter lastName was null or undefined when calling userRegister.');
        }

        if (login === null || login === undefined) {
            throw new Error('Required parameter login was null or undefined when calling userRegister.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling userRegister.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling userRegister.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling userRegister.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling userRegister.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (firstName !== undefined && firstName !== null) {
            queryParameters = queryParameters.set('firstName', <any>firstName);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }
        if (login !== undefined && login !== null) {
            queryParameters = queryParameters.set('login', <any>login);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/user/register`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * email activation code
     * 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userRegisterEmailActivationCode(code: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userRegisterEmailActivationCode(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userRegisterEmailActivationCode(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userRegisterEmailActivationCode(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling userRegisterEmailActivationCode.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/user/register/emailActivationCode`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * register setup
     * 
     * @param token 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userRegisterSetup(token: string, language: string, observe?: 'body', reportProgress?: boolean): Observable<RegistrationSetup>;
    public userRegisterSetup(token: string, language: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RegistrationSetup>>;
    public userRegisterSetup(token: string, language: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RegistrationSetup>>;
    public userRegisterSetup(token: string, language: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling userRegisterSetup.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling userRegisterSetup.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RegistrationSetup>('get',`${this.basePath}/user/register/setup`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
