/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PaymentReturnResponseError = 'ERRORS.GENERAL_FAILURE' | 'ERRORS.CLOSING_TIME_EXPIRED' | 'ERRORS.NOT_APPLICABLE';

export const PaymentReturnResponseError = {
    GENERALFAILURE: 'ERRORS.GENERAL_FAILURE' as PaymentReturnResponseError,
    CLOSINGTIMEEXPIRED: 'ERRORS.CLOSING_TIME_EXPIRED' as PaymentReturnResponseError,
    NOTAPPLICABLE: 'ERRORS.NOT_APPLICABLE' as PaymentReturnResponseError
};