import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../../../_app/app.service';
import {MenuController, Platform} from '@ionic/angular';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../../_notification/toast.service';
import {
    AppLanguage, MenuProductSimple, PhotoFile, RestaurantMenuService, RestaurantService, Translation, User, UserToolsService,
    VAT
} from '../../../../../api';
import {take} from 'rxjs/internal/operators';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '../../../../_auth/auth.service';
import {Observable, Subject} from 'rxjs';
import {Preferences} from '../../../../_interfaces/preferences';
import {deliveryType} from '../../../../_interfaces/poStorage';
import VatEnum = MenuProductSimple.VatEnum;
import {MenuItem, TreeNode} from 'primeng';
import {environment} from '../../../../../environments/environment';


@Component({
    selector: 'app-create-edit-product',
    templateUrl: './create-edit-product.component.html',
    styleUrls: ['./create-edit-product.component.scss'],
})
export class CreateEditProductComponent implements OnInit, OnDestroy {
    public segment: string;
    public languages: AppLanguage[];
    public selectedLng: any;
    public categoryLng: any;
    public form: FormGroup;
    public submitAttempt: boolean = false;
    private user: User;
    public user$: Observable<User>;
    public preferences: Preferences;
    public preferences$: Observable<Preferences>;
    public vatRates: any[];
    private readonly destroy$: Subject<void> = new Subject();
    items: MenuItem[];
    public categories: TreeNode[];
    public categoryLoading: boolean;
    private categoryFilter: any[] = [];
    public apiHost = environment.apiBasePath;
    public host = environment.host;
    public uploadedFile: PhotoFile;

    constructor(public appService: AppService,
                private fb: FormBuilder,
                private translate: TranslateService,
                private userTools: UserToolsService,
                private toast: ToastService,
                public platform: Platform,
                private restaurant: RestaurantService,
                private restaurantMenu: RestaurantMenuService,
                public authService: AuthService,
                private menuCtrl: MenuController) {
        this.segment = 'main';

        this.items = [
            {
                label: 'Update'
            },
            {
                label: 'lkhnhg'
            }
        ];

    }

    ngOnInit() {

    }

    init() {
        this.submitAttempt = false;
        this.vatRates = Object.keys(VatEnum).map(key => VatEnum[key]);
        this.selectedLng = this.appService.selectedLang;
        this.categoryLng = this.selectedLng;
        this.appService.currentApp.pipe(take(1)).subscribe(res => {
            this.languages = res.languages;
        });


        this.authService.currentUser.pipe(
            take(1),
            takeUntil(this.destroy$)).subscribe(res => {
            this.user = res;
            this.categoryNodeExpand();
        });
        this.appService.currentPreferences.pipe(
            takeUntil(this.destroy$)
        ).subscribe(res => {
            this.preferences = res;

        });

        this.preferences$ = this.appService.currentPreferences.pipe();


        this.form = this.fb.group({
            nameTranslation: this.fb.array(this.languages.map(locale => this.getFormGroupForLocale(locale, true))),
            descriptionShortTranslation: this.fb.array(this.languages.map(locale => this.getFormGroupForLocale(locale))),
            priceNet: [null, Validators.compose([
                Validators.required, Validators.pattern('[, .()0-9]+')
            ])],
            priceGross: [null, Validators.compose([
                Validators.required, Validators.pattern('[, .()0-9]+')
            ])],
            vat: [8, Validators.compose([
                Validators.required
            ])],
        });


    }

    closeMenu() {
        this.destroy$.next();
        this.destroy$.complete();

        this.menuCtrl.close('createEditProduct');
        this.menuCtrl.enable(false, 'createEditProduct');
    }

    segmentChanged(value: string): void {

        this.segment = value;

    }


    private getFormGroupForLocale(language, required?: boolean) {
        return this.fb.group({
            name: [null, (language === this.selectedLng && required) ? [Validators.required] : '']
        });
    }

    localeFormArray(name: string) {
        return (<FormArray> this.form.get(name)).controls;
    }

    getFormControl(i, groupName: string) {
        return (<FormArray> this.form.get(groupName)).controls[i];
    }


    translateText(groupName?: string) {

        this.userTools.trasnalteText(this.user.id, this.selectedLng, this.categoryLng, this.getFormControl(this.languages.indexOf(this.selectedLng), groupName).value.name).subscribe(res => {

            const field = this.getFormControl(this.languages.indexOf(this.categoryLng), groupName);
            field.patchValue({name: res});
            field.updateValueAndValidity();
        }, error2 => {

            if (error2.status !== 401 && error2.status !== 403 && error2.status !== 404) {
                this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-przesylania-danych-dane-nie'), null, 2000, 'top', 'danger');
            }

        });
    }

    submitProduct() {


        if (!this.form.valid) {
            this.submitAttempt = true;
            this.categoryLng = this.selectedLng;
            this.segment = 'main';

        } else {

            const translations: Translation[] = [];
            let name = null;
            this.languages.forEach((val: any, key: any) => {
                if (val === this.selectedLng) {
                    name = this.getFormControl(key, 'nameTranslation').value.name;
                }
                if (this.getFormControl(key, 'nameTranslation').value.name) {
                    translations.push({
                        lang: val,
                        value: this.getFormControl(key, 'nameTranslation').value.name
                    });
                }

            });
            const product: MenuProductSimple = {
                name: name,
                nameTranslation: translations,
                priceNet: 22.11
            }

            this.restaurantMenu.createMenuProduct(this.user.id, product).subscribe(res => {
                this.toast.show(this.translate.instant(`Danie ${name}, zostało utworzone !`), null, 2000, 'top', 'success');
            }, error2 => {

                if (error2.status !== 401 && error2.status !== 403 && error2.status !== 404) {
                    if (error2.error) {
                        this.toast.show(this.translate.instant(`Danie nie zostało utworzone,`), this.translate.instant(`ponieważ istnieje już danie o nazwie ${name}`), 2000, 'top', 'danger');
                    } else {

                        this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-przesylania-danych-dane-nie'), null, 2000, 'top', 'danger');
                    }

                }

            });
        }
    }

    calculatePrice(value, name?: string) {

        console.log(value);
        if (isNaN(value)) {
            this.form.get(name).setValue('');
            this.form.get(name).updateValueAndValidity();
        } else {

            const priceNet = value / (1 + (this.form.value.vat / 100));
            const priceGross = value * (1 + (this.form.value.vat / 100));

            if (name === 'priceGross') {

                this.form.get(name).setValue(priceGross.toFixed(2));
                this.form.get(name).updateValueAndValidity();

            } else if (name === 'priceNet') {

                this.form.get(name).setValue(priceNet.toFixed(2));
                this.form.get(name).updateValueAndValidity();

            } else {

                //    this.form.get('priceNet').setValue(priceNet.toFixed(2));
                //  this.form.get('priceNet').updateValueAndValidity();
                // this.form.get('priceGross').setValue(priceGross.toFixed(2));
                // this.form.get('priceGross').updateValueAndValidity();
            }

        }
    }


    ngOnDestroy(): void {

        this.destroy$.next();
        this.destroy$.complete();
    }

    categoryNodeExpand(event?) {

        this.categoryLoading = true;
        let key = null;
        if (event && event.node.key) {
            key = event.node.key;
        }

        this.restaurant.getCategoryTree(this.user.id, this.selectedLng, key, 'DISH').subscribe(cat => {
            this.categoryLoading = false;
            if (cat) {
                const categories = cat.map(item => {
                    return {key: item.id.toString(), data: item.name, label: item.name, leaf: item.isLeaf};
                });
                if (event && event.node) {
                    event.node.children = categories;
                } else {
                    this.categories = categories;
                }
            }
        }, error2 => {

            this.categoryLoading = false;
        });


    }

    onCategoryNodeSelect(node: any) {
        this.categoryFilter.push(node);

        if (node.children) {
            this.categoryFilter.push(...node.children);
        }

    }

    onCategoryNodeUnselect(node: any) {
        const nodes = [node.key];
        if (node.children) {
            nodes.push(...node.children.map(item => item.key));
        }

        this.categoryFilter = this.categoryFilter.filter(item => !nodes.includes(item.key)
        );

    }

    onUpload(event) {
        this.uploadedFile = event.originalEvent.body;

    }

}

