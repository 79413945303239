import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ContactlessPaymentStatus, OrderService} from '../../../../api';
import {ToastService} from '../../../_notification/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {interval, Subscription} from 'rxjs';
import {ModalController, NavController} from '@ionic/angular';
import {take, timeout} from 'rxjs/internal/operators';
import {AuthService} from '../../../_auth/auth.service';
import {EventService} from '../../../_app/event.service';

@Component({
    selector: 'app-contactless-payment',
    templateUrl: './contactless-payment.page.html',
    styleUrls: ['./contactless-payment.page.scss'],
})
export class ContactlessPaymentPage implements OnInit, OnDestroy {

    @Input() userId: number;
    @Input() orderId: number;
    @Input() restaurantId: number;
    @Input() toPayValue: number;
    @Input() token: string;
    @Input() isLunchMenu: boolean;

    private paymentId: number;
    public paymentStatus: ContactlessPaymentStatus;
    private source = interval(1000);
    private subscription: Subscription;
    private counter: number;

    constructor(private orderService: OrderService,
                private modalController: ModalController,
                private toast: ToastService,
                private eventService: EventService,
                private navCtrl: NavController,
                private authService: AuthService,
                private translate: TranslateService) {

    }

    ngOnInit() {

        this.payOrder();
    }


    payOrder() {
        this.counter = 0;
        this.paymentStatus = 'CONTACTLESS_PAYMENT_STATUS.PENDING';
        let idsLunchMenu = null;
        let idsMenu = null;
        (this.isLunchMenu) ? idsLunchMenu = [this.orderId] : idsMenu = [this.orderId];

        this.orderService.payOrdersContactless(
            this.userId,
            this.restaurantId,
            Number(this.toPayValue.toFixed(2)),
            this.token,
            idsLunchMenu,
            idsMenu).subscribe(paymentId => {

            this.paymentId = paymentId;
            if (this.paymentId) {

                this.source.pipe(timeout(200));
                this.subscription = this.source.subscribe(val => this.getPaymentStatus());

            } else {
                this.paymentStatus = 'CONTACTLESS_PAYMENT_STATUS.ERROR';
            }

        }, (err) => {
            if (err.status !== 401 && err.status !== 403) {
                this.paymentStatus = 'CONTACTLESS_PAYMENT_STATUS.ERROR';

            }
        });
    }


    getPaymentStatus() {
        this.orderService.getContactlessPaymentStatus(this.userId, this.paymentId, this.token).subscribe((status: ContactlessPaymentStatus) => {
            if (status !== this.paymentStatus && this.paymentStatus) {
                this.counter = 0;

            }

            this.paymentStatus = status;
            // console.log(status, new Date());


            if (status !== 'CONTACTLESS_PAYMENT_STATUS.PENDING' && status !== 'CONTACTLESS_PAYMENT_STATUS.PROCESSING') {
                this.subscription.unsubscribe();
            }

            if (status === 'CONTACTLESS_PAYMENT_STATUS.PENDING' && this.counter >= 10) {
                this.paymentStatus = 'CONTACTLESS_PAYMENT_STATUS.TIMEOUT';
                this.subscription.unsubscribe();
            }

            if (status === 'CONTACTLESS_PAYMENT_STATUS.PROCESSING' && this.counter >= 130) {
                this.paymentStatus = 'CONTACTLESS_PAYMENT_STATUS.TIMEOUT';
                this.subscription.unsubscribe();
            }

            this.counter++;


        }, (err) => {
            this.subscription.unsubscribe();
            if (err.status !== 401 && err.status !== 403) {

                this.paymentStatus = 'CONTACTLESS_PAYMENT_STATUS.ERROR';
            } else {
                this.closeModal();
            }
        });
    }


    async closeModal() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        await this.modalController.dismiss();

        this.navCtrl.navigateRoot('calendar');
        this.eventService.publishRefreshAfterDeletedOrder();

    }

    async logout() {
        this.subscription.unsubscribe();
        await this.modalController.dismiss();
        this.authService.logout();
    }

    ngOnDestroy() {

        this.subscription.unsubscribe();
    }

}
