/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TranslationType = 'CATEGORY_NAME' | 'PRODUCT_NAME' | 'PRODUCT_DESCRIPTION' | 'PRODUCT_INGREDIENTS' | 'PRODUCT_NUTRITIONAL_VALUES';

export const TranslationType = {
    CATEGORYNAME: 'CATEGORY_NAME' as TranslationType,
    PRODUCTNAME: 'PRODUCT_NAME' as TranslationType,
    PRODUCTDESCRIPTION: 'PRODUCT_DESCRIPTION' as TranslationType,
    PRODUCTINGREDIENTS: 'PRODUCT_INGREDIENTS' as TranslationType,
    PRODUCTNUTRITIONALVALUES: 'PRODUCT_NUTRITIONAL_VALUES' as TranslationType
};