/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PaymentMethod = 'PAYMENT.CASH' | 'PAYMENT.CASH_SETTLED' | 'PAYMENT.CARD' | 'PAYMENT.TRANSFER' | 'PAYMENT.P24' | 'PAYMENT.P24_DELAYED' | 'PAYMENT.CONTACTLESS' | 'PAYMENT.ZERO_VALUE';

export const PaymentMethod = {
    CASH: 'PAYMENT.CASH' as PaymentMethod,
    CASHSETTLED: 'PAYMENT.CASH_SETTLED' as PaymentMethod,
    CARD: 'PAYMENT.CARD' as PaymentMethod,
    TRANSFER: 'PAYMENT.TRANSFER' as PaymentMethod,
    P24: 'PAYMENT.P24' as PaymentMethod,
    P24DELAYED: 'PAYMENT.P24_DELAYED' as PaymentMethod,
    CONTACTLESS: 'PAYMENT.CONTACTLESS' as PaymentMethod,
    ZEROVALUE: 'PAYMENT.ZERO_VALUE' as PaymentMethod
};