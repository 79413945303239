/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AvailablePaymentMethods } from '../model/availablePaymentMethods';
import { Bank } from '../model/bank';
import { BasketDetails } from '../model/basketDetails';
import { ContactlessPaymentStatus } from '../model/contactlessPaymentStatus';
import { Order } from '../model/order';
import { OrderDiscount } from '../model/orderDiscount';
import { OrderPagination } from '../model/orderPagination';
import { OrderRateItem } from '../model/orderRateItem';
import { PaginationSortingParameters } from '../model/paginationSortingParameters';
import { PayOrderErrorResponse } from '../model/payOrderErrorResponse';
import { PaymentReturnResponse } from '../model/paymentReturnResponse';
import { PaymentReturnResponseError } from '../model/paymentReturnResponseError';
import { PlaceOrderErrorResponse } from '../model/placeOrderErrorResponse';
import { PlaceOrderResponse } from '../model/placeOrderResponse';
import { SetPublicOrderStatusErrorResponse } from '../model/setPublicOrderStatusErrorResponse';
import { UserPlaceOrderBody } from '../model/userPlaceOrderBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OrderService {

    protected basePath = 'https://virtserver.swaggerhub.com/e-Lunch.pl/kiosk/1.0.1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * delete order
     * 
     * @param isLunchMenu 
     * @param userId 
     * @param shiftId 
     * @param restaurantId 
     * @param idOrderConditions 
     * @param date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrder(isLunchMenu: boolean, userId: number, shiftId: number, restaurantId: number, idOrderConditions: number, date: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteOrder(isLunchMenu: boolean, userId: number, shiftId: number, restaurantId: number, idOrderConditions: number, date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteOrder(isLunchMenu: boolean, userId: number, shiftId: number, restaurantId: number, idOrderConditions: number, date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteOrder(isLunchMenu: boolean, userId: number, shiftId: number, restaurantId: number, idOrderConditions: number, date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (isLunchMenu === null || isLunchMenu === undefined) {
            throw new Error('Required parameter isLunchMenu was null or undefined when calling deleteOrder.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deleteOrder.');
        }

        if (shiftId === null || shiftId === undefined) {
            throw new Error('Required parameter shiftId was null or undefined when calling deleteOrder.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling deleteOrder.');
        }

        if (idOrderConditions === null || idOrderConditions === undefined) {
            throw new Error('Required parameter idOrderConditions was null or undefined when calling deleteOrder.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling deleteOrder.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isLunchMenu !== undefined && isLunchMenu !== null) {
            queryParameters = queryParameters.set('isLunchMenu', <any>isLunchMenu);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (shiftId !== undefined && shiftId !== null) {
            queryParameters = queryParameters.set('shiftId', <any>shiftId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (idOrderConditions !== undefined && idOrderConditions !== null) {
            queryParameters = queryParameters.set('idOrderConditions', <any>idOrderConditions);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/user/deleteOrder`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get available payment methods
     * 
     * @param userId 
     * @param idOrderConditions 
     * @param shiftId 
     * @param restaurantId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAvailablePaymentMethods(userId: number, idOrderConditions: number, shiftId: number, restaurantId: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<AvailablePaymentMethods>;
    public getAvailablePaymentMethods(userId: number, idOrderConditions: number, shiftId: number, restaurantId: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AvailablePaymentMethods>>;
    public getAvailablePaymentMethods(userId: number, idOrderConditions: number, shiftId: number, restaurantId: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AvailablePaymentMethods>>;
    public getAvailablePaymentMethods(userId: number, idOrderConditions: number, shiftId: number, restaurantId: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getAvailablePaymentMethods.');
        }

        if (idOrderConditions === null || idOrderConditions === undefined) {
            throw new Error('Required parameter idOrderConditions was null or undefined when calling getAvailablePaymentMethods.');
        }

        if (shiftId === null || shiftId === undefined) {
            throw new Error('Required parameter shiftId was null or undefined when calling getAvailablePaymentMethods.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getAvailablePaymentMethods.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (idOrderConditions !== undefined && idOrderConditions !== null) {
            queryParameters = queryParameters.set('idOrderConditions', <any>idOrderConditions);
        }
        if (shiftId !== undefined && shiftId !== null) {
            queryParameters = queryParameters.set('shiftId', <any>shiftId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AvailablePaymentMethods>('get',`${this.basePath}/user/availablePaymentMethods`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get banks list
     * 
     * @param userId 
     * @param restaurantId 
     * @param isOnlinePayment 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBanksList(userId: number, restaurantId: number, isOnlinePayment: boolean, language: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Bank>>;
    public getBanksList(userId: number, restaurantId: number, isOnlinePayment: boolean, language: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Bank>>>;
    public getBanksList(userId: number, restaurantId: number, isOnlinePayment: boolean, language: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Bank>>>;
    public getBanksList(userId: number, restaurantId: number, isOnlinePayment: boolean, language: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getBanksList.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getBanksList.');
        }

        if (isOnlinePayment === null || isOnlinePayment === undefined) {
            throw new Error('Required parameter isOnlinePayment was null or undefined when calling getBanksList.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getBanksList.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (isOnlinePayment !== undefined && isOnlinePayment !== null) {
            queryParameters = queryParameters.set('isOnlinePayment', <any>isOnlinePayment);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Bank>>('get',`${this.basePath}/user/getBanksList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get banks list
     * 
     * @param restaurantId 
     * @param isOnlinePayment 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBanksListObject(restaurantId: number, isOnlinePayment: boolean, language: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Bank>>;
    public getBanksListObject(restaurantId: number, isOnlinePayment: boolean, language: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Bank>>>;
    public getBanksListObject(restaurantId: number, isOnlinePayment: boolean, language: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Bank>>>;
    public getBanksListObject(restaurantId: number, isOnlinePayment: boolean, language: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getBanksListObject.');
        }

        if (isOnlinePayment === null || isOnlinePayment === undefined) {
            throw new Error('Required parameter isOnlinePayment was null or undefined when calling getBanksListObject.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getBanksListObject.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (isOnlinePayment !== undefined && isOnlinePayment !== null) {
            queryParameters = queryParameters.set('isOnlinePayment', <any>isOnlinePayment);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Bank>>('get',`${this.basePath}/user/getBanksListObject`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get basket details
     * 
     * @param isLunchMenu 
     * @param userId 
     * @param shiftId 
     * @param restaurantId 
     * @param idOrderConditions 
     * @param date 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBasketDetails(isLunchMenu: boolean, userId: number, shiftId: number, restaurantId: number, idOrderConditions: number, date: string, language: string, observe?: 'body', reportProgress?: boolean): Observable<BasketDetails>;
    public getBasketDetails(isLunchMenu: boolean, userId: number, shiftId: number, restaurantId: number, idOrderConditions: number, date: string, language: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BasketDetails>>;
    public getBasketDetails(isLunchMenu: boolean, userId: number, shiftId: number, restaurantId: number, idOrderConditions: number, date: string, language: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BasketDetails>>;
    public getBasketDetails(isLunchMenu: boolean, userId: number, shiftId: number, restaurantId: number, idOrderConditions: number, date: string, language: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (isLunchMenu === null || isLunchMenu === undefined) {
            throw new Error('Required parameter isLunchMenu was null or undefined when calling getBasketDetails.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getBasketDetails.');
        }

        if (shiftId === null || shiftId === undefined) {
            throw new Error('Required parameter shiftId was null or undefined when calling getBasketDetails.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getBasketDetails.');
        }

        if (idOrderConditions === null || idOrderConditions === undefined) {
            throw new Error('Required parameter idOrderConditions was null or undefined when calling getBasketDetails.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getBasketDetails.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getBasketDetails.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isLunchMenu !== undefined && isLunchMenu !== null) {
            queryParameters = queryParameters.set('isLunchMenu', <any>isLunchMenu);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (shiftId !== undefined && shiftId !== null) {
            queryParameters = queryParameters.set('shiftId', <any>shiftId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (idOrderConditions !== undefined && idOrderConditions !== null) {
            queryParameters = queryParameters.set('idOrderConditions', <any>idOrderConditions);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BasketDetails>('get',`${this.basePath}/user/basketDetails`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get contactless payment status
     * 
     * @param userId 
     * @param paymentId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContactlessPaymentStatus(userId: number, paymentId: number, token: string, observe?: 'body', reportProgress?: boolean): Observable<ContactlessPaymentStatus>;
    public getContactlessPaymentStatus(userId: number, paymentId: number, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContactlessPaymentStatus>>;
    public getContactlessPaymentStatus(userId: number, paymentId: number, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContactlessPaymentStatus>>;
    public getContactlessPaymentStatus(userId: number, paymentId: number, token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getContactlessPaymentStatus.');
        }

        if (paymentId === null || paymentId === undefined) {
            throw new Error('Required parameter paymentId was null or undefined when calling getContactlessPaymentStatus.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getContactlessPaymentStatus.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (paymentId !== undefined && paymentId !== null) {
            queryParameters = queryParameters.set('paymentId', <any>paymentId);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ContactlessPaymentStatus>('get',`${this.basePath}/user/getContactlessPaymentStatus`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get order
     * 
     * @param userId 
     * @param language 
     * @param body 
     * @param shiftId 
     * @param restaurantId 
     * @param date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrder(userId: number, language: string, body?: PaginationSortingParameters, shiftId?: number, restaurantId?: number, date?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Order | OrderPagination>>;
    public getOrder(userId: number, language: string, body?: PaginationSortingParameters, shiftId?: number, restaurantId?: number, date?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Order | OrderPagination>>>;
    public getOrder(userId: number, language: string, body?: PaginationSortingParameters, shiftId?: number, restaurantId?: number, date?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Order | OrderPagination>>>;
    public getOrder(userId: number, language: string, body?: PaginationSortingParameters, shiftId?: number, restaurantId?: number, date?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getOrder.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getOrder.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (shiftId !== undefined && shiftId !== null) {
            queryParameters = queryParameters.set('shiftId', <any>shiftId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<Order | OrderPagination>>('post',`${this.basePath}/user/order`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get order discount and funding
     * 
     * @param userId 
     * @param isLunchMenu 
     * @param shiftId 
     * @param restaurantId 
     * @param idOrderConditions 
     * @param valueGross 
     * @param date 
     * @param orderId 
     * @param idLunchMenuCategories 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderDiscount(userId: number, isLunchMenu: boolean, shiftId: number, restaurantId: number, idOrderConditions: number, valueGross: number, date: string, orderId?: number, idLunchMenuCategories?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<OrderDiscount>;
    public getOrderDiscount(userId: number, isLunchMenu: boolean, shiftId: number, restaurantId: number, idOrderConditions: number, valueGross: number, date: string, orderId?: number, idLunchMenuCategories?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderDiscount>>;
    public getOrderDiscount(userId: number, isLunchMenu: boolean, shiftId: number, restaurantId: number, idOrderConditions: number, valueGross: number, date: string, orderId?: number, idLunchMenuCategories?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderDiscount>>;
    public getOrderDiscount(userId: number, isLunchMenu: boolean, shiftId: number, restaurantId: number, idOrderConditions: number, valueGross: number, date: string, orderId?: number, idLunchMenuCategories?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getOrderDiscount.');
        }

        if (isLunchMenu === null || isLunchMenu === undefined) {
            throw new Error('Required parameter isLunchMenu was null or undefined when calling getOrderDiscount.');
        }

        if (shiftId === null || shiftId === undefined) {
            throw new Error('Required parameter shiftId was null or undefined when calling getOrderDiscount.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getOrderDiscount.');
        }

        if (idOrderConditions === null || idOrderConditions === undefined) {
            throw new Error('Required parameter idOrderConditions was null or undefined when calling getOrderDiscount.');
        }

        if (valueGross === null || valueGross === undefined) {
            throw new Error('Required parameter valueGross was null or undefined when calling getOrderDiscount.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getOrderDiscount.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (isLunchMenu !== undefined && isLunchMenu !== null) {
            queryParameters = queryParameters.set('isLunchMenu', <any>isLunchMenu);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (shiftId !== undefined && shiftId !== null) {
            queryParameters = queryParameters.set('shiftId', <any>shiftId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (idOrderConditions !== undefined && idOrderConditions !== null) {
            queryParameters = queryParameters.set('idOrderConditions', <any>idOrderConditions);
        }
        if (valueGross !== undefined && valueGross !== null) {
            queryParameters = queryParameters.set('valueGross', <any>valueGross);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (idLunchMenuCategories) {
            idLunchMenuCategories.forEach((element) => {
                queryParameters = queryParameters.append('idLunchMenuCategories', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OrderDiscount>('get',`${this.basePath}/user/orderDiscount`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get order discount
     * 
     * @param isLunchMenu 
     * @param restaurantId 
     * @param idOrderConditions 
     * @param valueGross 
     * @param date 
     * @param isPublicMenu 
     * @param userId 
     * @param orderId 
     * @param shiftId 
     * @param idLunchMenuCategories 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderDiscountObject(isLunchMenu: boolean, restaurantId: number, idOrderConditions: number, valueGross: number, date: string, isPublicMenu: boolean, userId?: number, orderId?: number, shiftId?: number, idLunchMenuCategories?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<OrderDiscount>;
    public getOrderDiscountObject(isLunchMenu: boolean, restaurantId: number, idOrderConditions: number, valueGross: number, date: string, isPublicMenu: boolean, userId?: number, orderId?: number, shiftId?: number, idLunchMenuCategories?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderDiscount>>;
    public getOrderDiscountObject(isLunchMenu: boolean, restaurantId: number, idOrderConditions: number, valueGross: number, date: string, isPublicMenu: boolean, userId?: number, orderId?: number, shiftId?: number, idLunchMenuCategories?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderDiscount>>;
    public getOrderDiscountObject(isLunchMenu: boolean, restaurantId: number, idOrderConditions: number, valueGross: number, date: string, isPublicMenu: boolean, userId?: number, orderId?: number, shiftId?: number, idLunchMenuCategories?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (isLunchMenu === null || isLunchMenu === undefined) {
            throw new Error('Required parameter isLunchMenu was null or undefined when calling getOrderDiscountObject.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getOrderDiscountObject.');
        }

        if (idOrderConditions === null || idOrderConditions === undefined) {
            throw new Error('Required parameter idOrderConditions was null or undefined when calling getOrderDiscountObject.');
        }

        if (valueGross === null || valueGross === undefined) {
            throw new Error('Required parameter valueGross was null or undefined when calling getOrderDiscountObject.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getOrderDiscountObject.');
        }

        if (isPublicMenu === null || isPublicMenu === undefined) {
            throw new Error('Required parameter isPublicMenu was null or undefined when calling getOrderDiscountObject.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (isLunchMenu !== undefined && isLunchMenu !== null) {
            queryParameters = queryParameters.set('isLunchMenu', <any>isLunchMenu);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (shiftId !== undefined && shiftId !== null) {
            queryParameters = queryParameters.set('shiftId', <any>shiftId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (idOrderConditions !== undefined && idOrderConditions !== null) {
            queryParameters = queryParameters.set('idOrderConditions', <any>idOrderConditions);
        }
        if (valueGross !== undefined && valueGross !== null) {
            queryParameters = queryParameters.set('valueGross', <any>valueGross);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (idLunchMenuCategories) {
            idLunchMenuCategories.forEach((element) => {
                queryParameters = queryParameters.append('idLunchMenuCategories', <any>element);
            })
        }
        if (isPublicMenu !== undefined && isPublicMenu !== null) {
            queryParameters = queryParameters.set('isPublicMenu', <any>isPublicMenu);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OrderDiscount>('get',`${this.basePath}/user/orderDiscountObject`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get orders to pay
     * 
     * @param userId 
     * @param restaurantId 
     * @param language 
     * @param isOnlinePayment 
     * @param dateMin min delivery date inclusive
     * @param dateMax max delivery date inclusive
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrdersToPay(userId: number, restaurantId: number, language: string, isOnlinePayment?: boolean, dateMin?: string, dateMax?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Order>>;
    public getOrdersToPay(userId: number, restaurantId: number, language: string, isOnlinePayment?: boolean, dateMin?: string, dateMax?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Order>>>;
    public getOrdersToPay(userId: number, restaurantId: number, language: string, isOnlinePayment?: boolean, dateMin?: string, dateMax?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Order>>>;
    public getOrdersToPay(userId: number, restaurantId: number, language: string, isOnlinePayment?: boolean, dateMin?: string, dateMax?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getOrdersToPay.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getOrdersToPay.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getOrdersToPay.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (isOnlinePayment !== undefined && isOnlinePayment !== null) {
            queryParameters = queryParameters.set('isOnlinePayment', <any>isOnlinePayment);
        }
        if (dateMin !== undefined && dateMin !== null) {
            queryParameters = queryParameters.set('dateMin', <any>dateMin);
        }
        if (dateMax !== undefined && dateMax !== null) {
            queryParameters = queryParameters.set('dateMax', <any>dateMax);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Order>>('get',`${this.basePath}/user/ordersToPay`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get public order
     * 
     * @param userId 
     * @param language 
     * @param body 
     * @param date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublicOrder(userId: number, language: string, body?: PaginationSortingParameters, date?: string, observe?: 'body', reportProgress?: boolean): Observable<OrderPagination>;
    public getPublicOrder(userId: number, language: string, body?: PaginationSortingParameters, date?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderPagination>>;
    public getPublicOrder(userId: number, language: string, body?: PaginationSortingParameters, date?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderPagination>>;
    public getPublicOrder(userId: number, language: string, body?: PaginationSortingParameters, date?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getPublicOrder.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getPublicOrder.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OrderPagination>('post',`${this.basePath}/user/publicOrder`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * pay order
     * 
     * @param email 
     * @param firstSecondName 
     * @param orderId 
     * @param restaurantId 
     * @param toPayValue 
     * @param bankGroupId 
     * @param urlReturn 
     * @param isLunchMenu 
     * @param language 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payOrder(email: string, firstSecondName: string, orderId: number, restaurantId: number, toPayValue: number, bankGroupId: number, urlReturn: string, isLunchMenu: boolean, language: string, userId?: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public payOrder(email: string, firstSecondName: string, orderId: number, restaurantId: number, toPayValue: number, bankGroupId: number, urlReturn: string, isLunchMenu: boolean, language: string, userId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public payOrder(email: string, firstSecondName: string, orderId: number, restaurantId: number, toPayValue: number, bankGroupId: number, urlReturn: string, isLunchMenu: boolean, language: string, userId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public payOrder(email: string, firstSecondName: string, orderId: number, restaurantId: number, toPayValue: number, bankGroupId: number, urlReturn: string, isLunchMenu: boolean, language: string, userId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling payOrder.');
        }

        if (firstSecondName === null || firstSecondName === undefined) {
            throw new Error('Required parameter firstSecondName was null or undefined when calling payOrder.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling payOrder.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling payOrder.');
        }

        if (toPayValue === null || toPayValue === undefined) {
            throw new Error('Required parameter toPayValue was null or undefined when calling payOrder.');
        }

        if (bankGroupId === null || bankGroupId === undefined) {
            throw new Error('Required parameter bankGroupId was null or undefined when calling payOrder.');
        }

        if (urlReturn === null || urlReturn === undefined) {
            throw new Error('Required parameter urlReturn was null or undefined when calling payOrder.');
        }

        if (isLunchMenu === null || isLunchMenu === undefined) {
            throw new Error('Required parameter isLunchMenu was null or undefined when calling payOrder.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling payOrder.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (firstSecondName !== undefined && firstSecondName !== null) {
            queryParameters = queryParameters.set('firstSecondName', <any>firstSecondName);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (toPayValue !== undefined && toPayValue !== null) {
            queryParameters = queryParameters.set('toPayValue', <any>toPayValue);
        }
        if (bankGroupId !== undefined && bankGroupId !== null) {
            queryParameters = queryParameters.set('bankGroupId', <any>bankGroupId);
        }
        if (urlReturn !== undefined && urlReturn !== null) {
            queryParameters = queryParameters.set('UrlReturn', <any>urlReturn);
        }
        if (isLunchMenu !== undefined && isLunchMenu !== null) {
            queryParameters = queryParameters.set('isLunchMenu', <any>isLunchMenu);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('post',`${this.basePath}/user/payOrder`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * pay orders
     * 
     * @param userId 
     * @param restaurantId 
     * @param toPayValue 
     * @param token 
     * @param idsLunchMenu 
     * @param idsMenu 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payOrdersContactless(userId: number, restaurantId: number, toPayValue: number, token: string, idsLunchMenu?: Array<number>, idsMenu?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public payOrdersContactless(userId: number, restaurantId: number, toPayValue: number, token: string, idsLunchMenu?: Array<number>, idsMenu?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public payOrdersContactless(userId: number, restaurantId: number, toPayValue: number, token: string, idsLunchMenu?: Array<number>, idsMenu?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public payOrdersContactless(userId: number, restaurantId: number, toPayValue: number, token: string, idsLunchMenu?: Array<number>, idsMenu?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling payOrdersContactless.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling payOrdersContactless.');
        }

        if (toPayValue === null || toPayValue === undefined) {
            throw new Error('Required parameter toPayValue was null or undefined when calling payOrdersContactless.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling payOrdersContactless.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (toPayValue !== undefined && toPayValue !== null) {
            queryParameters = queryParameters.set('toPayValue', <any>toPayValue);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (idsLunchMenu) {
            idsLunchMenu.forEach((element) => {
                queryParameters = queryParameters.append('idsLunchMenu', <any>element);
            })
        }
        if (idsMenu) {
            idsMenu.forEach((element) => {
                queryParameters = queryParameters.append('idsMenu', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('post',`${this.basePath}/user/payOrdersContactless`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * pay orders online
     * 
     * @param userId 
     * @param restaurantId 
     * @param toPayValue 
     * @param bankGroupId 
     * @param urlReturn 
     * @param language 
     * @param idsLunchMenu 
     * @param idsMenu 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payOrdersOnline(userId: number, restaurantId: number, toPayValue: number, bankGroupId: number, urlReturn: string, language: string, idsLunchMenu?: Array<number>, idsMenu?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public payOrdersOnline(userId: number, restaurantId: number, toPayValue: number, bankGroupId: number, urlReturn: string, language: string, idsLunchMenu?: Array<number>, idsMenu?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public payOrdersOnline(userId: number, restaurantId: number, toPayValue: number, bankGroupId: number, urlReturn: string, language: string, idsLunchMenu?: Array<number>, idsMenu?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public payOrdersOnline(userId: number, restaurantId: number, toPayValue: number, bankGroupId: number, urlReturn: string, language: string, idsLunchMenu?: Array<number>, idsMenu?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling payOrdersOnline.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling payOrdersOnline.');
        }

        if (toPayValue === null || toPayValue === undefined) {
            throw new Error('Required parameter toPayValue was null or undefined when calling payOrdersOnline.');
        }

        if (bankGroupId === null || bankGroupId === undefined) {
            throw new Error('Required parameter bankGroupId was null or undefined when calling payOrdersOnline.');
        }

        if (urlReturn === null || urlReturn === undefined) {
            throw new Error('Required parameter urlReturn was null or undefined when calling payOrdersOnline.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling payOrdersOnline.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (toPayValue !== undefined && toPayValue !== null) {
            queryParameters = queryParameters.set('toPayValue', <any>toPayValue);
        }
        if (bankGroupId !== undefined && bankGroupId !== null) {
            queryParameters = queryParameters.set('bankGroupId', <any>bankGroupId);
        }
        if (urlReturn !== undefined && urlReturn !== null) {
            queryParameters = queryParameters.set('UrlReturn', <any>urlReturn);
        }
        if (idsLunchMenu) {
            idsLunchMenu.forEach((element) => {
                queryParameters = queryParameters.append('idsLunchMenu', <any>element);
            })
        }
        if (idsMenu) {
            idsMenu.forEach((element) => {
                queryParameters = queryParameters.append('idsMenu', <any>element);
            })
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('post',`${this.basePath}/user/payOrders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payment return
     * 
     * @param userId 
     * @param orderId 
     * @param isLunchMenu 
     * @param paymentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentReturn(userId: number, orderId: number, isLunchMenu: boolean, paymentId: number, observe?: 'body', reportProgress?: boolean): Observable<PaymentReturnResponse>;
    public paymentReturn(userId: number, orderId: number, isLunchMenu: boolean, paymentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentReturnResponse>>;
    public paymentReturn(userId: number, orderId: number, isLunchMenu: boolean, paymentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentReturnResponse>>;
    public paymentReturn(userId: number, orderId: number, isLunchMenu: boolean, paymentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling paymentReturn.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling paymentReturn.');
        }

        if (isLunchMenu === null || isLunchMenu === undefined) {
            throw new Error('Required parameter isLunchMenu was null or undefined when calling paymentReturn.');
        }

        if (paymentId === null || paymentId === undefined) {
            throw new Error('Required parameter paymentId was null or undefined when calling paymentReturn.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (isLunchMenu !== undefined && isLunchMenu !== null) {
            queryParameters = queryParameters.set('isLunchMenu', <any>isLunchMenu);
        }
        if (paymentId !== undefined && paymentId !== null) {
            queryParameters = queryParameters.set('paymentId', <any>paymentId);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaymentReturnResponse>('post',`${this.basePath}/user/paymentReturn`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * place order
     * 
     * @param body 
     * @param isLunchMenu 
     * @param userId 
     * @param shiftId 
     * @param restaurantId 
     * @param idOrderConditions 
     * @param date 
     * @param deliveryHours 
     * @param deliveryPrice 
     * @param paymentMethod 
     * @param language 
     * @param idPickupPoint 
     * @param comment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public placeOrder(body: UserPlaceOrderBody, isLunchMenu: boolean, userId: number, shiftId: number, restaurantId: number, idOrderConditions: number, date: string, deliveryHours: string, deliveryPrice: number, paymentMethod: string, language: string, idPickupPoint?: number, comment?: string, observe?: 'body', reportProgress?: boolean): Observable<PlaceOrderResponse>;
    public placeOrder(body: UserPlaceOrderBody, isLunchMenu: boolean, userId: number, shiftId: number, restaurantId: number, idOrderConditions: number, date: string, deliveryHours: string, deliveryPrice: number, paymentMethod: string, language: string, idPickupPoint?: number, comment?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PlaceOrderResponse>>;
    public placeOrder(body: UserPlaceOrderBody, isLunchMenu: boolean, userId: number, shiftId: number, restaurantId: number, idOrderConditions: number, date: string, deliveryHours: string, deliveryPrice: number, paymentMethod: string, language: string, idPickupPoint?: number, comment?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PlaceOrderResponse>>;
    public placeOrder(body: UserPlaceOrderBody, isLunchMenu: boolean, userId: number, shiftId: number, restaurantId: number, idOrderConditions: number, date: string, deliveryHours: string, deliveryPrice: number, paymentMethod: string, language: string, idPickupPoint?: number, comment?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling placeOrder.');
        }

        if (isLunchMenu === null || isLunchMenu === undefined) {
            throw new Error('Required parameter isLunchMenu was null or undefined when calling placeOrder.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling placeOrder.');
        }

        if (shiftId === null || shiftId === undefined) {
            throw new Error('Required parameter shiftId was null or undefined when calling placeOrder.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling placeOrder.');
        }

        if (idOrderConditions === null || idOrderConditions === undefined) {
            throw new Error('Required parameter idOrderConditions was null or undefined when calling placeOrder.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling placeOrder.');
        }

        if (deliveryHours === null || deliveryHours === undefined) {
            throw new Error('Required parameter deliveryHours was null or undefined when calling placeOrder.');
        }

        if (deliveryPrice === null || deliveryPrice === undefined) {
            throw new Error('Required parameter deliveryPrice was null or undefined when calling placeOrder.');
        }

        if (paymentMethod === null || paymentMethod === undefined) {
            throw new Error('Required parameter paymentMethod was null or undefined when calling placeOrder.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling placeOrder.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isLunchMenu !== undefined && isLunchMenu !== null) {
            queryParameters = queryParameters.set('isLunchMenu', <any>isLunchMenu);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (shiftId !== undefined && shiftId !== null) {
            queryParameters = queryParameters.set('shiftId', <any>shiftId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (idOrderConditions !== undefined && idOrderConditions !== null) {
            queryParameters = queryParameters.set('idOrderConditions', <any>idOrderConditions);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (deliveryHours !== undefined && deliveryHours !== null) {
            queryParameters = queryParameters.set('deliveryHours', <any>deliveryHours);
        }
        if (idPickupPoint !== undefined && idPickupPoint !== null) {
            queryParameters = queryParameters.set('idPickupPoint', <any>idPickupPoint);
        }
        if (deliveryPrice !== undefined && deliveryPrice !== null) {
            queryParameters = queryParameters.set('deliveryPrice', <any>deliveryPrice);
        }
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }
        if (paymentMethod !== undefined && paymentMethod !== null) {
            queryParameters = queryParameters.set('paymentMethod', <any>paymentMethod);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PlaceOrderResponse>('post',`${this.basePath}/user/placeOrder`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * set status public order
     * 
     * @param userId 
     * @param orderId 
     * @param isLunchMenu 
     * @param status 
     * @param comment 
     * @param dateTimeDelivery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicOrderSetStatus(userId: number, orderId: number, isLunchMenu: boolean, status: string, comment?: string, dateTimeDelivery?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public publicOrderSetStatus(userId: number, orderId: number, isLunchMenu: boolean, status: string, comment?: string, dateTimeDelivery?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public publicOrderSetStatus(userId: number, orderId: number, isLunchMenu: boolean, status: string, comment?: string, dateTimeDelivery?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public publicOrderSetStatus(userId: number, orderId: number, isLunchMenu: boolean, status: string, comment?: string, dateTimeDelivery?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling publicOrderSetStatus.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling publicOrderSetStatus.');
        }

        if (isLunchMenu === null || isLunchMenu === undefined) {
            throw new Error('Required parameter isLunchMenu was null or undefined when calling publicOrderSetStatus.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling publicOrderSetStatus.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (isLunchMenu !== undefined && isLunchMenu !== null) {
            queryParameters = queryParameters.set('isLunchMenu', <any>isLunchMenu);
        }
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }
        if (dateTimeDelivery !== undefined && dateTimeDelivery !== null) {
            queryParameters = queryParameters.set('dateTimeDelivery', <any>dateTimeDelivery.toISOString());
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/user/publicOrder/setStatus`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * rate order
     * 
     * @param body dish rates
     * @param userId 
     * @param orderId 
     * @param isLunchMenu 
     * @param comment 
     * @param isPublicMenu 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rateOrder(body: Array<OrderRateItem>, userId: number, orderId: number, isLunchMenu: boolean, comment?: string, isPublicMenu?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public rateOrder(body: Array<OrderRateItem>, userId: number, orderId: number, isLunchMenu: boolean, comment?: string, isPublicMenu?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public rateOrder(body: Array<OrderRateItem>, userId: number, orderId: number, isLunchMenu: boolean, comment?: string, isPublicMenu?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public rateOrder(body: Array<OrderRateItem>, userId: number, orderId: number, isLunchMenu: boolean, comment?: string, isPublicMenu?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling rateOrder.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling rateOrder.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling rateOrder.');
        }

        if (isLunchMenu === null || isLunchMenu === undefined) {
            throw new Error('Required parameter isLunchMenu was null or undefined when calling rateOrder.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (isLunchMenu !== undefined && isLunchMenu !== null) {
            queryParameters = queryParameters.set('isLunchMenu', <any>isLunchMenu);
        }
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }
        if (isPublicMenu !== undefined && isPublicMenu !== null) {
            queryParameters = queryParameters.set('isPublicMenu', <any>isPublicMenu);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/user/rateOrder`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * set pickup status
     * 
     * @param userId 
     * @param orderId 
     * @param isLunchMenu 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setOrderPickupStatus(userId: number, orderId: number, isLunchMenu: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setOrderPickupStatus(userId: number, orderId: number, isLunchMenu: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setOrderPickupStatus(userId: number, orderId: number, isLunchMenu: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setOrderPickupStatus(userId: number, orderId: number, isLunchMenu: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling setOrderPickupStatus.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling setOrderPickupStatus.');
        }

        if (isLunchMenu === null || isLunchMenu === undefined) {
            throw new Error('Required parameter isLunchMenu was null or undefined when calling setOrderPickupStatus.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (isLunchMenu !== undefined && isLunchMenu !== null) {
            queryParameters = queryParameters.set('isLunchMenu', <any>isLunchMenu);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/user/order/setPickupStatus`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update order payment method
     * 
     * @param userId 
     * @param orderId 
     * @param isLunchMenu 
     * @param paymentMethod 
     * @param isPublicMenu 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrderPaymentMethod(userId: number, orderId: number, isLunchMenu: boolean, paymentMethod: string, isPublicMenu?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateOrderPaymentMethod(userId: number, orderId: number, isLunchMenu: boolean, paymentMethod: string, isPublicMenu?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateOrderPaymentMethod(userId: number, orderId: number, isLunchMenu: boolean, paymentMethod: string, isPublicMenu?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateOrderPaymentMethod(userId: number, orderId: number, isLunchMenu: boolean, paymentMethod: string, isPublicMenu?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling updateOrderPaymentMethod.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling updateOrderPaymentMethod.');
        }

        if (isLunchMenu === null || isLunchMenu === undefined) {
            throw new Error('Required parameter isLunchMenu was null or undefined when calling updateOrderPaymentMethod.');
        }

        if (paymentMethod === null || paymentMethod === undefined) {
            throw new Error('Required parameter paymentMethod was null or undefined when calling updateOrderPaymentMethod.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (isLunchMenu !== undefined && isLunchMenu !== null) {
            queryParameters = queryParameters.set('isLunchMenu', <any>isLunchMenu);
        }
        if (isPublicMenu !== undefined && isPublicMenu !== null) {
            queryParameters = queryParameters.set('isPublicMenu', <any>isPublicMenu);
        }
        if (paymentMethod !== undefined && paymentMethod !== null) {
            queryParameters = queryParameters.set('paymentMethod', <any>paymentMethod);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/user/updateOrderPaymentMethod`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
