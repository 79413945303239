import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Day, MenuService, OrderService, User, UserCalendarService} from '../../../api';
import {Observable, Subject, Subscription} from 'rxjs';
import {tap, takeUntil, take, switchMap, distinctUntilChanged} from 'rxjs/operators';
import {AuthService} from '../../_auth/auth.service';
import {ToastService} from '../../_notification/toast.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Preferences} from '../../_interfaces/preferences';
import {AppService} from '../../_app/app.service';
import {OrderMenuService} from '../../_order/order-menu.service';
import {NavController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Events} from '@ionic/angular';

@Component({
    selector: 'app-week-calendar',
    templateUrl: './week-calendar.component.html',
    styleUrls: ['./week-calendar.component.scss'],
})
export class WeekCalendarComponent implements OnInit, OnDestroy {
    public shiftId;
    public restaurantId;
    public user$: Observable<User>;
    private user: User;
    public week: Day[];
    public today: Date;
    public weekLabel: string;
    public firstDayOfWeek: Date;

    public preferences: Preferences;
    public preferences$: Observable<Preferences>;
    private readonly destroy$: Subject<void> = new Subject();

    private subParams: Subscription;
    public selectedLng: string;

    constructor(public events: Events,
                public platform: Platform,
                private translate: TranslateService,
                private route: ActivatedRoute,
                public userMenu: MenuService,
                public navCtrl: NavController,
                public orderService: OrderService,
                public orderMenuService: OrderMenuService,
                public router: Router,
                public appService: AppService,
                public toast: ToastService,
                public authService: AuthService,
                public datePipe: DatePipe,
                public userCalendar: UserCalendarService) {


        this.subParams = this.route.params.subscribe(params => {
            this.today = new Date();
            this.firstDayOfWeek = new Date();
            this.firstDayOfWeek.setDate(this.today.getDate() - (this.today.getDay() + 6) % 7);
            this.authService.currentUser
                .pipe(
                    take(1),
                    tap((user: User) => this.user = user),
                    switchMap(() => this.preferences$ = this.appService.currentPreferences.pipe(
                        distinctUntilChanged((prev, current) => {
                            return prev.restaurantId === current.restaurantId;
                        }),
                        tap((preferences: Preferences) => this.preferences = preferences), takeUntil(this.destroy$))),

                    takeUntil(this.destroy$)
                )
                .subscribe(preferences => {
                    this.preferences = preferences;
                    this.getWeek(this.firstDayOfWeek);

                });
        });

    }

    ngOnInit() {
        this.selectedLng = this.appService.selectedLang;

        // subskrybuje event zamknięcia menu po usunieciu zamowienia.
        this.events.subscribe('menuOrder:closed', (res) => {
            this.resetWeek();
        });
    }

    private getWeek(date: Date) {
        const endOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() + (7 - date.getDay()));
        const monday = this.datePipe.transform(date, 'yyyy-MM-dd');
        const sunday = this.datePipe.transform(endOfWeek, 'dd.MM.yyyy');

        this.weekLabel = this.datePipe.transform(date, 'dd.MM') + ' - ' + sunday;


        if (this.preferences.shiftId && this.preferences.restaurantId) {
            this.userCalendar.getUserCalendar(this.user.id, monday, this.preferences.shiftId, this.preferences.restaurantId, 7).subscribe(res => {

                    this.week = res;

                }, error2 => {
                    if (error2.status !== 401 && error2.status !== 403) {
                        this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
                    }
                }
            );
        }


    }

    //https://forum.ionicframework.com/t/solved-can-i-disable-a-checkbox-from-activating-when-clicking-on-a-label/95120/12
    openMenu(ev: any, day) {

        ev.stopImmediatePropagation();
        const current = new Date();

        // wyrównuje czas dnia z kalendarza do obecnego aby przy porównaniu nie było różnicy w czasie. + 1 sek
        const date = new Date(day.date);
        date.setHours(current.getHours(), current.getMinutes(), current.getSeconds() + 1);


        // KIOSK & MOBILE & DESKTOP: dzień przeszły, nie ma zamówień
        if (date < current && !day.isOrdered) {

            this.toast.show(this.translate.instant('CALENDAR.Nie-masz-zadnych-zamowien-na-dzien-day', {day: this.datePipe.transform(date, 'EEEE d MMMM', null, this.appService.getSelectedLngIsoCode())}), this.translate.instant('CALENDAR.Nie-mozesz-rowniez-skladac-zamowien-na-dzien-przes'), 2000, 'top');

        }
        // KIOSK & MOBILE & DESKTOP: nie ma menu
        else if (!day.isEnabled) {
            this.toast.show(this.translate.instant('CALENDAR.Brak-menu-na-dzien-day-przepraszamy', {day: this.datePipe.transform(date, 'EEEE d MMMM', null, this.appService.getSelectedLngIsoCode())}), null, 2000, 'top');

        }
        // KIOSK & MOBILE & DESKTOP: dzień przeszły, są zamówienia
        else if (date < current && day.isOrdered) {

            this.orderService.getOrder(
                this.user.id,
                this.preferences.language,
                null,
                this.preferences.shiftId,
                this.preferences.restaurantId,
                this.datePipe.transform(date, 'yyyy-MM-dd')
            ).subscribe(res => {
                    if (res) {
                        // console.log(res);
                        this.orderMenuService.setOrders(res);
                        this.orderMenuService.displayOrder();
                    }
                }, error2 => {
                    if (error2.status !== 401 && error2.status !== 403) {
                        this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
                    }
                }
            );
        }
        // KIOSK & MOBILE & DESKTOP: dzień PRZYszły, jest menu
        else if (date >= current && day.isEnabled) {


            this.userMenu.getMenu(this.user.id, this.preferences.shiftId, this.preferences.restaurantId, this.datePipe.transform(date, 'yyyy-MM-dd'), this.preferences.language).subscribe(res => {


                if (day.isOrdered) {


                    this.orderService.getOrder(
                        this.user.id,
                        this.preferences.language,
                        null,
                        this.preferences.shiftId,
                        this.preferences.restaurantId,
                        this.datePipe.transform(date, 'yyyy-MM-dd')
                    ).subscribe(res => {
                            if (res) {
                                //  let isEditable = false;

                                if (!this.platform.is('ios') && !this.platform.is('android') && !this.appService.isDesktop) {
                                    // KIOSK: są zamówienia i jest menu ale nie moze juz edytować
                                    this.toast.show(this.translate.instant('CALENDAR.Nie-mozesz-juz-skladac-zamowien-na-ten-dzien'), this.translate.instant('CALENDAR.Masz-juz-zapisane-zamowienia'), 2000, 'top');
                                } else {
                                    //  MOBILE & DESKTOP: są zamówienia i jest menu i  moze edytować
                                    //   isEditable = true;

                                }
                                this.orderMenuService.setOrders(res);

                                //  this.orderMenuService.setOrders(res, isEditable);
                                this.orderMenuService.displayOrder();
                            }
                        }, error2 => {
                            if (error2.status !== 401 && error2.status !== 403) {
                                this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
                            }
                        }
                    );

                } else {
                    //  KIOSK & MOBILE & DESKTOP: nie ma zamowien i jest menu i  moze edytować , tworzyc
                    const copyPreferences = JSON.parse(JSON.stringify(this.preferences));
                    copyPreferences.menu = {
                        date: this.datePipe.transform(date, 'yyyy-MM-dd')
                    };
                    this.appService.setPreferences(copyPreferences);
                    this.navCtrl.navigateForward(['menu/week']);

                }
            }, error2 => {


                if (day.isOrdered) {
                    this.orderService.getOrder(
                        this.user.id,
                        this.preferences.language,
                        null,
                        this.preferences.shiftId,
                        this.preferences.restaurantId,
                        this.datePipe.transform(date, 'yyyy-MM-dd')
                    ).subscribe(res => {
                            if (res) {
                                // KIOSK & MOBILE & DESKTOP: są zamówienia ale menu nie ma dostępnego

                                this.toast.show(this.translate.instant('CALENDAR.Nie-mozesz-juz-skladac-zamowien-na-ten-dzien'), this.translate.instant('CALENDAR.Masz-juz-zapisane-zamowienia'), 2000, 'top');
                                this.orderMenuService.setOrders(res);
                                this.orderMenuService.displayOrder();
                            }
                        }, error2 => {
                            if (error2.status !== 401 && error2.status !== 403) {
                                this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
                            }
                        }
                    );

                } else {

                    if (error2.status !== 401 && error2.status !== 403) {
                        // KIOSK & MOBILE & DESKTOP: nie ma zamowien i nie moze skladac juz zamowien
                        this.toast.show(this.translate.instant('CALENDAR.Nie-masz-zadnych-zamowien-na-dzien-day', {day: this.datePipe.transform(date, 'EEEE d MMMM', null, this.appService.getSelectedLngIsoCode())}), this.translate.instant('CALENDAR.Nie-mozesz-juz-skladac-zamowien-na-ten-dzien'), 2000, 'top');
                    }
                }
            });

        }

    }


    getClass(day) {
        const currentDate: Date = new Date();
        const date = new Date(day.date);
        currentDate.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);


        if (!day.isEnabled || date < currentDate) {
            return 'disabledDay';

        }
        //TODO current day ma sie wyswietlac inaczej, rozny format dat jest debbuger pokazal
        if (date.getDay() === currentDate.getDay()) {

            //return 'currentDay';
        }
    }

    changeWeek(next: boolean) {

        if (next) {

            this.today.setDate((this.today.getDate() + (5 + 3 - this.today.getDay())));
            this.getWeek(this.today);
        } else {

            // cofam date na poprzeni tydzien
            this.today.setDate((this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 7));
            // biore poniedzialek
            this.today.setDate(this.today.getDate() - (this.today.getDay() + 6) % 7);
            this.getWeek(this.today);


        }

    }

    resetWeek() {

        this.firstDayOfWeek = new Date();
        this.today = new Date();
        this.firstDayOfWeek.setDate(this.today.getDate() - (this.today.getDay() + 6) % 7);
        this.getWeek(this.firstDayOfWeek);
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.subParams.unsubscribe();
        this.events.unsubscribe('menuOrder:closed');
    }


}

