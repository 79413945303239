import {Component, Input, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import {Preferences} from '../../../_interfaces/preferences';
import {Router} from '@angular/router';
import {AppService} from '../../../_app/app.service';
import {ModalController, Platform} from '@ionic/angular';
import {InvoiceAddressPage} from '../../../_modal/invoice-address/invoice-address.page';
import {RestaurantInfoPage} from '../../../_modal/restaurant-info/restaurant-info.page';

@Component({
    selector: 'app-restaurant-header',
    templateUrl: './restaurant-header.component.html',
    styleUrls: ['./restaurant-header.component.scss'],
})
export class RestaurantHeaderComponent implements OnInit {
    @Input() name: string;
    @Input() logoFile: string;
    public defaultHref: string;
    private preferences: Preferences;

    constructor(public platform: Platform,
                private modalCtrl: ModalController,
                public appService: AppService) {
    }

    ngOnInit() {

        this.appService.currentPreferences.pipe(take(1)).subscribe((preferences: Preferences) => {
            // ustawiam domyśly path powrotu z app token i ids aby nie gubił.
            this.defaultHref = 'welcome/' + preferences.appToken + '/' + preferences.ids;

            this.preferences = preferences;


        });

    }

    public async displayRestaurantInfo() {

        const restaurantInfoWindow = await this.modalCtrl.create({
            component: RestaurantInfoPage,
            animated: true,
            componentProps: {
                restaurant: this.preferences.restaurants[0],
            }

        });
        restaurantInfoWindow.onDidDismiss()
            .then((data) => {

            });

        await restaurantInfoWindow.present();

    }


}
