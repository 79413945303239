import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastService} from '../_notification/toast.service';
import {AuthService} from '../_auth/auth.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private translate: TranslateService,
                private toast: ToastService,
                private authenticationService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].indexOf(err.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api

                this.authenticationService.logout(true);
                // location.reload(true);

            }


            //const error = err.error.message || err.statusText;
            //return throwError(error);
            return throwError(err);
        }));
    }
}