import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NavController, Platform} from '@ionic/angular';
import {AppService} from '../../_app/app.service';
import {Preferences} from '../../_interfaces/preferences';
import {takeUntil, take} from 'rxjs/operators';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
    @Input() title: string;
    @Input() parentTitle: string;
    @Input() disableBack: boolean;
    public currentRoute: string;
    public newPassword: boolean;
    public defaultHref: string;


    constructor(public platform: Platform,
                public appService: AppService,
                private router: Router) {
    }

    ngOnInit() {

        this.newPassword = this.router.url.includes('new-password');
        this.currentRoute = this.router.url;

        this.appService.currentPreferences.pipe(take(1)).subscribe((preferences: Preferences) => {
            // ustawiam domyśly path powrotu z app token i ids aby nie gubił.
            this.defaultHref = 'welcome/' + preferences.appToken + '/' + preferences.ids;

        });



    }


}
