import {Injectable, OnDestroy} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {User} from '../../../api';
import {take, takeUntil, tap} from 'rxjs/operators';
import {AuthService} from '../../_auth/auth.service';
import {map, skip, skipWhile} from 'rxjs/internal/operators';
import {Preferences} from '../../_interfaces/preferences';
import {AppService} from '../../_app/app.service';
import {NavController} from '@ionic/angular';
import {RestaurantService} from '../../_restaurant/restaurant.service';


@Injectable({
    providedIn: 'root'
})
export class CanActivateRestaurantsService implements CanActivate, OnDestroy {

    private user: User;
    private readonly destroy$: Subject<void> = new Subject();
    public preferences: Preferences;
    private didActivateAlready = false;

    constructor(private navCtrl: NavController,
                private authService: AuthService,
                private restaurantService: RestaurantService,
                private appService: AppService) {


    }

    canActivate(): Observable<boolean> | boolean {

        if (this.didActivateAlready) {
            return true;
        }

        return this.appService.currentPreferences
            .pipe(skip(1), take(1), takeUntil(this.destroy$), map(preferences => {
                this.preferences = preferences;

                if (this.preferences.restaurants && this.preferences.restaurants.length === 1) {

                    const copyPreferences = JSON.parse(JSON.stringify(this.preferences));

                    copyPreferences.restaurantId = this.preferences.restaurants[0].id;
                    this.appService.setPreferences(copyPreferences);
                    this.navCtrl.navigateForward(['calendar']);
                    return false;
                }
                this.didActivateAlready = true;
                return true;

            }));

    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
