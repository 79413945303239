import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.page.html',
    styleUrls: ['./order-details.page.scss'],
})
export class OrderDetailsPage implements OnInit {
    @Input() order;
    @Input() user;

    constructor(private modalController: ModalController) {
    }

    ngOnInit() {

    }

    async closeModal() {
        await this.modalController.dismiss();
    }

}
