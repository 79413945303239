import {NgModule} from '@angular/core';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {RouterModule} from '@angular/router';
import {FullCalendarComponent} from './full-calendar/full-calendar.component';
import {WeekCalendarComponent} from './week-calendar/week-calendar.component';

import {NgCalendarModule} from 'ionic2-calendar';
import {MatExpansionModule} from '@angular/material';
import {FormsModule} from '@angular/forms';
import {PopoverComponent} from '../menu/_components/popover/popover.component';
import {TranslateModule} from '@ngx-translate/core';
import {AdsFooterComponent} from './ads-footer/ads-footer.component';
import {RestaurantHeaderComponent} from './publicOrder/restaurant-header/restaurant-header.component';
import {PublicHeaderComponent} from './publicOrder/public-header/public-header.component';
import {OrderDetailsComponent} from './publicOrder/order-details/order-details.component';

@NgModule({
    imports: [

        CommonModule,
        RouterModule,
        IonicModule,
        FormsModule,
        NgCalendarModule,
        MatExpansionModule,
        TranslateModule
    ],
    entryComponents: [PopoverComponent],
    declarations: [PopoverComponent, HeaderComponent, FooterComponent, AdsFooterComponent, BreadcrumbsComponent, FullCalendarComponent, WeekCalendarComponent, RestaurantHeaderComponent, PublicHeaderComponent, OrderDetailsComponent],
    exports: [PopoverComponent, HeaderComponent, FooterComponent, AdsFooterComponent, BreadcrumbsComponent, FullCalendarComponent, WeekCalendarComponent, RestaurantHeaderComponent, PublicHeaderComponent, OrderDetailsComponent],
})

export class ComponentsModule {
}


