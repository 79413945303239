/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ProductBadges = 'GLUTEN_FREE' | 'BESTSELLER' | 'PROMOTION' | 'NOVELTY' | 'VEGETARIAN';

export const ProductBadges = {
    GLUTENFREE: 'GLUTEN_FREE' as ProductBadges,
    BESTSELLER: 'BESTSELLER' as ProductBadges,
    PROMOTION: 'PROMOTION' as ProductBadges,
    NOVELTY: 'NOVELTY' as ProductBadges,
    VEGETARIAN: 'VEGETARIAN' as ProductBadges
};