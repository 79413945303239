/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OrderPaymentDetails { 
    paymentId?: number;
    status?: OrderPaymentDetails.StatusEnum;
    date?: Date;
}
export namespace OrderPaymentDetails {
    export type StatusEnum = 'NOT_REQUIRED' | 'REQUIRED' | 'PAID';
    export const StatusEnum = {
        NOTREQUIRED: 'NOT_REQUIRED' as StatusEnum,
        REQUIRED: 'REQUIRED' as StatusEnum,
        PAID: 'PAID' as StatusEnum
    };
}