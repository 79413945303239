import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BasketDetails, Menu, MenuProduct, Order, OrderConditions, OrderPickupPoint, OrderService} from '../../api';
import {Cart} from '../_interfaces/cart';
import {ActionSheetController, AlertController, MenuController} from '@ionic/angular';
import {ToastService} from '../_notification/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {SingleMenuService} from '../menu/_service/single-menu.service';


@Injectable({
    providedIn: 'root'
})
export class CartService {

    private orderedProducts: MenuProduct[] = [];
    public cartNew: Cart[] = [];
    private cartItemCount = new BehaviorSubject(0);
    private unsavedCarts = new BehaviorSubject(false);


    constructor(private translate: TranslateService,
                private orderService: OrderService,
                public alertController: AlertController,
                public menuCtrl: MenuController,
                public actionSheetController: ActionSheetController,
                public menu: MenuController,
                public toast: ToastService) {
    }


    getCart() {
        return this.cartNew;
    }

    getCartItemCount() {
        return this.cartItemCount;
    }

    getUnsavedCarts() {
        return this.unsavedCarts;
    }

    setUnsavedCarts(value: boolean) {
        this.unsavedCarts.next(value);
    }

    checkUnsavedCarts() {

        for (const c of this.cartNew) {
            if (!c.isSaved) {
                this.unsavedCarts.next(true);
                return true;

            } else {
                this.unsavedCarts.next(false);

            }

        }


    }

    getCurrentCartTotalValueGross(id) {
        for (const c of this.cartNew) {
            if (c.id === id) {
                return c.products.reduce((i, j) => i + j.priceGross * j.qtyOrdered, 0);
            }

        }
    }


    setActiveCart(id) {
        for (const c of this.cartNew) {
            if (c.id === id) {

                c.isActive = true;

            } else {
                c.isActive = false;
            }

        }
    }

    resetActiveCarts() {

        for (const c of this.cartNew) {

            c.isActive = false;
        }

    }

    setSavedCart(id, values?, setActive?: boolean) {

        for (const c of this.cartNew) {
            if (c.id === id) {
                c.isSaved = true;
                if (setActive) {
                    c.isActive = true;
                }
                if (values) {

                    c.order = {
                        orderId: values.orderId,
                        deliveryHour: values.deliveryHour,
                        paymentMethod: values.paymentMethod,
                        pickupPoint: values.pickupPoint,
                        comment: values.comment,
                        restaurantName: values.restaurantName,
                        shiftName: values.shiftName,
                        timePlaceOrder: values.timePlaceOrder,
                        invoiceAddress: values.invoiceAddress,
                        paymentDetails: values.paymentDetails

                    };
                }

            }

        }
        this.checkUnsavedCarts();
    }


    setUnsavedCart(id) {
        for (const c of this.cartNew) {
            if (c.id === id) {
                c.isSaved = false;

            }

        }
        this.checkUnsavedCarts();
    }


    addProduct(product, menu, fromOrder?: boolean) {

        let cartAdded = false;
        let productAdded = false;
        //szukam koszyka czy istnieje
        for (const c of this.cartNew) {
            // TODO WH c.id === menu.id && c.isLunchMenu === menu.isLunchMenu-> c.orderConditions.id === menu.orderConditions.id && c.isLunchMenu === menu.isLunchMenu
            if (c.orderConditions.id === menu.orderConditions.id && c.isLunchMenu === menu.isLunchMenu) {

                c.isSaved = false;

                //znalazl koszyk , szukam produktu czy istniee
                for (const p of c.products) {
                    //TODO WH dodane && p.categoryId === product.categoryId

                    if (p.id === product.id && p.categoryId === product.categoryId) {

                        p.qtyOrdered += (fromOrder) ? p.qtyOrdered : 1;
                        // zmieniamy ilosc w menu , potrzebne w przypadku gdy raz cos dodasz w menu koszykowym, zmienisz menu wrocicsz i chcesz z menu koszykowego zwiekszyc ten sam produkt
                        product.qtyOrdered = p.qtyOrdered;

                        productAdded = true;

                        break;
                    }
                }
                if (!productAdded) {
                    product.qtyOrdered = (fromOrder) ? product.qtyOrdered : 1;
                    c.products.push(product);
                    this.cartItemCount.next(this.cartItemCount.value + 1);
                }
                cartAdded = true;
                break;
            }

        }
        //nie znalazlem koszyka , dodaje nowy koszyk z produktem
        if (!cartAdded) {

            product.qtyOrdered = (fromOrder) ? product.qtyOrdered : 1;
            const cartItem: Cart = {
                id: menu.id,
                name: (menu.isLunchMenu) ? 'Lunch menu' : menu.name, // TODO usunąć nie aktualne
                isLunchMenu: menu.isLunchMenu,
                isPublicMenu: menu.isPublicMenu,
                isSaved: false,
                orderType: menu.orderType,
                orderConditions: menu.orderConditions,
                products: [product],
                deductedFromPay: menu.deductedFromPay
            };


            this.cartNew.push(cartItem);
            this.cartItemCount.next(this.cartItemCount.value + 1);


        }

        this.checkUnsavedCarts();
    }


    decreaseProduct(product, cartId) {

        for (const c of this.cartNew) {
            if (c.id === cartId) {
                c.isSaved = false;

                for (const [index, p] of c.products.entries()) {
                    // TODO WH dodałem && p.categoryId === product.categoryId
                    if (p.id === product.id && p.categoryId === product.categoryId) {

                        if (p.qtyOrdered > 1) {
                            p.qtyOrdered -= 1;
                        }
                    }
                }
            }
        }
        this.checkUnsavedCarts();
    }

    updateProductsPrices(categories, cartId) {
        if (!categories) {
            return false;
        }
        for (const category of categories) {
            for (const c of this.cartNew) {
                if (c.id === cartId) {
                    for (const [index, p] of c.products.entries()) {
                        if (p.categoryId === category.id) {
                            p.priceGross = category.price;
                        }
                    }
                }
            }

        }
    }


    getProduct(product, cartId) {

        for (const [index, p] of this.cartNew.entries()) {

            if (p.id === cartId) {

                for (const [index2, p2] of p.products.entries()) {
                    if (p2.id === product.id) {
                        return p2;
                    }
                }

            }
        }

    }

    getProductByOrderConditions(product, cart) {

        for (const [index, p] of this.cartNew.entries()) {

            if (p.orderConditions.id === cart.orderConditions.id && p.isLunchMenu === cart.isLunchMenu) {

                for (const [index2, p2] of p.products.entries()) {
                    //TODO WH dodałem && p2.categoryId === product.categoryId
                    if (p2.id === product.id && p2.categoryId === product.categoryId) {
                        return p2;
                    }
                }

            }
        }

    }


    removeProduct(product, cartId) {

        for (const [index, p] of this.cartNew.entries()) {
            if (p.id === cartId) {
                p.isSaved = false;

                for (const [index2, p2] of p.products.entries()) {
                    //TODO WH dodane && p2.categoryId === product.categoryId

                    if (p2.id === product.id && p2.categoryId === product.categoryId) {
                        // debugger
                        this.cartItemCount.next(this.cartItemCount.value - 1);
                        p.products.splice(index2, 1);
                        if (p.products.length === 0) {
                            this.cartNew.splice(index, 1);
                            return true;
                        }
                    }
                }
                break;
            }
        }
        this.checkUnsavedCarts();
    }

    clearCart(cartId) {

        //if (cartId) {
        let itemCount = 0;
        for (const [index, p] of this.cartNew.entries()) {
            if (p.id === cartId) {
                itemCount = p.products.length;
                for (const [index2, p2] of p.products.entries()) {

                    p.products.splice(index2);

                }
                this.cartNew.splice(index, 1);
                break;
            }
        }


        this.cartItemCount.next(this.cartItemCount.value - itemCount);
        //  }
        this.checkUnsavedCarts();
    }


    removeCarts() {

        this.cartNew = [];
        this.cartItemCount.next(0);
        this.checkUnsavedCarts();

    }


    displayCart() {


        // const cart = this.getCart();
        // if (cart.length > 1) {
        //     this.cartListActionSheet();
        //
        // } else if (cart.length === 0) {
        //     this.toast.show(this.translate.instant('CART.Twoj-koszyk-jest-pusty'), null, 2000, 'top');
        //
        // } else if (cart.length === 1) {
        //     this.menu.enable(true, 'menuCart');
        //     this.menu.open('menuCart');
        // }
        this.menu.enable(true, 'menuCart');
        this.menu.open('menuCart');

    }


    async cartListActionSheet() {
        const actionSheet = await this.actionSheetController.create({
            header: this.translate.instant('CART.Wybierz-koszyk').toUpperCase(),
            subHeader: this.translate.instant('CART.Koszyki-zostaly-podzielone-automatycznie-ze-wzgled'),
            mode: 'ios',
            cssClass: 'headerActionSheet',
            buttons: this.createActionSheetButtons()
        });
        await actionSheet.present();
    }


    createActionSheetButtons() {
        const buttons = [];

        for (const c of this.getCart()) {

            const button = {
                text: c.name,
                icon: (c.isSaved) ? 'checkmark-circle' : 'radio-button-off',
                cssClass: (c.isSaved) ? 'buttonActiveActionSheet' : 'buttonActionSheet cartActionSheet',
                handler: () => {
                    this.setActiveCart(c.id);


                    this.menu.close('menuCart').then(on => {
                        this.menu.enable(true, 'menuCart');
                        this.menu.open('menuCart');
                    })

                    return true;
                }
            }

            const closeButton = {
                text: this.translate.instant('APP.Zamknij').toUpperCase(),
                icon: 'close-circle',
                role: 'cancel',
                cssClass: 'closeButtonActionSheet'
            };
            buttons.push(button);
            buttons.push(closeButton);
        }
        return buttons;
    }


    getOrderedProducts(categories) {
        for (const category of categories) {
            if (category.products != null) {
                for (const product of category.products) {
                    if (product.qtyOrdered >= 1) {
                        this.orderedProducts.push(product);
                    }
                }
            }
            if (category.categories != null) {
                this.getOrderedProducts(category.categories);
            }
        }
    }


    makeCartsFromOrder(menus: Menu[], userId: number, shiftId: number, restaurantId: number, date: string, order: Order, editOrderId?: number, disableDisplayMenuCart?: boolean) {


        for (const menu of menus) {
            this.orderedProducts = [];
            this.getOrderedProducts(menu.categories);

            for (const product of this.orderedProducts) {

                this.addProduct(product, menu, true);
            }
            this.setSavedCart(menu.id);
            this.orderService.getBasketDetails(menu.isLunchMenu, userId, shiftId, restaurantId, menu.orderConditions.id, date, 'PL').subscribe(details => {

                    const values = {
                        orderId: details.orderId,
                        deliveryHour: details.deliveryTime,
                        paymentMethod: details.paymentMethod,
                        pickupPoint: details.idPickupPoint,
                        comment: details.orderComment,
                        restaurantName: order[0].restaurantName,
                        shiftName: order[0].shiftName,
                        timePlaceOrder: order[0].timePlaceOrder,
                        invoiceAddress: (details.invoiceAddress) ? details.invoiceAddress : null,
                        paymentDetails: (details.paymentDetails) ? details.paymentDetails : null

                    };
                    // ustawiam id dla mat-expansion-panel do otwarcia edycja
                    let setActive = false;
                    if (editOrderId === details.orderId) {
                        setActive = true;
                    }

                    //wrzucam drugi raz setSavedCart z szczegółami poniewaz jak wrzuce tylko tutaj to czasami nie zalapuje
                    this.setSavedCart(menu.id, values, setActive);

                    if (!disableDisplayMenuCart) {
                        this.menuCtrl.open('menuCart');
                        this.menuCtrl.enable(true, 'menuCart');
                    }

                }
                , error2 => {
                    if (error2.status !== 401 && error2.status !== 403 && error2.status !== 404) {
                        this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
                    }
                }
            );

        }


    }


    async unsavedCartsConfirmAlert(): Promise<any> {

        return new Promise(async (resolve) => {
            const alert = await this.alertController.create({
                backdropDismiss: false,
                cssClass: 'customAlert',
                header: this.translate.instant('CART.Czy-chcesz-zapisac-swoj-koszyk'),
                message: this.translate.instant('CART.Jesli-nie-zapiszesz-koszyka-Twoje-zamowienie-nie-z'),
                buttons: [
                    {
                        text: this.translate.instant('APP.NIE'),
                        cssClass: 'alertBtnGrey',
                        handler: (handler) => {
                            resolve(true);
                        }
                    },
                    {
                        text: this.translate.instant('CART.ZAPISZ-KOSZYK'),
                        role: 'cancel',
                        cssClass: 'alertBtnSuccess',
                        handler: (handler) => {
                            resolve(false);
                        }
                    }
                ]
            });
            alert.present();
        });
    }


    updateOrderConditions(newOrderConditions: OrderConditions) {

        for (const [index, c] of this.cartNew.entries()) {

            c.orderConditions = newOrderConditions;

        }

    }


}