import {Component, Input, OnInit} from '@angular/core';
import {Advertisement, User, UserAdvertisementsService} from '../../../api';
import {AdvertisementPage} from '../../_modal/advertisement/advertisement.page';
import {ModalController, Platform} from '@ionic/angular';

@Component({
    selector: 'app-ads-footer',
    templateUrl: './ads-footer.component.html',
    styleUrls: ['./ads-footer.component.scss'],
})
export class AdsFooterComponent implements OnInit {
    @Input() ad: Advertisement;
    @Input() user: User;
    @Input() selectedLng: string;

    constructor(private modalCtrl: ModalController, private advertisementsService: UserAdvertisementsService, public  platform: Platform) {
    }

    ngOnInit() {

    }

    async onAdvertisementView() {

        const advertisementView = await this.modalCtrl.create({
            component: AdvertisementPage,
            animated: true,
            cssClass: 'adsModal',
            componentProps: {
                ad: this.ad
            }

        });
        advertisementView.onDidDismiss().then(data => {
            this.markViewed();

        });
        return await
            advertisementView.present();


    }

    markViewed() {
        this.advertisementsService.userAdvertisementMarkViewed(this.user.id, this.ad.id, 'ORDERING', this.selectedLng).subscribe();
        this.ad = null;
    }


}
