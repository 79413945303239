import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActionSheetController, MenuController} from '@ionic/angular';
import {ToastService} from '../_notification/toast.service';
import {Order} from '../../api';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class OrderMenuService {


    public order: Order[] = [];
    public activeOrder: number;

    // public isEditable: boolean;

    constructor(private translate: TranslateService,
                public actionSheetController: ActionSheetController,
                public menu: MenuController,
                public toast: ToastService) {
    }

    getOrder() {
        return this.order;
    }

    setOrders(orders) {

        this.order = [];
        for (const order of orders) {
            this.order.push(order);
        }
    }


    // setOrders(orders, isEditable?: boolean) {
    //     this.isEditable = isEditable;
    //     this.order = [];
    //     for (const order of orders) {
    //         this.order.push(order);
    //     }
    // }


    setActiveOrder(id) {
        this.activeOrder = id;
    }

    getActiveOrder() {
        return this.activeOrder;
    }

    displayOrder(returnArray?: boolean) {


        const order = this.getOrder();

        // if (order.length > 1 && !returnArray) {
        //     this.orderListActionSheet();
        //
        //
        // } else if (order.length >= 1 && returnArray) {
        //     return this.getOrder();
        // }
        //
        // else if (order.length === 1) {
        //     this.menu.enable(true, 'menuOrder');
        //     this.menu.open('menuOrder');
        // }

        if (returnArray) {
            return this.getOrder();
        }

        else {
            this.menu.enable(true, 'menuOrder');
            this.menu.open('menuOrder');
        }

    }

    async orderListActionSheet() {
        const actionSheet = await this.actionSheetController.create({
            header: this.translate.instant('ORDER.WYBIERZ-ZAMOWIENIE'),
            subHeader: this.translate.instant('ORDER.Zamowienia-zostaly-podzielone-automatycznie-ze-wzg'),
            mode: 'ios',
            cssClass: 'headerActionSheet',
            buttons: this.createActionSheetButtons()
        });
        await actionSheet.present();
    }


    createActionSheetButtons() {
        const buttons = [];
        for (const c of this.getOrder()) {
            const name = (c.isLunchMenu) ? this.translate.instant('ORDER.Zamowienie-Lunch-menu') : this.translate.instant('ORDER.Zamowienie-ID') + c.id;
            const button = {
                text: name,
                icon: 'cart',
                cssClass: (c.id === this.getActiveOrder()) ? 'buttonActiveActionSheet' : 'buttonActionSheet',
                handler: () => {
                    this.setActiveOrder(c.id);


                    this.menu.close('menuOrder').then(on => {
                        this.menu.enable(true, 'menuOrder');
                        this.menu.open('menuOrder');
                    })

                    return true;
                }
            }

            const closeButton = {
                text: this.translate.instant('APP.Zamknij').toUpperCase(),
                icon: 'close-circle',
                role: 'cancel',
                cssClass: 'closeButtonActionSheet'
            };
            buttons.push(button);
            buttons.push(closeButton);
        }
        return buttons;
    }

}
