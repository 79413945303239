// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// ionic build  --prod
//



export const environment = {
    production: false,
    //apiBasePath: 'https://app.e-lunch.pl/tpay/API/eLunch',
    apiBasePath: 'https://app.e-lunch.pl/dev/API/eLunch',
    //apiBasePath: 'http://10.20.10.3:8888/API/eLunch', // localhost VPN
    newPasswordHost: 'https://kiosk.e-lunch.pl/#/new-password',
    host: 'https://app.e-lunch.pl/dev',
    defaultLanguage: 'PL',
    userIdle: false,
    enablePushNotification: true,
    googleFirebaseProjectNumber: '1048307522359',
    oneSignalAppId: '822605ae-f5fc-492d-88ef-b3523f2242ed',
    safariWebId: 'web.onesignal.auto.195e7e66-9dea-4e11-b56c-b4a654da5ab7',
    appStoreUrl: 'https://apps.apple.com/pl/app/e-lunch/id1500780586?l=pl',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.elunch.kiosk',
    codeVersion: 168
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
