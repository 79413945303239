import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Restaurant} from '../../../api';
import {GoogleMapsService} from '../../_googleMaps/google-maps.service';
import {ToastService} from '../../_notification/toast.service';
import {IonContent} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

declare var google: any;

@Component({
    selector: 'app-restaurant-info',
    templateUrl: './restaurant-info.page.html',
    styleUrls: ['./restaurant-info.page.scss'],
})
export class RestaurantInfoPage implements OnInit {
    @Input() restaurant: Restaurant;
    @ViewChild('contactMap', null) contactMap: any;
    @ViewChild('pickupPointsMap', null) pickupPointsMap: any;
    @ViewChild('deliveryAreasMap', null) deliveryAreasMap: any;
    @ViewChild(IonContent, {static: true}) private content: IonContent;
    private GoogleGeoCode: any;
    private GoogleMap: any;
    public segment: string = 'contact';
    private pickupPointsMapLoaded: boolean;
    private deliveryAreasMapLoaded: boolean;
    public mapHeight: any;

    constructor(private modalController: ModalController,
                private toast: ToastService,
                public translate: TranslateService,
                private googleMapsService: GoogleMapsService) {


        this.GoogleGeoCode = new google.maps.Geocoder();

    }


    ngOnInit() {


        this.googleMapsService.geoCodeAddress(this.restaurant.street, this.restaurant.buildingNumber, this.restaurant.zipCode, this.restaurant.city).then(on => {

            const infowindow = new google.maps.InfoWindow({
                content: '<div style="padding: 0 15px 15px 0"><b>' + this.restaurant.name + '</b><br>' + this.restaurant.street + ' ' + this.restaurant.buildingNumber + '<br>' + this.restaurant.zipCode + ' ' + this.restaurant.city + '</div>',
                maxWidth: 200
            });

            const mapProp = {
                gestureHandling: 'cooperative',
                center: on[0].geometry.location,
                zoom: 13,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                clickableIcons: false
            };
            const map = new google.maps.Map(this.contactMap.nativeElement, mapProp);


            const marker = new google.maps.Marker({
                position: on[0].geometry.location,
                map,
                title: this.restaurant.street + ' ' + this.restaurant.buildingNumber + ', ' + this.restaurant.zipCode + ' ' + this.restaurant.city,
            });

            marker.addListener('click', function () {
                infowindow.open(map, marker);
            });
            infowindow.open(map, marker);


        }).catch(reason => {
            this.toast.show(reason, null, 2000, 'top', 'danger');
        });

    }

    async closeModal() {

        await this.modalController.dismiss();

    }

    async segmentChanged(ev: any) {

        this.segment = ev.detail.value;

        // TODO zrobic automatyczne height dla kontenera map.
        // this.content.getScrollElement().then(scrollElement => {
        //     this.mapHeight = scrollElement.clientHeight;
        // });
        if (this.segment === 'deliveryAreas' && !this.deliveryAreasMapLoaded) {

            setTimeout(() => {
                this.renderDeliveryAreasMap();
            }, 300);

        }


    }

    renderPickupPointsMap() {
        const mapProp = {
            zoom: 13,
            gestureHandling: 'cooperative',
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            clickableIcons: false
        };
        const map = new google.maps.Map(this.pickupPointsMap.nativeElement, mapProp);
        const bounds = new google.maps.LatLngBounds();

        this.restaurant.pickupPoints.forEach((o, i) => {

            this.googleMapsService.geoCodeAddress(o.address.street, o.address.buildingNumber, o.address.zipCode, o.address.city).then(on => {

                const phone = (o.phone) ? '<br>' + this.translate.instant('APP.tel') + ': ' + o.phone : '';
                const mobilePhone = (o.mobilePhone) ? '<br>' + this.translate.instant('APP.tel-kom') + ': ' + o.mobilePhone : '';
                const email = (o.email) ? '<br><a href="mailto:' + o.email + '">' + o.email + '</a>' : '';

                const infowindow = new google.maps.InfoWindow({
                    content: '<div style="padding: 0 15px 15px 0"><b>' + o.name + '</b><br>' + o.address.street + ' ' + o.address.buildingNumber + '<br>' + o.address.zipCode + ' ' + o.address.city +
                    '<br>' + phone + '' + mobilePhone + '' + email + '</div>',
                    maxWidth: 200
                });
                const marker = new google.maps.Marker({
                    position: on[0].geometry.location,
                    title: o.address.street + ' ' + o.address.buildingNumber + ', ' + o.address.zipCode + ' ' + o.address.city,
                });

                marker.addListener('click', function () {
                    infowindow.open(map, marker);
                });
                marker.setMap(map);
                infowindow.open(map, marker);

                bounds.extend(on[0].geometry.location);
                map.fitBounds(bounds);
            });
        });


        setTimeout(function () {


            map.setCenter(bounds.getCenter());
        }, 100);

        this.pickupPointsMapLoaded = true;
    }

    renderDeliveryAreasMap() {


        const mapProp = {
            streetViewControl: false,
            gestureHandling: 'cooperative',
            fullscreenControl: false,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            clickableIcons: false
        };
        const map = new google.maps.Map(this.deliveryAreasMap.nativeElement, mapProp);
        const bounds = new google.maps.LatLngBounds();


        this.restaurant.deliveryAreas.forEach((area) => {
            const cords = [];
            area.coordinates.forEach((cord) => {
                cords.push({lat: cord.latitude, lng: cord.longitude});

            });
            const randomColor = Math.floor(Math.random() * 16777215).toString(16);
            const polygon = new google.maps.Polygon({
                strokeColor: '#' + randomColor,
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#' + randomColor,
                fillOpacity: .5,
                paths: cords
            });
            polygon.setMap(map);

            polygon.getPath().forEach(function (paths, index) {
                bounds.extend(paths);
            });
            map.fitBounds(bounds);

        });
        setTimeout(function () {


            map.setCenter(bounds.getCenter());
        }, 100);


        this.deliveryAreasMapLoaded = true;
    }
}
