import {Component, OnDestroy, OnInit} from '@angular/core';
import {PoStorage} from '../../../_interfaces/poStorage';
import {AppService} from '../../../_app/app.service';
import {Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {ModalController, Platform} from '@ionic/angular';
import {DeliveryAddressPage} from '../../../_modal/delivery-address/delivery-address.page';
import {Router} from '@angular/router';

declare var google: any;

@Component({
    selector: 'app-public-header',
    templateUrl: './public-header.component.html',
    styleUrls: ['./public-header.component.scss'],
})
export class PublicHeaderComponent implements OnInit, OnDestroy {

    public poStorage: PoStorage;
    private readonly destroy$: Subject<void> = new Subject();
    public currentRoute: string;

    constructor(public appService: AppService,
                private router: Router,
                public platform: Platform,
                public modalCtrl: ModalController) {

        this.currentRoute = this.router.url;

    }

    ngOnInit() {

        this.appService.currentPoStorage.pipe(tap((poStorage: PoStorage) => {
            this.poStorage = poStorage;
        }), takeUntil(this.destroy$)).subscribe(res => {
            // if (!this.poStorage.deliveryAddress) {
            //     this.deliveryAddress();
            // }
        });


    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();

    }


    public async deliveryAddress() {
        const deliveryAddress = await this.modalCtrl.create({
            component: DeliveryAddressPage,
            animated: true,
            backdropDismiss: false,
            cssClass: 'deliveryAddressModal',
            componentProps: {
                // selectDate: true
            }

        });

        await deliveryAddress.present();
    }


}
