/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Menu } from '../model/menu';
import { OrderConditions } from '../model/orderConditions';
import { PlaceOrderErrorResponse } from '../model/placeOrderErrorResponse';
import { PlaceOrderResponse } from '../model/placeOrderResponse';
import { PublicOrdersPlacePublicOrderBody } from '../model/publicOrdersPlacePublicOrderBody';
import { Restaurant } from '../model/restaurant';
import { UpdateUserProfileErrorInfo } from '../model/updateUserProfileErrorInfo';
import { User } from '../model/user';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PublicOrdersService {

    protected basePath = 'https://virtserver.swaggerhub.com/e-Lunch.pl/kiosk/1.0.1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * get order conditions
     * 
     * @param restaurantId 
     * @param orderConditionsId 
     * @param date 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderConditions(restaurantId: number, orderConditionsId: number, date: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<OrderConditions>;
    public getOrderConditions(restaurantId: number, orderConditionsId: number, date: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderConditions>>;
    public getOrderConditions(restaurantId: number, orderConditionsId: number, date: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderConditions>>;
    public getOrderConditions(restaurantId: number, orderConditionsId: number, date: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getOrderConditions.');
        }

        if (orderConditionsId === null || orderConditionsId === undefined) {
            throw new Error('Required parameter orderConditionsId was null or undefined when calling getOrderConditions.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getOrderConditions.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (orderConditionsId !== undefined && orderConditionsId !== null) {
            queryParameters = queryParameters.set('orderConditionsId', <any>orderConditionsId);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OrderConditions>('get',`${this.basePath}/publicOrders/orderConditions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get public menu
     * 
     * @param restaurantId 
     * @param date 
     * @param language 
     * @param pickupPointId 
     * @param deliveryAreaId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublicMenu(restaurantId: number, date: string, language: string, pickupPointId?: number, deliveryAreaId?: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Menu>>;
    public getPublicMenu(restaurantId: number, date: string, language: string, pickupPointId?: number, deliveryAreaId?: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Menu>>>;
    public getPublicMenu(restaurantId: number, date: string, language: string, pickupPointId?: number, deliveryAreaId?: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Menu>>>;
    public getPublicMenu(restaurantId: number, date: string, language: string, pickupPointId?: number, deliveryAreaId?: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getPublicMenu.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getPublicMenu.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getPublicMenu.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (pickupPointId !== undefined && pickupPointId !== null) {
            queryParameters = queryParameters.set('pickupPointId', <any>pickupPointId);
        }
        if (deliveryAreaId !== undefined && deliveryAreaId !== null) {
            queryParameters = queryParameters.set('deliveryAreaId', <any>deliveryAreaId);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Menu>>('get',`${this.basePath}/publicOrders/menu`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get public single menu
     * 
     * @param restaurantId 
     * @param date 
     * @param language 
     * @param id ID of menu (null for lunch menu)
     * @param pickupPointId 
     * @param deliveryAreaId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublicSingleMenu(restaurantId: number, date: string, language: string, id?: number, pickupPointId?: number, deliveryAreaId?: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Menu>;
    public getPublicSingleMenu(restaurantId: number, date: string, language: string, id?: number, pickupPointId?: number, deliveryAreaId?: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Menu>>;
    public getPublicSingleMenu(restaurantId: number, date: string, language: string, id?: number, pickupPointId?: number, deliveryAreaId?: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Menu>>;
    public getPublicSingleMenu(restaurantId: number, date: string, language: string, id?: number, pickupPointId?: number, deliveryAreaId?: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getPublicSingleMenu.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getPublicSingleMenu.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getPublicSingleMenu.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (pickupPointId !== undefined && pickupPointId !== null) {
            queryParameters = queryParameters.set('pickupPointId', <any>pickupPointId);
        }
        if (deliveryAreaId !== undefined && deliveryAreaId !== null) {
            queryParameters = queryParameters.set('deliveryAreaId', <any>deliveryAreaId);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Menu>('get',`${this.basePath}/publicOrders/singleMenu`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get restaurant by id
     * 
     * @param restaurantId 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRestaurant(restaurantId: number, language: string, observe?: 'body', reportProgress?: boolean): Observable<Restaurant>;
    public getRestaurant(restaurantId: number, language: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Restaurant>>;
    public getRestaurant(restaurantId: number, language: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Restaurant>>;
    public getRestaurant(restaurantId: number, language: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getRestaurant.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getRestaurant.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Restaurant>('get',`${this.basePath}/publicOrders/restaurant`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * place public order
     * 
     * @param body 
     * @param isLunchMenu 
     * @param restaurantId 
     * @param idOrderConditions 
     * @param date 
     * @param deliveryTime 
     * @param isASAP 
     * @param deliveryPrice 
     * @param paymentMethod 
     * @param language 
     * @param firstName 
     * @param lastName 
     * @param phone 
     * @param email 
     * @param idPickupPoint 
     * @param comment 
     * @param deliveryAreaId 
     * @param street 
     * @param buildingNumber 
     * @param apartmentNumber 
     * @param city 
     * @param zipCode 
     * @param userId 
     * @param password 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public placePublicOrder(body: PublicOrdersPlacePublicOrderBody, isLunchMenu: boolean, restaurantId: number, idOrderConditions: number, date: string, deliveryTime: string, isASAP: boolean, deliveryPrice: number, paymentMethod: string, language: string, firstName: string, lastName: string, phone: string, email: string, idPickupPoint?: number, comment?: string, deliveryAreaId?: number, street?: string, buildingNumber?: string, apartmentNumber?: string, city?: string, zipCode?: string, userId?: number, password?: string, observe?: 'body', reportProgress?: boolean): Observable<PlaceOrderResponse>;
    public placePublicOrder(body: PublicOrdersPlacePublicOrderBody, isLunchMenu: boolean, restaurantId: number, idOrderConditions: number, date: string, deliveryTime: string, isASAP: boolean, deliveryPrice: number, paymentMethod: string, language: string, firstName: string, lastName: string, phone: string, email: string, idPickupPoint?: number, comment?: string, deliveryAreaId?: number, street?: string, buildingNumber?: string, apartmentNumber?: string, city?: string, zipCode?: string, userId?: number, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PlaceOrderResponse>>;
    public placePublicOrder(body: PublicOrdersPlacePublicOrderBody, isLunchMenu: boolean, restaurantId: number, idOrderConditions: number, date: string, deliveryTime: string, isASAP: boolean, deliveryPrice: number, paymentMethod: string, language: string, firstName: string, lastName: string, phone: string, email: string, idPickupPoint?: number, comment?: string, deliveryAreaId?: number, street?: string, buildingNumber?: string, apartmentNumber?: string, city?: string, zipCode?: string, userId?: number, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PlaceOrderResponse>>;
    public placePublicOrder(body: PublicOrdersPlacePublicOrderBody, isLunchMenu: boolean, restaurantId: number, idOrderConditions: number, date: string, deliveryTime: string, isASAP: boolean, deliveryPrice: number, paymentMethod: string, language: string, firstName: string, lastName: string, phone: string, email: string, idPickupPoint?: number, comment?: string, deliveryAreaId?: number, street?: string, buildingNumber?: string, apartmentNumber?: string, city?: string, zipCode?: string, userId?: number, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling placePublicOrder.');
        }

        if (isLunchMenu === null || isLunchMenu === undefined) {
            throw new Error('Required parameter isLunchMenu was null or undefined when calling placePublicOrder.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling placePublicOrder.');
        }

        if (idOrderConditions === null || idOrderConditions === undefined) {
            throw new Error('Required parameter idOrderConditions was null or undefined when calling placePublicOrder.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling placePublicOrder.');
        }

        if (deliveryTime === null || deliveryTime === undefined) {
            throw new Error('Required parameter deliveryTime was null or undefined when calling placePublicOrder.');
        }

        if (isASAP === null || isASAP === undefined) {
            throw new Error('Required parameter isASAP was null or undefined when calling placePublicOrder.');
        }

        if (deliveryPrice === null || deliveryPrice === undefined) {
            throw new Error('Required parameter deliveryPrice was null or undefined when calling placePublicOrder.');
        }

        if (paymentMethod === null || paymentMethod === undefined) {
            throw new Error('Required parameter paymentMethod was null or undefined when calling placePublicOrder.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling placePublicOrder.');
        }

        if (firstName === null || firstName === undefined) {
            throw new Error('Required parameter firstName was null or undefined when calling placePublicOrder.');
        }

        if (lastName === null || lastName === undefined) {
            throw new Error('Required parameter lastName was null or undefined when calling placePublicOrder.');
        }

        if (phone === null || phone === undefined) {
            throw new Error('Required parameter phone was null or undefined when calling placePublicOrder.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling placePublicOrder.');
        }











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isLunchMenu !== undefined && isLunchMenu !== null) {
            queryParameters = queryParameters.set('isLunchMenu', <any>isLunchMenu);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (idOrderConditions !== undefined && idOrderConditions !== null) {
            queryParameters = queryParameters.set('idOrderConditions', <any>idOrderConditions);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (deliveryTime !== undefined && deliveryTime !== null) {
            queryParameters = queryParameters.set('deliveryTime', <any>deliveryTime);
        }
        if (isASAP !== undefined && isASAP !== null) {
            queryParameters = queryParameters.set('isASAP', <any>isASAP);
        }
        if (idPickupPoint !== undefined && idPickupPoint !== null) {
            queryParameters = queryParameters.set('idPickupPoint', <any>idPickupPoint);
        }
        if (deliveryPrice !== undefined && deliveryPrice !== null) {
            queryParameters = queryParameters.set('deliveryPrice', <any>deliveryPrice);
        }
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }
        if (paymentMethod !== undefined && paymentMethod !== null) {
            queryParameters = queryParameters.set('paymentMethod', <any>paymentMethod);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }
        if (deliveryAreaId !== undefined && deliveryAreaId !== null) {
            queryParameters = queryParameters.set('deliveryAreaId', <any>deliveryAreaId);
        }
        if (firstName !== undefined && firstName !== null) {
            queryParameters = queryParameters.set('firstName', <any>firstName);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }
        if (phone !== undefined && phone !== null) {
            queryParameters = queryParameters.set('phone', <any>phone);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (street !== undefined && street !== null) {
            queryParameters = queryParameters.set('street', <any>street);
        }
        if (buildingNumber !== undefined && buildingNumber !== null) {
            queryParameters = queryParameters.set('buildingNumber', <any>buildingNumber);
        }
        if (apartmentNumber !== undefined && apartmentNumber !== null) {
            queryParameters = queryParameters.set('apartmentNumber', <any>apartmentNumber);
        }
        if (city !== undefined && city !== null) {
            queryParameters = queryParameters.set('city', <any>city);
        }
        if (zipCode !== undefined && zipCode !== null) {
            queryParameters = queryParameters.set('zipCode', <any>zipCode);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PlaceOrderResponse>('post',`${this.basePath}/publicOrders/placePublicOrder`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * register public order user
     * 
     * @param firstName 
     * @param lastName 
     * @param phone 
     * @param email 
     * @param password 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerPublicOrderUser(firstName: string, lastName: string, phone: string, email: string, password: string, language: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public registerPublicOrderUser(firstName: string, lastName: string, phone: string, email: string, password: string, language: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public registerPublicOrderUser(firstName: string, lastName: string, phone: string, email: string, password: string, language: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public registerPublicOrderUser(firstName: string, lastName: string, phone: string, email: string, password: string, language: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (firstName === null || firstName === undefined) {
            throw new Error('Required parameter firstName was null or undefined when calling registerPublicOrderUser.');
        }

        if (lastName === null || lastName === undefined) {
            throw new Error('Required parameter lastName was null or undefined when calling registerPublicOrderUser.');
        }

        if (phone === null || phone === undefined) {
            throw new Error('Required parameter phone was null or undefined when calling registerPublicOrderUser.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling registerPublicOrderUser.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling registerPublicOrderUser.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling registerPublicOrderUser.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (firstName !== undefined && firstName !== null) {
            queryParameters = queryParameters.set('firstName', <any>firstName);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }
        if (phone !== undefined && phone !== null) {
            queryParameters = queryParameters.set('phone', <any>phone);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('post',`${this.basePath}/publicOrders/register`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
