/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type RechargeFrequency = 'CALENDAR.DAILY' | 'CALENDAR.WEEKLY' | 'CALENDAR.MONTHLY';

export const RechargeFrequency = {
    DAILY: 'CALENDAR.DAILY' as RechargeFrequency,
    WEEKLY: 'CALENDAR.WEEKLY' as RechargeFrequency,
    MONTHLY: 'CALENDAR.MONTHLY' as RechargeFrequency
};