import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppService {


    public currentApp: Observable<any>;
    private appState = new ReplaySubject<any>(1);

    public currentKeys: Observable<any>;
    public keysState = new ReplaySubject<any>(1);

    public currentBiometric: Observable<any>;
    public biometricState = new ReplaySubject<any>(1);

    public currentPreferences: Observable<any>;
    public prefernecesState = new ReplaySubject<any>(1);

    public currentPoStorage: Observable<any>;
    public poStorageState = new ReplaySubject<any>(1);

    public selectedLang: string;
    private _isDesktop: boolean;


    constructor(private translate: TranslateService,
                private storage: Storage,
                private platform: Platform) {


        this.currentApp = this.appState.asObservable();
        this.currentPreferences = this.prefernecesState.asObservable();
        this.currentPoStorage = this.poStorageState.asObservable();
        this.currentKeys = this.keysState.asObservable();
        this.currentBiometric = this.biometricState.asObservable();

        this.platform.ready().then(async () => {
            this.setAppState();
            this.setPreferencesState();
            this.setPoStorageState();
            this.setKeysState();
            this.setBiometricState();
            this.checkDesktop();
        });
    }

    checkDesktop() {
                this.isDesktop = true;
    }

    async setPreferencesState() {
        this.storage.get('preferences').then(res => {
            if (res) {

                this.prefernecesState.next(res);
            }
        });
    }

    async setPoStorageState() {
        this.storage.get('poStorage').then(res => {
            if (res) {

                this.poStorageState.next(res);
            }
        });
    }


    async setAppState() {
        this.storage.get('app').then(res => {
            if (res) {
                this.appState.next(res);
            }
        });
    }

    async setKeysState() {
        this.storage.get('keys').then(res => {
            if (res) {
                this.keysState.next(res);
            }
        });
    }

    public removeKeys() {
        this.storage.remove('keys');
        this.keysState.next(null);
    }

    public setKeys(login, key, lang) {
        const keys = {
            login: login,
            key: key,
            lang: lang
        };
        this.storage.remove('keys');
        this.storage.set('keys', keys);
        this.keysState.next(keys);
    }


    async setBiometricState() {
        this.storage.get('biometricLogin').then(res => {
            this.biometricState.next(res);
        });
    }

    public removeBiometric() {
        this.storage.remove('biometricLogin');
        this.biometricState.next(null);
    }


    public setBiometric(value: boolean) {
        this.storage.remove('biometricLogin');
        this.storage.set('biometricLogin', value);
        this.biometricState.next(value);
    }


    public setApp(app) {
        this.storage.remove('app');
        this.storage.set('app', app);
        this.appState.next(app);
    }


    public setPreferences(preferences) {
        this.storage.remove('preferences');
        this.storage.set('preferences', preferences);
        this.prefernecesState.next(preferences);
    }

    public setPoStorage(poStorage) {
        this.storage.remove('poStorage');
        this.storage.set('poStorage', poStorage);
        this.poStorageState.next(poStorage);
    }

    public setInitialLanguage() {

        this.translate.setDefaultLang(environment.defaultLanguage);
        this.selectedLang = environment.defaultLanguage;
        this.storage.get('preferences').then(res => {
            if (res && res.language) {
                this.setLanguage(res.language);
                this.selectedLang = res.language;
            }
        });
    }

    setLanguage(lng) {

        this.translate.use(lng);
        this.selectedLang = lng;
        this.storage.get('preferences').then(res => {
            if (res) {
                res.language = lng;
                this.setPreferences(res);
            }
        });
    }

    getSelectedLngIsoCode() {
        return this.selectedLang.toLowerCase() + '_' + this.selectedLang.toUpperCase();
    }
    getSelectedLng() {
        return this.selectedLang;
    }


    get isDesktop(): boolean {
        return this._isDesktop;
    }

    set isDesktop(value: boolean) {
        this._isDesktop = value;
    }

    get isTouchableDevice(): boolean {
        if (!this.isDesktop || (this.platform.is('ios') || this.platform.is('android'))) {
            return true;
        }
        return false;

    }


}
