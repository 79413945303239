import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {CartService} from '../../_cart/cart.service';
import {AlertController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {SingleMenuService} from './single-menu.service';

// https://stackoverflow.com/questions/44130580/generic-type-candeactivatet-requires-1-type-arguments
export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: 'root'
})

export class CanDeactiveService implements CanDeactivate<CanComponentDeactivate> {

    constructor(private translate: TranslateService,
                public alertController: AlertController,
                private singleMenu: SingleMenuService,
                private cartService: CartService) {

    }

    canDeactivate() {


        if (this.cartService.getCart().length > 0 && !this.singleMenu.isOrderingDisabled) {


            let isSaved = false;
            for (const c of this.cartService.getCart()) {
                if (!c.isSaved) {

                    return this.presentConfirm().then(res => {
                        if (!res) {
                            this.cartService.displayCart();
                        }
                        return res;
                    });

                } else {

                    isSaved = true;
                }

            }
            if (isSaved) {

                this.singleMenu.removeSingleMenu();
                return true;
            }


        } else {
            this.singleMenu.removeSingleMenu();
            return true;
        }
    }

    async presentConfirm(): Promise<any> {
        return new Promise(async (resolve) => {
            const alert = await this.alertController.create({
                backdropDismiss: false,
                cssClass: 'customAlert',
                header: this.translate.instant('CART.Czy-chcesz-zapisac-swoj-koszyk'),
                message: this.translate.instant('CART.Jesli-nie-zapiszesz-koszyka-Twoje-zamowienie-nie-z'),
                buttons: [
                    {
                        text: this.translate.instant('APP.NIE'),
                        cssClass: 'alertBtnGrey',
                        handler: (handler) => {
                            this.singleMenu.removeSingleMenu();
                            resolve(true);
                        }
                    },
                    {
                        text: this.translate.instant('CART.ZAPISZ-KOSZYK'),
                        role: 'cancel',
                        cssClass: 'alertBtnSuccess',
                        handler: (handler) => {
                            resolve(false);
                        }
                    }
                ]
            });
            alert.present();
        });
    }


}
