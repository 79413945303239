import {Component, OnInit} from '@angular/core';
import {CartService} from '../../../_cart/cart.service';
import {Cart} from '../../../_interfaces/cart';
import {MenuController, PopoverController} from '@ionic/angular';

@Component({
    selector: 'app-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
    cart: Cart[] = [];

    constructor(public popover: PopoverController, public menu: MenuController, private  cartService: CartService) {

        this.cart = this.cartService.getCart();

    }

    displayCart(id) {

        this.menu.enable(true, 'menuCart');
        this.menu.open('menuCart');
        this.cartService.setActiveCart(id);
        this.close();
    }

    close() {
        this.popover.dismiss();
    }

    ngOnInit() {
    }

}
