import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {GuardService} from './_auth/guard.service';
import {AgreementsPageModule} from './agreements/agreements.module';
import {ShiftsPageModule} from './shifts/shifts.module';
import {CanDeactiveService} from './menu/_service/can-deactive.service';
import {CanDeactiveAgreementsService} from './agreements/_service/can-deactive-agreements.service';
import {CanActivateShiftsService} from './shifts/_service/can-activate-shifts.service';
import {CanActivateRestaurantsService} from './restaurants/_service/can-activate-restaurants.service';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'welcome',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: './login/login.module#LoginPageModule'
    },
    {
        path: 'login/:byCard',
        loadChildren: './login/login.module#LoginPageModule'
    },
    {
        path: 'welcome',
        loadChildren: './welcome/welcome.module#WelcomePageModule'
    },
    {
        path: 'welcome/:token',
        loadChildren: './welcome/welcome.module#WelcomePageModule'
    },
    {
        path: 'welcome/:token/:ids',
        loadChildren: './welcome/welcome.module#WelcomePageModule'
    },
    {
        path: 'register/:token',
        loadChildren: './register/register.module#RegisterPageModule'
    },
    {
        path: 'register/activate/:lang/:token',
        loadChildren: './register/register-activate/register-activate.module#RegisterActivatePageModule'
    },
    {
        path: 'help',
        loadChildren: './_modal/help/help.module#HelpPageModule'
    },
    {
        path: 'forgot-password',
        loadChildren: './_modal/forgot-password/forgot-password.module#ForgotPasswordPageModule'
    },
    {
        path: 'new-password/:lang/:token',
        loadChildren: './new-password/new-password.module#NewPasswordPageModule'
    },
    {
        path: 'profile',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './profile/profile.module#ProfilePageModule'
    },
    {
        path: 'profile/:tab',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './profile/profile.module#ProfilePageModule'
    },
    {
        path: 'payments',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './payments/payments.module#PaymentsPageModule'
    },

    {
        path: 'payments/:restaurantId',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './payments/payments.module#PaymentsPageModule'
    },
    {
        path: 'paymentsHistory',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './payments/payments-history/payments-history.module#PaymentsHistoryPageModule'
    },
    {
        path: 'restaurants',
        canActivate: [GuardService, CanActivateRestaurantsService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './restaurants/restaurants.module#RestaurantsPageModule'
    },
    {
        path: 'shifts',
        //  canActivate: [GuardService, CanActivateShiftsService],
        // data: {
        //     permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
        //     companyTypes: ['CLIENT', 'RESTAURANT']
        // },
        loadChildren: './shifts/shifts.module#ShiftsPageModule'

    },
    {
        path: 'calendar',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './calendar/calendar.module#CalendarPageModule'
    },
    {
        path: 'menu',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        canDeactivate: [CanDeactiveService],
        loadChildren: './menu/menu.module#MenuPageModule'
    },
    // {
    //     path: 'menu/:view',
    //     canActivate: [GuardService],
    //     data: {
    //         permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
    //         companyTypes: ['CLIENT', 'RESTAURANT']
    //     },
    //     canDeactivate: [CanDeactiveService],
    //     loadChildren: './menu/menu.module#MenuPageModule'
    // },
    {
        path: 'menu/:restaurantId',
        //  canActivate: [GuardService],
        canDeactivate: [CanDeactiveService],
        loadChildren: './menu/menu.module#MenuPageModule'
    },
    {   // public Orders
        path: 'menu/:restaurantId/:city/:zipCode/:street/:buildingNumber',
        //   canActivate: [GuardService],
        canDeactivate: [CanDeactiveService],
        loadChildren: './menu/menu.module#MenuPageModule'
    },
    {   // public Orders
        path: 'menu/:restaurantId/:pickupPointId',
        //   canActivate: [GuardService],
        canDeactivate: [CanDeactiveService],
        loadChildren: './menu/menu.module#MenuPageModule'
    },
    {
        path: 'agreements',
        // canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        canDeactivate: [CanDeactiveAgreementsService],
        loadChildren: './agreements/agreements.module#AgreementsPageModule'

    },
    {
        path: 'terms',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './agreements/terms/terms.module#TermsPageModule'
    },
    {
        path: 'dashboard',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT']
        },
        loadChildren: './dashboard/dashboard.module#DashboardPageModule'
    },
    {
        path: 'orders',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './orders/orders.module#OrdersPageModule'
    },
    {
        path: 'reports/bills',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './reports/bills-report/bills-report.module#BillsReportPageModule'
    },
    {
        path: 'reports/products',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './reports/products-report/products-report.module#ProductsReportPageModule'
    },
    {
        path: 'rate-order',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './_modal/rate-order/rate-order.module#RateOrderPageModule'
    },
    {
        path: 'invoice-address',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './_modal/invoice-address/invoice-address.module#InvoiceAddressPageModule'
    },
    {
        path: 'advertisement',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './_modal/advertisement/advertisement.module#AdvertisementPageModule'
    },
    {
        path: 'complaints',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './complaints/complaints.module#ComplaintsPageModule'
    },
    {
        path: 'complaints/create',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './complaints/create-complaint/create-complaint.module#CreateComplaintPageModule'
    },
    {
        path: 'complaint',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './_modal/complaint/complaint.module#ComplaintPageModule'
    },
    {
        path: 'restaurantPanel/order-pickup',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['CLIENT', 'RESTAURANT']
        },
        loadChildren: './restaurantPanel/orders/order-pickup/order-pickup.module#OrderPickupPageModule'
    },
    {
        path: 'restaurantPanel/labels',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['RESTAURANT']
        },
        loadChildren: './restaurantPanel/labels/labels.module#LabelsPageModule'
    },
    {
        path: 'contactless-payment',
        loadChildren: './menu/_components/contactless-payment/contactless-payment.module#ContactlessPaymentPageModule'
    },
    {path: 'my-restaurants', loadChildren: './restaurants/my-restaurants/my-restaurants.module#MyRestaurantsPageModule'},
    {path: 'my-restaurants/:paymentConfirm', loadChildren: './restaurants/my-restaurants/my-restaurants.module#MyRestaurantsPageModule'},
    {path: 'find-restaurant', loadChildren: './restaurants/find-restaurant/find-restaurant.module#FindRestaurantPageModule'},
    {path: 'delivery-address', loadChildren: './_modal/delivery-address/delivery-address.module#DeliveryAddressPageModule'},
    {path: 'restaurant-info', loadChildren: './_modal/restaurant-info/restaurant-info.module#RestaurantInfoPageModule'},
    {path: 'bank-list', loadChildren: './_modal/bank-list/bank-list.module#BankListPageModule'},
    {path: 'public-orders-simple', loadChildren: './orders/public-orders-simple/public-orders-simple.module#PublicOrdersSimplePageModule'},
    {
        path: 'order-details',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['RESTAURANT']
        },
        loadChildren: './orders/public-orders-simple/order-details/order-details.module#OrderDetailsPageModule'
    },
    {path: 'update-card-number', loadChildren: './profile/update-card-number/update-card-number.module#UpdateCardNumberPageModule'},
    {
        path: 'restaurantPanel/categories',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['RESTAURANT']
        },
        loadChildren: './restaurantPanel/categories/categories.module#CategoriesPageModule'
    },
    {
        path: 'restaurantPanel/products',
        canActivate: [GuardService],
        data: {
            permissions: ['WORKER', 'CHIEF', 'MANAGER', 'ADMIN'],
            companyTypes: ['RESTAURANT']
        },
        loadChildren: './restaurantPanel/products/products.module#ProductsPageModule'
    }


];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
