import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {RateOrderPage} from './rate-order.page';
import {TranslateModule} from '@ngx-translate/core';
import {RatingModule} from 'primeng/rating';

const routes: Routes = [
    {
        path: '',
        component: RateOrderPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        RatingModule,
        TranslateModule,
        RouterModule.forChild(routes)
    ],
    declarations: [RateOrderPage]
})
export class RateOrderPageModule {
}
