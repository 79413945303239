/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type OrderType = 'APP.ORDER' | 'APP.RESERVATION' | 'APP.OVERDUE_RESERVATION';

export const OrderType = {
    ORDER: 'APP.ORDER' as OrderType,
    RESERVATION: 'APP.RESERVATION' as OrderType,
    OVERDUERESERVATION: 'APP.OVERDUE_RESERVATION' as OrderType
};