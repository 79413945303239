/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Dictionary } from './dictionary';
import { PhotoFile } from './photoFile';
import { Translation } from './translation';

export interface MenuProductSimple { 
    id?: number;
    name?: string;
    nameTranslation?: Array<Translation>;
    description?: string;
    descriptionTranslation?: Array<Translation>;
    descriptionShort?: string;
    descriptionShortTranslation?: Array<Translation>;
    nutritions?: string;
    nutritionsTranslation?: Array<Translation>;
    ingredients?: string;
    ingredientsTranslation?: Array<Translation>;
    photo?: Array<PhotoFile>;
    packagesNumber?: number;
    priceGross?: number;
    priceNet?: number;
    vat?: MenuProductSimple.VatEnum;
    allergens?: Array<Dictionary>;
    badges?: Array<MenuProductSimple.BadgesEnum>;
    kind?: Dictionary;
    categories?: Array<Dictionary>;
    avgRate?: number;
}
export namespace MenuProductSimple {
    export type VatEnum = 0 | 5 | 8 | 23;
    export const VatEnum = {
        NUMBER_0: 0 as VatEnum,
        NUMBER_5: 5 as VatEnum,
        NUMBER_8: 8 as VatEnum,
        NUMBER_23: 23 as VatEnum
    };
    export type BadgesEnum = 'GLUTEN_FREE' | 'BESTSELLER' | 'PROMOTION' | 'NOVELTY' | 'VEGETARIAN';
    export const BadgesEnum = {
        GLUTENFREE: 'GLUTEN_FREE' as BadgesEnum,
        BESTSELLER: 'BESTSELLER' as BadgesEnum,
        PROMOTION: 'PROMOTION' as BadgesEnum,
        NOVELTY: 'NOVELTY' as BadgesEnum,
        VEGETARIAN: 'VEGETARIAN' as BadgesEnum
    };
}