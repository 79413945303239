import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../_app/app.service';
import {App, User, UserProfileService} from '../../../api';
import {Observable, Observer} from 'rxjs';
import {AuthService} from '../../_auth/auth.service';
import {Input} from '@angular/core';
import {tap, map, skip, takeUntil, take} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Preferences} from '../../_interfaces/preferences';
import {Router} from '@angular/router';
import {NavController, Platform} from '@ionic/angular';
import {RestaurantService} from '../../_restaurant/restaurant.service';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

    public user$: Observable<User>;
    private user: User;
    public app$: Observable<App>;
    public app: App;
    public preferences$: Observable<Preferences>;
    private preferences: Preferences;

    private readonly destroy$: Subject<void> = new Subject();
    public currentRoute: string;

    constructor(private restaurantService: RestaurantService,
                public platform: Platform,
                public navCtrl: NavController,
                private userProfile: UserProfileService,
                private router: Router,
                public authService: AuthService,
                public  appService: AppService) {

        this.user$ = this.authService.currentUser.pipe(tap(user => this.user = user));
        this.app$ = this.appService.currentApp.pipe(tap(app => this.app = app));
        this.preferences$ = this.appService.currentPreferences.pipe(tap(preferences => this.preferences = preferences));
        this.currentRoute = this.router.url;
    }


    ngOnInit(): void {


    }


    async displayRestaurants(event) {

        // const copyPreferences = JSON.parse(JSON.stringify(this.preferences));
        // copyPreferences.shiftId = event;
        // copyPreferences.restaurantId = null;
        // this.appService.setPreferences(copyPreferences);

        await  this.restaurantService.setRestaurants(this.user.id, event, true).then(out => {
            this.navCtrl.navigateRoot(['restaurants']);

        });


    }

    displayCalendar(event) {

        const copyPreferences = JSON.parse(JSON.stringify(this.preferences));
        copyPreferences.restaurantId = event;
        this.appService.setPreferences(copyPreferences);
        this.navCtrl.navigateRoot(['calendar']);
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }


}


