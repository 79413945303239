import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ToastService} from '../../_notification/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {AppService} from '../../_app/app.service';
import {ModalController, NavController, Platform} from '@ionic/angular';
import {OrderConditions, OrderPickupPoint, PickupPoint, PublicOrdersService, UserAuthService} from '../../../api';

import {Geolocation} from '@ionic-native/geolocation/ngx';
import {PoStorage} from '../../_interfaces/poStorage';
import {PoService} from '../../_poService/po.service';
import {takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Preferences} from '../../_interfaces/preferences';
import {SingleMenuService} from '../../menu/_service/single-menu.service';
import {CartService} from '../../_cart/cart.service';
import {GoogleMapsService} from '../../_googleMaps/google-maps.service';
import {DateAdapter} from '@angular/material';


declare var google: any;

@Component({
    selector: 'app-delivery-address',
    templateUrl: './delivery-address.page.html',
    styleUrls: ['./delivery-address.page.scss'],
})
export class DeliveryAddressPage implements OnInit, OnDestroy {


    @Input() pickupPointId: number;
    @Input() edit: boolean;

    private GoogleAutocomplete: any;
    private GoogleGeoCode: any;


    public autocomplete: { street: string; apartmentNumber: string };
    public autocompleteItems: any[];
    public loading: boolean = false;
    public searchError: string;
    public poStorage: PoStorage;
    public preferences: Preferences;
    private readonly destroy$: Subject<void> = new Subject();
    public segment: string;
    public isSearchBar: boolean;
    public privatePickups: OrderPickupPoint[];
    public pickupPoints: OrderPickupPoint[];
    public selectedPickupPoint: PickupPoint;
    public selectedOrderConditions: OrderConditions;
    public selectedDeliveryAddress: boolean;
    public saveBtn: string;
    public address: any;

    public minDate: Date;
    public maxDate: Date;
    public isDateMenu: boolean;
    public dateMenu: Date;
    public chooseDate: boolean;

    constructor(private appService: AppService,
                public translate: TranslateService,
                private userAuth: UserAuthService,
                private navCtrl: NavController,
                private poService: PoService,
                public platform: Platform,
                private publicOrdersService: PublicOrdersService,
                private toast: ToastService,
                private googleMapsService: GoogleMapsService,
                private cartService: CartService,
                private singleMenuService: SingleMenuService,
                private adapter: DateAdapter<any>,
                private modalController: ModalController,
                public modalCtrl: ModalController,
                private geolocation: Geolocation) {

        this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
        this.GoogleGeoCode = new google.maps.Geocoder();


        this.autocomplete = {street: null, apartmentNumber: null};
        this.autocompleteItems = [];

    }

    ngOnInit() {
        this.adapter.setLocale(this.appService.selectedLang);
        const todayDate = new Date();
        this.minDate = todayDate;
        const maxDate = new Date();
        maxDate.setDate(todayDate.getDate() + 30);
        this.maxDate = maxDate;


        (this.edit) ? this.saveBtn = this.translate.instant('APP.Zapisz-i-zamknij').toUpperCase() : this.saveBtn = this.translate.instant('APP.Rozpocznij-zamawianie').toUpperCase();

        this.appService.currentPoStorage.pipe(tap((poStorage: PoStorage) => {
            this.poStorage = poStorage;


            this.appService.currentPreferences.pipe(tap((preferences: Preferences) => {
                this.preferences = preferences;
                this.dateMenu = new Date(this.preferences.menu.date);

                if (this.poStorage && this.poStorage.restaurants) {
                    const filtered = this.poStorage.restaurants.filter(value => value.id === this.preferences.restaurantId);
                    if (filtered[0] && filtered[0].pickupPoints) {
                        this.pickupPoints = this.filterPickupPoints(filtered[0].pickupPoints);
                        this.privatePickups = this.filterPersonalPickup(filtered[0].pickupPoints);

                    }


                }


            }), takeUntil(this.destroy$)).subscribe(res => {
            });

        }), takeUntil(this.destroy$)).subscribe(res => {
        });

        //TODO  this shit nie laduje jak wchodzisz pierwszy raz bezpordednio z url
        this.loadSettings();


    }

    private loadSettings() {
        if (this.pickupPointId) {

            this.segment = 'PICKUP_POINT';
            const point = this.pickupPoints.filter(item => {
                return item.id === this.pickupPointId;
            });
            this.onSelectPickupPoint(point[0]);

        } else if (this.poStorage && this.poStorage.deliveryType) {
            this.segment = this.poStorage.deliveryType;
            if (this.poStorage.deliveryType === 'PICKUP_POINT' || this.poStorage.deliveryType === 'PERSONAL_PICKUP') {

                this.onSelectPickupPoint(this.poStorage.pickupPoint);
            } else if (this.poStorage.deliveryType === 'DELIVERY') {

                this.selectedDeliveryAddress = true;
                this.updateConditionsMenuCart(this.poStorage.deliveryAddress.orderConditionsId);
            }
        }

    }

    localizeMe() {

        this.searchError = null;
        this.loading = true;
        const options = {maximumAge: 0, timeout: 5000, enableHighAccuracy: true};

        this.geolocation.getCurrentPosition(options).then((resp) => {
            this.loading = false;
            const latlng = {
                lat: resp.coords.latitude,
                lng: resp.coords.longitude,
            };

            this.GoogleGeoCode.geocode({location: latlng}, (

                (results, status) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            this.setSearchBar(false);
                            this.setDeliveryAddress(results[0]);
                        } else {
                            this.toast.show(this.translate.instant('APP.Niestety-nie-mozemy-ustalic-Twojej-lokalizacji'), null, 2000, 'top');
                        }
                    } else {
                        this.searchError = this.googleMapsService.getPlacesServiceStatus(status);
                    }

                }));


        }).catch((error) => {
            this.loading = false;
            switch (error.code) {
                case 1:
                    this.toast.show(this.translate.instant('APP.Wlacz-uprawnienia-dostepu-do-lokalizacji-w-urzadze'), null, 4000, 'top');
                    break;
                case 2:
                    this.toast.show(this.translate.instant('APP.Niestety-nie-mozemy-ustalic-Twojej-lokalizacji'), null, 2000, 'top');
                    break;
                case 3:
                    this.toast.show(this.translate.instant('APP.Uplynal-czas-ustalania-Twojej-lokalizacji-sprobuj'), null, 2000, 'top');
                    break;
                default:
                    this.toast.show(this.translate.instant('APP.Wystapil-blad-podczas-ustalania-Twojej-lokalizacji'), null, 2000, 'top', 'danger');
            }

        });
    }

    updateSearchResults(searchAddress) {

        this.searchError = null;
        this.loading = true;
        if (searchAddress === '') {
            this.clearAutocomplete();
            return;
        }

        this.GoogleAutocomplete.getPlacePredictions({
                input: searchAddress,
                types: ['address'],
                componentRestrictions: {country: 'pl'}
            },
            (predictions, status) => {

                this.loading = false;
                this.autocompleteItems = [];
                if (status === 'OK') {

                    if (predictions.length) {
                        predictions.forEach((prediction) => {

                            this.autocompleteItems.push(prediction);
                        });

                    } else {
                        this.searchError = this.googleMapsService.getPlacesServiceStatus();
                    }
                } else {
                    this.searchError = this.googleMapsService.getPlacesServiceStatus(status);
                }
            });
    }

    clearAutocomplete() {
        this.loading = false;
        this.searchError = null;
        this.autocompleteItems = []
        this.autocomplete.street = null;
        this.autocomplete.apartmentNumber = null;
        this.address = null;
    }


    eneterSearch(enterAddress) {

        this.searchError = null;
        this.GoogleAutocomplete.getPlacePredictions({
                input: enterAddress,
                types: ['address'],
                componentRestrictions: {country: 'pl'}
            },
            (predictions, status) => {
                this.loading = false;
                if (status === 'OK') {
                    if (predictions[0]) {

                        this.setDeliveryAddress(predictions[0]);
                    } else {
                        this.searchError = this.googleMapsService.getPlacesServiceStatus();
                    }
                } else {
                    this.searchError = this.googleMapsService.getPlacesServiceStatus(status);
                }
            });
    }

    async closeModal() {

        await this.modalController.dismiss();
    }

    setDeliveryAddress(address) {

        this.searchError = null;
        let arr: any = {};
        this.GoogleGeoCode.geocode({
            placeId: address.place_id
        }, (result, status) => {
            if (status === 'OK') {
                if (result[0]) {

                    arr = result[0].address_components
                        .filter(item => item.types[0] === 'locality' || item.types[0] === 'postal_code' || item.types[0] === 'route' || item.types[0] === 'street_number')
                        .map(item => {
                            switch (item.types[0]) {

                                case 'street_number':
                                    return arr = {buildingNumber: item.long_name};
                                    break;
                                case 'route':
                                    return arr = {street: item.long_name};
                                    break;
                                case 'locality':
                                    return arr = {city: item.long_name};
                                    break;
                                case 'postal_code':
                                    return arr = {zipCode: item.long_name};
                                    break;
                            }

                        }).reduce((res, obj) => {
                            return Object.assign(res, obj);
                        }, {});


                    const coord = new google.maps.LatLng(result[0].geometry.location.lat(), result[0].geometry.location.lng());
                    this.googleMapsService.containsLocation(coord, this.preferences.restaurants[0]).then(resultCoord => {

                        arr.deliveryAreaId = resultCoord.id;

                        const condId = (resultCoord.orderConditionsId) ? resultCoord.orderConditionsId : this.preferences.menu.defaultOrderConditions.id;
                        this.address = {
                            street: arr,
                            condId: condId
                        };


                    }).catch(errors => {
                        const msg = (this.preferences.restaurants[0].pickupPoints) ? this.translate.instant('APP.Mozliwy-jest-odbior-osobisty') : null;
                        this.searchError = this.translate.instant('APP.Podany-adres-nie-znajduje-sie-w-obszarze-dostawy') + ' ' + msg;
                        this.address = null;
                    });

                } else {
                    this.toast.show(this.translate.instant('APP.Wystapil-blad-podczas-szukania-adresu-Skontaktuj-s'), null, 2000, 'top');
                    this.address = null;
                }

            } else {
                this.searchError = this.googleMapsService.getPlacesServiceStatus(status);
                this.address = null;
            }
        });


    }


    saveAddress() {
        this.address.street.apartmentNumber = this.autocomplete.apartmentNumber;
        const copyPoStorage = JSON.parse(JSON.stringify(this.poStorage));
        copyPoStorage.deliveryAddress = this.address.street;
        copyPoStorage.deliveryType = 'DELIVERY';
        copyPoStorage.orderConditionsId = this.address.condId;
        copyPoStorage.deliveryAddress.orderConditionsId = this.address.condId;

        this.appService.setPoStorage(copyPoStorage);

        this.updateConditionsMenuCart(this.address.condId);
        this.selectedDeliveryAddress = true;
        this.setSearchBar(false);
    }

    setSearchBar(status: boolean) {
        this.isSearchBar = status;
        if (!this.isSearchBar) {
            this.clearAutocomplete();
        }
    }

    updateConditionsMenuCart(orderConditionsId) {
        // this.selectedOrderConditions = null;

        // TODO taki sam update przy zmianie menu np. szablonowe -> stałe ?????????
        this.publicOrdersService.getOrderConditions(this.preferences.restaurants[0].id, orderConditionsId, this.preferences.menu.date).subscribe(res => {

            this.selectedOrderConditions = res;


        }, error2 => {
            this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
        });

    }

    addNewQRCode() {
        this.closeModal().then(on => {
            this.navCtrl.navigateRoot(['find-restaurant']);
        });

    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();

    }


    filterPickupPoints(pickupPoints: OrderPickupPoint[]) {

        let list = pickupPoints.filter(i => !i.isPersonalPickup);

        // filtruje rowniez po punktach które zostaly "odkryte" przez usera w qrcode

        if (this.poStorage.myPickupPoints && this.poStorage.myPickupPoints.length > 0) {
            list = list.filter(item => this.poStorage.myPickupPoints.some(f => f.pickupPointId === item.id && f.restaurantId === this.preferences.restaurantId));
        } else {
            return null;
        }

        return (list.length > 0) ? list : null;
    }


    filterPersonalPickup(pickupPoints: OrderPickupPoint[]) {

        const list = pickupPoints.filter(i => i.isPersonalPickup);
        return (list.length > 0) ? list : null;
    }

    onSelectPickupPoint(pickupPoint: PickupPoint) {
        this.selectedPickupPoint = pickupPoint;
        const condId = (pickupPoint.orderConditionsId) ? pickupPoint.orderConditionsId : this.preferences.menu.defaultOrderConditions.id;
        this.updateConditionsMenuCart(condId);


        const copyPoStorage = JSON.parse(JSON.stringify(this.poStorage));

        copyPoStorage.pickupPoint = pickupPoint;
        copyPoStorage.deliveryType = (pickupPoint.isPersonalPickup) ? 'PERSONAL_PICKUP' : 'PICKUP_POINT';
        copyPoStorage.orderConditionsId = condId;
        this.appService.setPoStorage(copyPoStorage);

    }


    getMaxOrderHour() {


        const maxHour = new Date(this.preferences.menu.date);
        const arr = this.selectedOrderConditions.maxOrderHour.split(':');
        const timeBefore = this.selectedOrderConditions.timeBeforeOrderPlace.split(':');
        maxHour.setHours(parseInt(arr[0]), parseInt(arr[1]), 0);
        maxHour.setHours(maxHour.getHours() - parseInt(timeBefore[0]), maxHour.getMinutes() - parseInt(timeBefore[1]), 0);
        return maxHour;
    }

    checkMaxOrderHour() {


        const now = new Date();

        const result = now.getTime() < this.getMaxOrderHour().getTime();
        return result;
    }

    segmentChanged(ev: any) {
        if (ev.detail.value === 'DELIVERY') {
            if (!this.poStorage || !this.poStorage.deliveryAddress) {
                this.setSearchBar(true);
            }
        }

        this.selectedOrderConditions = null;
        this.selectedPickupPoint = null;
        this.selectedDeliveryAddress = false;

    }

    public selectDeliveryAddress() {
        this.updateConditionsMenuCart(this.poStorage.deliveryAddress.orderConditionsId);
        this.setSearchBar(false);
        this.selectedDeliveryAddress = true;

        const copyPoStorage = JSON.parse(JSON.stringify(this.poStorage));
        copyPoStorage.deliveryType = 'DELIVERY';
        copyPoStorage.orderConditionsId = this.poStorage.deliveryAddress.orderConditionsId;
        this.appService.setPoStorage(copyPoStorage);

    }

    public cancelSearch() {
        if (!this.poStorage || !this.poStorage.deliveryAddress) {
            this.segment = null;
        }
        this.setSearchBar(false);

    }

    public startOrdering() {

        this.cartService.updateOrderConditions(this.selectedOrderConditions);
        if (this.singleMenuService.singleMenu) {

            this.singleMenuService.updateOrderConditions(this.selectedOrderConditions);
        }
        this.closeModal();

    }

    public viewMenu() {

        this.cartService.updateOrderConditions(this.selectedOrderConditions);
        if (this.singleMenuService.singleMenu) {

            this.singleMenuService.updateOrderConditions(this.selectedOrderConditions, true);
        }
        this.closeModal();
    }

    onStreetSelect(ev) {
        const item = ev.option.value;
        this.autocomplete.street = item.terms[0].value + ' ' + item.terms[1].value + ' ' + item.terms[2].value;
        this.setDeliveryAddress(ev.option.value);

    }

    onDate() {

        if (!this.dateMenu) {
            return false;
        }

        const local = new Date(this.dateMenu);
        local.setMinutes(this.dateMenu.getMinutes() - this.dateMenu.getTimezoneOffset());
        const date = local.toISOString().substring(0, 10);

        const copyPreferences = JSON.parse(JSON.stringify(this.preferences));
        copyPreferences.menu = {
            date: date,
            isPublicMenu: true
        };


        setTimeout(() => {
            this.isDateMenu = true;
        }, 100);

        this.appService.setPreferences(copyPreferences);
        this.singleMenuService.changeMenuDay(true);

    }

}
