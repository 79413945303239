import {Component, OnInit} from '@angular/core';
import {ToastService} from '../../_notification/toast.service';
import {Router} from '@angular/router';
import {AuthService} from '../../_auth/auth.service';
import {MenuController, NavController, Platform} from '@ionic/angular';
import {BehaviorSubject, Observable} from 'rxjs';
import {CartService} from '../../_cart/cart.service';
import {PopoverController} from '@ionic/angular';
import {PopoverComponent} from '../../menu/_components/popover/popover.component';
import {ActionSheetController} from '@ionic/angular';
import {Cart} from '../../_interfaces/cart';
import {tap} from 'rxjs/operators';
import {User} from '../../../api';
import {ProgressBarService} from '../../_progressbar/progress-bar.service';
import {AppService} from '../../_app/app.service';
import {SingleMenuService} from '../../menu/_service/single-menu.service';
import {DatePipe} from '@angular/common';
import {Preferences} from '../../_interfaces/preferences';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

    cartItemCount: BehaviorSubject<number>;
    unsavedCarts: BehaviorSubject<boolean>;
    progressBarStatus: BehaviorSubject<boolean>;
    cart: Cart[] = [];

    // public user$: Observable<User>;
    // private user: User;
    public preferences$: Observable<Preferences>;
    private preferences: Preferences;


    constructor(public platform: Platform,
                private progessBar: ProgressBarService,
                public navCtrl: NavController,
                public cartService: CartService,
                public menu: MenuController,
                public singleMenuService: SingleMenuService,
                public toast: ToastService,
                public router: Router,
                public appService: AppService,
                public authService: AuthService) {

        // this.user$ = this.authService.currentUser.pipe(tap(user => this.user = user));
        this.preferences$ = this.appService.currentPreferences.pipe(tap(preferences => this.preferences = preferences));
    }

    ngOnInit() {

        this.cartItemCount = this.cartService.getCartItemCount();
        this.unsavedCarts = this.cartService.getUnsavedCarts();
        this.progressBarStatus = this.progessBar.getActive();
    }


    displayCart() {
        this.cartService.displayCart();
    }

    logout() {

        this.authService.logout();
    }

    getDateEarlier(menuDate: string, daysEarlier: number) {
        return new Date(new Date().setDate(new Date(menuDate).getDate() - daysEarlier));
    }

}

