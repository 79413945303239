/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type DictionaryType = 'PRODUCT_KIND' | 'PRODUCT_ALLERGEN' | 'PRODUCT_LABEL';

export const DictionaryType = {
    KIND: 'PRODUCT_KIND' as DictionaryType,
    ALLERGEN: 'PRODUCT_ALLERGEN' as DictionaryType,
    LABEL: 'PRODUCT_LABEL' as DictionaryType
};