import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';


@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(public toastController: ToastController) {
    }


    async show(header: string, message: string = null, duration: number = 2000, position: any = 'top', color: string = 'tertiary') {

        let toast = await this.toastController.create({
            message: message,
            duration: duration,
            header: header,
            cssClass: 'toastTheme',
            position: position,
            color: color,
            showCloseButton: true,
            closeButtonText: 'X',
            mode: 'md'
        });
        return await toast.present();

    }




}
