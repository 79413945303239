import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Restaurant, UserProfileService} from '../../api';
import {switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Preferences} from '../_interfaces/preferences';
import {AppService} from '../_app/app.service';
import {NavController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class RestaurantService {

    public restaurants: Observable<Restaurant[]>;
    public restaurantsState = new ReplaySubject<any>(1);
    public preferences: Preferences;
    private readonly destroy$: Subject<void> = new Subject();

    constructor(private userProfile: UserProfileService,
                private navCtrl: NavController,
                private appService: AppService) {
        this.restaurants = this.restaurantsState.asObservable();
        this.appService.currentPreferences.pipe(takeUntil(this.destroy$)).subscribe(preferences => {
            this.preferences = preferences;
        });

    }


    async setRestaurants(userId, shiftId, resetetRestaurantId?: boolean) {

        await this.userProfile.getUserRestaurants(userId, shiftId).subscribe(res => {
            this.restaurantsState.next(res);

            const copyPreferences = JSON.parse(JSON.stringify(this.preferences));
            copyPreferences.restaurants = res;
            copyPreferences.shiftId = shiftId;
            if (resetetRestaurantId) {
                copyPreferences.restaurantId = null;
            }
            this.appService.setPreferences(copyPreferences);

        });

    }

    async setRestaurantAndOpenMenu(userId, shiftId, restaurantId: number, menuDate: string) {

        await this.userProfile.getUserRestaurants(userId, shiftId).subscribe(res => {
            this.restaurantsState.next(res);

            const copyPreferences = JSON.parse(JSON.stringify(this.preferences));
            copyPreferences.restaurants = res;
            copyPreferences.shiftId = shiftId;
            copyPreferences.restaurantId = restaurantId;
            copyPreferences.menu = {
                date: menuDate
            };


            this.appService.setPreferences(copyPreferences);
            this.navCtrl.navigateForward('menu');
        });

    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();

    }

}
