/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PaymentReturnResponse = 'SUCCESS.REFUNDED_AND_DELETED' | 'SUCCESS.REFUNDED_AND_UPDATED' | 'SUCCESS.REFUNDED_AND_UPDATE_ERROR' | 'SUCCESS.REFUNDED_AND_PAYMENT_PENDING';

export const PaymentReturnResponse = {
    DELETED: 'SUCCESS.REFUNDED_AND_DELETED' as PaymentReturnResponse,
    UPDATED: 'SUCCESS.REFUNDED_AND_UPDATED' as PaymentReturnResponse,
    UPDATEERROR: 'SUCCESS.REFUNDED_AND_UPDATE_ERROR' as PaymentReturnResponse,
    PAYMENTPENDING: 'SUCCESS.REFUNDED_AND_PAYMENT_PENDING' as PaymentReturnResponse
};