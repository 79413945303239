/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Menu } from '../model/menu';
import { MenuCategory } from '../model/menuCategory';
import { MenuProduct } from '../model/menuProduct';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MenuService {

    protected basePath = 'https://virtserver.swaggerhub.com/e-Lunch.pl/kiosk/1.0.1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * get menu
     * 
     * @param userId 
     * @param shiftId 
     * @param restaurantId 
     * @param date 
     * @param language 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMenu(userId: number, shiftId: number, restaurantId: number, date: string, language: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Menu>>;
    public getMenu(userId: number, shiftId: number, restaurantId: number, date: string, language: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Menu>>>;
    public getMenu(userId: number, shiftId: number, restaurantId: number, date: string, language: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Menu>>>;
    public getMenu(userId: number, shiftId: number, restaurantId: number, date: string, language: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getMenu.');
        }

        if (shiftId === null || shiftId === undefined) {
            throw new Error('Required parameter shiftId was null or undefined when calling getMenu.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getMenu.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getMenu.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getMenu.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (shiftId !== undefined && shiftId !== null) {
            queryParameters = queryParameters.set('shiftId', <any>shiftId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Menu>>('get',`${this.basePath}/user/menu`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get menu category
     * 
     * @param userId 
     * @param shiftId 
     * @param restaurantId 
     * @param date 
     * @param categoryId ID of category
     * @param language 
     * @param menuId ID of menu (null for lunch menu)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMenuCategory(userId: number, shiftId: number, restaurantId: number, date: string, categoryId: number, language: string, menuId?: number, observe?: 'body', reportProgress?: boolean): Observable<MenuCategory>;
    public getMenuCategory(userId: number, shiftId: number, restaurantId: number, date: string, categoryId: number, language: string, menuId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MenuCategory>>;
    public getMenuCategory(userId: number, shiftId: number, restaurantId: number, date: string, categoryId: number, language: string, menuId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MenuCategory>>;
    public getMenuCategory(userId: number, shiftId: number, restaurantId: number, date: string, categoryId: number, language: string, menuId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getMenuCategory.');
        }

        if (shiftId === null || shiftId === undefined) {
            throw new Error('Required parameter shiftId was null or undefined when calling getMenuCategory.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getMenuCategory.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getMenuCategory.');
        }

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling getMenuCategory.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getMenuCategory.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (shiftId !== undefined && shiftId !== null) {
            queryParameters = queryParameters.set('shiftId', <any>shiftId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (menuId !== undefined && menuId !== null) {
            queryParameters = queryParameters.set('menuId', <any>menuId);
        }
        if (categoryId !== undefined && categoryId !== null) {
            queryParameters = queryParameters.set('categoryId', <any>categoryId);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MenuCategory>('get',`${this.basePath}/user/menu/category`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get menu product
     * 
     * @param userId 
     * @param shiftId 
     * @param restaurantId 
     * @param date 
     * @param categoryId ID of category
     * @param productId ID of product
     * @param language 
     * @param menuId ID of menu (null for lunch menu)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMenuProduct(userId: number, shiftId: number, restaurantId: number, date: string, categoryId: number, productId: number, language: string, menuId?: number, observe?: 'body', reportProgress?: boolean): Observable<MenuProduct>;
    public getMenuProduct(userId: number, shiftId: number, restaurantId: number, date: string, categoryId: number, productId: number, language: string, menuId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MenuProduct>>;
    public getMenuProduct(userId: number, shiftId: number, restaurantId: number, date: string, categoryId: number, productId: number, language: string, menuId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MenuProduct>>;
    public getMenuProduct(userId: number, shiftId: number, restaurantId: number, date: string, categoryId: number, productId: number, language: string, menuId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getMenuProduct.');
        }

        if (shiftId === null || shiftId === undefined) {
            throw new Error('Required parameter shiftId was null or undefined when calling getMenuProduct.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getMenuProduct.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getMenuProduct.');
        }

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling getMenuProduct.');
        }

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getMenuProduct.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getMenuProduct.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (shiftId !== undefined && shiftId !== null) {
            queryParameters = queryParameters.set('shiftId', <any>shiftId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (menuId !== undefined && menuId !== null) {
            queryParameters = queryParameters.set('menuId', <any>menuId);
        }
        if (categoryId !== undefined && categoryId !== null) {
            queryParameters = queryParameters.set('categoryId', <any>categoryId);
        }
        if (productId !== undefined && productId !== null) {
            queryParameters = queryParameters.set('productId', <any>productId);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MenuProduct>('get',`${this.basePath}/user/menu/product`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get  product
     * 
     * @param userId 
     * @param productId 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProduct(userId: number, productId: number, language: string, observe?: 'body', reportProgress?: boolean): Observable<MenuProduct>;
    public getProduct(userId: number, productId: number, language: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MenuProduct>>;
    public getProduct(userId: number, productId: number, language: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MenuProduct>>;
    public getProduct(userId: number, productId: number, language: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getProduct.');
        }

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProduct.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getProduct.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (productId !== undefined && productId !== null) {
            queryParameters = queryParameters.set('productId', <any>productId);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MenuProduct>('get',`${this.basePath}/user/product`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get single menu
     * 
     * @param userId 
     * @param shiftId 
     * @param restaurantId 
     * @param date 
     * @param language 
     * @param id ID of menu (null for lunch menu)
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSingleMenu(userId: number, shiftId: number, restaurantId: number, date: string, language: string, id?: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Menu>;
    public getSingleMenu(userId: number, shiftId: number, restaurantId: number, date: string, language: string, id?: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Menu>>;
    public getSingleMenu(userId: number, shiftId: number, restaurantId: number, date: string, language: string, id?: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Menu>>;
    public getSingleMenu(userId: number, shiftId: number, restaurantId: number, date: string, language: string, id?: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getSingleMenu.');
        }

        if (shiftId === null || shiftId === undefined) {
            throw new Error('Required parameter shiftId was null or undefined when calling getSingleMenu.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getSingleMenu.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getSingleMenu.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getSingleMenu.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (shiftId !== undefined && shiftId !== null) {
            queryParameters = queryParameters.set('shiftId', <any>shiftId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Menu>('get',`${this.basePath}/user/singleMenu`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
