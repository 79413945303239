import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    AlertController, LoadingController, MenuController, ModalController, NavController, Platform,
    PopoverController
} from '@ionic/angular';
import {CartService} from '../../../_cart/cart.service';
import {Cart} from '../../../_interfaces/cart';
import {
    MenuProduct, OrderDiscount, OrderPickupPoint, OrderService, PublicOrdersService, Shift, User, UserInvoiceAddress,
    UserProfileService
} from '../../../../api';
import {ToastService} from '../../../_notification/toast.service';
import {Observable, Subject} from 'rxjs';
import {Preferences} from '../../../_interfaces/preferences';
import {take, takeUntil} from 'rxjs/operators';
import {AuthService} from '../../../_auth/auth.service';
import {AppService} from '../../../_app/app.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SingleMenuService} from '../../_service/single-menu.service';
import {TranslateService} from '@ngx-translate/core';
import {ViewChild} from '@angular/core';
import {MatAccordion} from '@angular/material';
import {CurrencyPipe, DatePipe} from '@angular/common';

import {InvoiceAddressPage} from '../../../_modal/invoice-address/invoice-address.page';
import {PoStorage} from '../../../_interfaces/poStorage';
import {GoogleMapsService} from '../../../_googleMaps/google-maps.service';
import {BankListPage} from '../../../_modal/bank-list/bank-list.page';
import * as printJS from 'print-js';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ContactlessPaymentPage} from '../contactless-payment/contactless-payment.page';
import {DeliveryAddressPage} from '../../../_modal/delivery-address/delivery-address.page';


@Component({
    selector: 'app-menu-cart',
    templateUrl: './menu-cart.component.html',
    styleUrls: ['./menu-cart.component.scss'],
})
export class MenuCartComponent implements OnInit, OnDestroy {

    public cart: Cart;
    public carts: Cart[];

    private user: User;
    public user$: Observable<User>;
    public preferences: Preferences;
    public preferences$: Observable<Preferences>;
    public discounts: OrderDiscount = null;
    public toPayGross: number;
    public shift: Shift[];

    public deliveryPrice: number;
    public segment: string;
    public orderDetails: boolean;
    public cartForm: FormGroup;
    public submitAttempt: boolean = false;
    public orderPlaced: boolean = false;
    public isCombination: boolean = false; //potrzebne do sprawdzania przed zapisem zamówienia czy kombinacja została spełniona
    public selectedLng: string;
    private paymentMethodDefault: string;
    public deliveryHoursDefault: string;
    private pickupPointDefault: string;
    public pickupPoints: OrderPickupPoint[];

    private commentDefault: string;
    public invoiceAddress: UserInvoiceAddress;
    public commentOn: boolean;

    private readonly destroy$: Subject<void> = new Subject();

    // publicOrders

    public poStorage: PoStorage;
    private loading: any;
    private _submitButtonName: string;

    @ViewChild(MatAccordion, {static: false}) accordion: MatAccordion;

    constructor(public platform: Platform,
                private translate: TranslateService,
                public navCtrl: NavController,
                private singleMenuService: SingleMenuService,
                private formBuilder: FormBuilder,
                public orderService: OrderService,
                public appService: AppService,
                public loadingController: LoadingController,
                private http: HttpClient,
                private googleMapsService: GoogleMapsService,
                private modalCtrl: ModalController,
                private publicOrdersService: PublicOrdersService,
                public authService: AuthService,
                private userProfile: UserProfileService,
                private currencyPipe: CurrencyPipe,
                public popoverController: PopoverController,
                public toast: ToastService,
                public alertController: AlertController,
                private datePipe: DatePipe,
                private cartService: CartService,
                public menuCtrl: MenuController) {

    }

    ngOnInit() {


    }

    getExpandedPanelHeader() {

        if (this.accordion) {
            if ((this.accordion._headers.find(h => h._isExpanded()))) {

                return true;
            }
        }

        return false;
    }

    getDefaultOption(value, pickupPoints?) {

        if (!value) {
            return null;
        }
        if (value.length > 1) {
            return null;
        } else {
            if (pickupPoints) {
                return value[0].id;
            } else {
                return value[0];
            }
        }
    }

    checkValidators() {

        if (!this.cart.orderConditions.pickupPoints) {
            this.cartForm.get('pickupPoint').clearValidators();
            this.cartForm.get('pickupPoint').updateValueAndValidity();

        }
        if (!this.cart.orderConditions.deliveryHours) {
            this.cartForm.get('deliveryHour').clearValidators();
            this.cartForm.get('deliveryHour').updateValueAndValidity();

        }
        if (!this.cart.orderConditions.paymentMethods) {
            this.cartForm.get('paymentMethod').clearValidators();
            this.cartForm.get('paymentMethod').updateValueAndValidity();

        }

    }

    loadDefaultsOptions() {

        if (this.cart.order && this.cart.order.paymentMethod && this.cart.order.paymentMethod !== 'PAYMENT.ZERO_VALUE') {
            this.paymentMethodDefault = this.cart.order.paymentMethod;

        } else {

            this.paymentMethodDefault = this.getDefaultOption(this.cart.orderConditions.paymentMethods);
        }

        if (this.cart.order && this.cart.order.deliveryHour) {
            this.deliveryHoursDefault = this.cart.order.deliveryHour;
        } else {
            this.deliveryHoursDefault = this.getDefaultOption(this.cart.orderConditions.deliveryHours);
        }

        if (this.cart.order && this.cart.order.pickupPoint) {
            this.pickupPointDefault = this.cart.order.pickupPoint;
        } else {
            this.pickupPointDefault = this.getDefaultOption(this.pickupPoints, true);
            //  this.privatePickupDefault = this.getDefaultOption(this.privatePickups, true);
        }
        if (this.cart.order && this.cart.order.comment) {
            this.commentDefault = this.cart.order.comment;
        } else {
            this.commentDefault = null;
        }
        if (this.cart.order && this.cart.order.invoiceAddress) {
            this.invoiceAddress = this.cart.order.invoiceAddress;
        } else {
            this.invoiceAddress = null;
        }


        this.cartForm = this.formBuilder.group({

            'pickupPoint': [this.pickupPointDefault, Validators.compose([
                (!this.cart.isPublicMenu) ? Validators.required : null
            ])],


            'deliveryHour': [this.deliveryHoursDefault, Validators.compose([
                Validators.required
            ])],
            'paymentMethod': [this.paymentMethodDefault, Validators.compose([
                Validators.required
            ])],
            'comment': [this.commentDefault],
            'isInvoiceNeeded': [(this.cart.order && this.cart.order.invoiceAddress) ? true : false],

            'firstName': [null, Validators.compose([])],
            'lastName': [null, Validators.compose([])],
            'phone': [null, Validators.compose([])],
            'email': [null, Validators.compose([])]
        });


        this.checkValidators();

        if (this.cart.isPublicMenu) {
            this.getDeliveryAddressFromStorage();
            this.checkValidatorsPublic();

        }
    }

    closeMenu() {
        this.menuCtrl.close('menuCart');
        this.menuCtrl.enable(false, 'menuCart');

        this.destroy$.next();
        this.destroy$.complete();

        this.cartService.resetActiveCarts();

    }


    openMenu() {
        this.menuCtrl.open('menuCart');
        this.menuCtrl.enable(true, 'menuCart');
    }


    loadMenu() {

        this.discounts = {
            percentageDiscount: null,
            discountValue: null,
            amountDiscount: null

        };

        this.authService.currentUser.pipe(
            take(1),
            takeUntil(this.destroy$)).subscribe(res => {
            this.user = res;
        });
        this.appService.currentPreferences.pipe(
            takeUntil(this.destroy$)
        ).subscribe(res => {
            this.preferences = res;

        });

        this.preferences$ = this.appService.currentPreferences.pipe();


        this.carts = this.cartService.getCart();
        let cartSet = false;

        for (const c of this.carts) {
            if (this.carts.length > 1) {
                this.getDiscounts(c);
            }
            if (c.isActive) {
                this.cart = c;
                cartSet = true;
                //  break;
            }
        }
        if (!cartSet) {
            this.cart = this.carts[0];
        }

        this.segment = 'order';
        this.submitAttempt = false;
        this.orderPlaced = false;
        this.isCombination = false;
        this.selectedLng = this.appService.getSelectedLngIsoCode();
        if (this.carts.length === 1) {
            this.getDiscounts();
            this.cartService.setActiveCart(this.cart.id);
        }


        this.checkOrderDetails();
        this.loadDefaultsOptions();


        if (!this.preferences.menu.isPublicMenu) {
            this.shift = this.user.shifts.filter(i => i.id === this.preferences.shiftId);
        }


        this.checkDeliveryHours();

        this.setSubmitButtonName();


    }


    checkOrderDetails() {

        return this.orderDetails = true;


    }


    getDiscounts(cart?: Cart, discountAlerts?: boolean) {

        let cartObj = cart;
        if (!cart) {
            cartObj = this.cart;

        }

        const idLunchMenuCategories: Array<number> = [];
        //sprawdzam czy sa rabaty na kombinacje dlatego musze przeslac liste id kategorii

        if (cartObj.orderType && cartObj.isLunchMenu) {
            for (const product of cartObj.products) {
                idLunchMenuCategories.push(product.categoryId);
            }
        }
        let orderId = null;
        // sprawdzam czy dostalem z BasketDetails orderId jesli tak do go podaje (bylo robione juz zamowienie)
        if (cartObj.order && cartObj.order.orderId) {
            orderId = cartObj.order.orderId;
        }

        this.orderService.getOrderDiscountObject(
            cartObj.isLunchMenu,
            this.preferences.restaurantId,
            cartObj.orderConditions.id,
            this.getTotalValueGross(cartObj),
            this.preferences.menu.date,
            (this.preferences.menu.isPublicMenu) ? this.preferences.menu.isPublicMenu : false,
            (this.preferences.menu.isPublicMenu) ? null : this.user.id,
            (this.preferences.menu.isPublicMenu) ? null : orderId,
            (this.preferences.menu.isPublicMenu) ? null : this.preferences.shiftId,
            idLunchMenuCategories
        ).subscribe(
            res => {

                if (discountAlerts) {
                    if (this.discounts.percentageDiscount !== res.percentageDiscount || this.discounts.amountDiscount !== res.amountDiscount) {

                        if (this.discounts.percentageDiscount > res.percentageDiscount || this.discounts.amountDiscount > res.amountDiscount) {
                            let discount = '';
                            if (this.discounts.percentageDiscount) {
                                discount = this.discounts.percentageDiscount * 100 + ' %';
                            } else if (this.discounts.amountDiscount) {
                                discount = this.discounts.amountDiscount + ' zł';
                            }

                            if (this.cart.orderConditions.discountForAmount) {
                                this.toast.show(this.translate.instant('ORDER.Rabat') + ' -' + discount +
                                    ' ' + this.translate.instant('CART.zostal-usuniety') + ' :(', this.translate.instant('CART.minimalna-kwota-zamowienia-aby-otrzymac-rabat-to') + ' <b>' + this.cart.orderConditions.discountForAmount.minOrderValue + ' zł</b>', 2000, 'top', 'danger'
                                );
                            }
                        }
                        if (this.discounts.percentageDiscount < res.percentageDiscount || this.discounts.amountDiscount < res.amountDiscount) {
                            let discount = '';
                            if (res.percentageDiscount) {
                                discount = res.percentageDiscount * 100 + ' %';
                            } else if (res.amountDiscount) {
                                discount = res.amountDiscount + ' zł';
                            }

                            this.toast.show(this.translate.instant('CART.Otrzymales-rabat') + ' :)', '<b>-' + discount + '</b> ' + this.translate.instant('CART.za-minimalna-kwote-zamowienia'), 2000, 'top', 'success');
                        }
                    }
                }

                this.discounts = res;
                this.deliveryPrice = 0;


                //sprawdza czy jest warunek kombinacji do sprawdzania przed zapisaniem zamówienia

                if (cartObj.orderConditions.isCombination && cartObj.orderType) {
                    this.isCombination = res.isCombination;


                } else {
                    this.isCombination = true;

                }

                //kombinacje jesli sa zwracaja ceny nowe kategorii dlatego musze zrobic ich update
                if (cartObj.orderType && cartObj.isLunchMenu) {
                    this.cartService.updateProductsPrices(res.categories, cartObj.id);
                }
                const grossValueDiscounted = this.getTotalValueGross(cartObj) - this.discounts.discountValue;
                const grossValueDiscountedToPay = this.getTotalValueGross(cartObj) - this.discounts.discountValue - this.discounts.funding;


                let deliveryPrice = 0;
                if (cartObj.orderConditions.deliveryPrice) {  // jesli koszt dostawy nie jest nullem
                    deliveryPrice = cartObj.orderConditions.deliveryPrice;
                }

                if (cartObj.orderConditions.minFreeDeliveryValue) {
                    (grossValueDiscounted >= cartObj.orderConditions.minFreeDeliveryValue) ? this.deliveryPrice = 0 : this.deliveryPrice = deliveryPrice;
                } else {
                    this.deliveryPrice = deliveryPrice;
                }


                this.toPayGross = grossValueDiscountedToPay + this.deliveryPrice;
                this.toPayGross = Number(this.toPayGross.toFixed(2));


                for (const c of this.carts) {
                    if (c.id === cartObj.id) {
                        c.valueGrossDiscounted = grossValueDiscounted + this.deliveryPrice;
                        c.defaultDeliveryHour = this.getDefaultOption(c.orderConditions.deliveryHours);
                        break;
                    }
                }


            }, error2 => {
                if (error2.status !== 401 && error2.status !== 403) {
                    this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
                }
            }
        );


    }


    getTotalValueGross(cart?: Cart) {

        if (cart) {
            return cart.products.reduce((i, j) => i + j.priceGross * j.qtyOrdered, 0);
        } else {
            return this.cart.products.reduce((i, j) => i + j.priceGross * j.qtyOrdered, 0);
        }
    }

    getTotalValueGrossDiscounted() {
        return this.getTotalValueGross() - this.discounts.discountValue - this.discounts.funding;
    }

    removeCartItem(product: MenuProduct) {


        if (this.cartService.removeProduct(product, this.cart.id)) {
            this.closeMenu();
            this.deleteOrderSingle();

        } else {
            this.getDiscounts(null, true);
        }
        //czyscimy zaznaczone checkboxy na single menu
        for (const c of this.singleMenuService.getSingleMenu().categories) {

            if (c.id === product.categoryId) {
                for (const [index, p] of c.products.entries()) {
                    if (p.id === product.id) {
                        p.isChecked = false;
                        p.qtyOrdered = null;
                        break;
                    }
                }
            }

            //szukam w podkategorii
            if (c.categories) {
                for (const cSub of c.categories) {

                    if (cSub.id === product.categoryId) {

                        for (const [indexSub, pSub] of cSub.products.entries()) {
                            if (pSub.id === product.id) {
                                pSub.isChecked = false;
                                pSub.qtyOrdered = null;
                                break;
                            }
                        }
                    }
                }
            }
        }

        this.checkboxCategories();

    }

    increaseCartItem(product: MenuProduct) {

        this.cartService.addProduct(product, this.cart);
        this.singleMenuService.updateSingleMenu(product);

        this.getDiscounts(null, true);
    }

    decreaseCartItem(product: MenuProduct) {

        this.cartService.decreaseProduct(product, this.cart.id);
        this.singleMenuService.updateSingleMenu(product);
        this.getDiscounts(null, true);

    }


    deleteOrder() {
        this.orderService.deleteOrder(
            this.cart.isLunchMenu,
            this.user.id,
            this.preferences.shiftId,
            this.preferences.restaurantId,
            this.cart.orderConditions.id,
            this.preferences.menu.date
        ).subscribe(res => {
            this.clearCart();
        }, error2 => {
            if (error2.status !== 401 && error2.status !== 403) {
                this.toast.show(this.translate.instant('CART.Koszyk-nie-zostal-usuniety'), null, 2000, 'top', 'danger');
            }
        });
    }


    deleteOrderSingle() {

        if (this.cart.order && this.cart.order.orderId) {
            this.orderService.deleteOrder(
                this.cart.isLunchMenu,
                this.user.id,
                this.preferences.shiftId,
                this.preferences.restaurantId,
                this.cart.orderConditions.id,
                this.preferences.menu.date
            ).subscribe(res => {
                this.toast.show(this.translate.instant('CART.Koszyk-zostal-usuniety'), null, 2000, 'top');
            }, error2 => {
                if (error2.status !== 401 && error2.status !== 403) {
                    this.toast.show(this.translate.instant('CART.Koszyk-nie-zostal-usuniety'), null, 2000, 'top', 'danger');
                }
            });
        } else {
            this.toast.show(this.translate.instant('CART.Koszyk-zostal-usuniety'), null, 2000, 'top');
        }
    }

    checkMinOrderValue(): boolean {

        if (this.getTotalValueGross() >= this.cart.orderConditions.minOrderValue) {
            return true;
        } else {
            this.toast.show(this.translate.instant('CART.Twoje-zamowienie-nie-moze-byc-zapisane'), this.translate.instant('CART.Minimalna-kwota-zamowienia-to-price-currency', {price: this.cart.orderConditions.minOrderValue}), 2000, 'top', 'danger');
            return false;
        }
    }

    validatePublicOrder() {

        if (!this.cart.orderConditions.paymentMethods) {
            this.toast.show(this.translate.instant('CART.Twoje-zamowienie-nie-moze-zostac-zapisane'), this.translate.instant('ALERT.Nie-posiadasz-skonfigurowanych-form-platnosci') + '.<br>' + this.translate.instant('ALERT.Skontaktuj-sie-z-managerem'), 2000, 'top', 'danger');
            return false;
        }
        if (!this.isCombination) {
            this.toast.show(this.translate.instant('CART.Twoje-zamowienie-nie-moze-zostac-zapisane'), this.translate.instant('CART.Kombinacja-zamowienia-nie-zostala-spelniona'), 2000, 'top', 'danger');
            return false;
        }
        if (!this.checkMinOrderValue()) {
            return false;
        }
        if (this.segment === 'order') {
            this.segment = 'orderDetails';
            this.setSubmitButtonName();
            return;
        }

        if (!this.cartForm.valid && this.orderDetails) {
            this.submitAttempt = true;

            this.segment = 'orderDetails';

            this.toast.show(this.translate.instant('CART.Uzupelnij-wymagane-opcje-zamowienia'), null, 2000, 'top', 'danger');
        } else {


            this.submitAttempt = false;

            this.placeOrderPublic();
        }


    }

    placeOrderPublic() {

        this.presentLoading();
        const body = {
            invoiceAddress: (this.invoiceAddress && this.cartForm.value.isInvoiceNeeded) ? this.invoiceAddress : null,
            products: this.cart.products,
        }

        this.publicOrdersService.placePublicOrder(
            body,
            this.cart.isLunchMenu,
            this.preferences.restaurantId,
            this.cart.orderConditions.id,
            this.preferences.menu.date,
            (/\p{L}/u.test(this.cartForm.value.deliveryHour)) ? this.cart.orderConditions.deliveryHours[1] : this.cartForm.value.deliveryHour,
            (/\p{L}/u.test(this.cartForm.value.deliveryHour)) ? true : false,
            this.deliveryPrice,
            this.cartForm.value.paymentMethod,
            this.appService.selectedLang,
            this.cartForm.value.firstName,
            this.cartForm.value.lastName,
            this.cartForm.value.phone,
            this.cartForm.value.email,
            (this.poStorage.deliveryType !== 'DELIVERY') ? this.poStorage.pickupPoint.id : null,
            this.cartForm.value.comment,
            (this.poStorage.deliveryType === 'DELIVERY') ? this.poStorage.deliveryAddress.deliveryAreaId : null,
            (this.poStorage.deliveryType === 'DELIVERY') ? this.poStorage.deliveryAddress.street : null,
            (this.poStorage.deliveryType === 'DELIVERY') ? this.poStorage.deliveryAddress.buildingNumber : null,
            (this.poStorage.deliveryType === 'DELIVERY' && this.poStorage.deliveryAddress.apartmentNumber) ? this.poStorage.deliveryAddress.apartmentNumber : null,
            (this.poStorage.deliveryType === 'DELIVERY') ? this.poStorage.deliveryAddress.city : null,
            (this.poStorage.deliveryType === 'DELIVERY') ? this.poStorage.deliveryAddress.zipCode : null,
            null // user id opcja
        )
            .subscribe(res => {

                    this.orderPlaced = true;

                    this.closeMenu();
                    this.cartService.setSavedCart(this.cart.id, this.cartForm.value);
                    if (this.cartForm.value.paymentMethod === 'PAYMENT.P24' || this.cartForm.value.paymentMethod === 'PAYMENT.P24_DELAYED') {
                        this.bankListWindowModal(this.cartForm.value.email, this.cartForm.value.firstName + ' ' + this.cartForm.value.lastName, res.orderId, this.cart.valueGrossDiscounted, this.cart.isLunchMenu);

                    } else {
                        this.whatNext(this.translate.instant('APP.Otrzymasz-email-z-potwierdzeniem-i-godzina-realiza'), true);

                    }
                    this.clearCart(true);
                    this.loading.dismiss();
                },
                (err) => {
                    if (err.status !== 401 && err.status !== 403) {

                        if (!err.error || err.error === 'ERRORS.GENERAL_FAILURE') {
                            this.toast.show(this.translate.instant('APP.Wystapil-blad-podczas-skladania-zamowienia'), this.translate.instant('CART.Skontaktuj-sie-z-pomoca-techniczna'), 2000, 'top', 'danger');

                        } else {
                            this.toast.show(this.translate.instant('APP.Wystapil-blad-podczas-skladania-zamowienia'), this.translate.instant(err.error), 2000, 'top', 'danger');
                        }
                    }
                    this.loading.dismiss();
                });


    }

    placeOrder() {

        if (!this.cart.orderConditions.paymentMethods) {
            this.toast.show(this.translate.instant('CART.Twoje-zamowienie-nie-moze-zostac-zapisane'), this.translate.instant('ALERT.Nie-posiadasz-skonfigurowanych-form-platnosci') + '.<br>' + this.translate.instant('ALERT.Skontaktuj-sie-z-managerem'), 2000, 'top', 'danger');
            return false;
        }
        if (!this.isCombination) {
            this.toast.show(this.translate.instant('CART.Twoje-zamowienie-nie-moze-zostac-zapisane'), this.translate.instant('CART.Kombinacja-zamowienia-nie-zostala-spelniona'), 2000, 'top', 'danger');
            return false;
        }
        if (!this.checkMinOrderValue()) {
            return false;
        }


        if (this.segment === 'order') {
            this.segment = 'orderDetails';
            this.setSubmitButtonName();
            return;
        }

        let rewritePaymentMethod = false;

        if (this.toPayGross <= 0) {
            // tutaj warunek specjalnie wstawiam cash, poniewaz trzeba z automatu wybrac typ
            // platnosci za usera poniewaz w formularzu nie wyswielta sie pole wyboru.
            // W backend wstawiony jest podmieniony typ na dofinansowanie
            this.cartForm.get('paymentMethod').setValue('PAYMENT.CASH');
            this.cartForm.get('paymentMethod').updateValueAndValidity();
            rewritePaymentMethod = true;
        }


        if (!this.cartForm.valid && this.orderDetails) {

            this.submitAttempt = true;
            this.segment = 'orderDetails';
            this.toast.show(this.translate.instant('CART.Uzupelnij-wymagane-opcje-zamowienia'), null, 2000, 'top', 'danger');
        } else {

            const body = {
                invoiceAddress: (this.invoiceAddress && this.cartForm.value.isInvoiceNeeded) ? this.invoiceAddress : null,
                products: this.cart.products,
            }

            this.presentLoading();
            this.orderService.placeOrder(
                body,
                this.cart.isLunchMenu,
                this.user.id,
                this.preferences.shiftId,
                this.preferences.restaurantId,
                this.cart.orderConditions.id,
                this.preferences.menu.date,
                this.cartForm.value.deliveryHour,
                this.deliveryPrice,
                this.cartForm.value.paymentMethod,
                this.appService.selectedLang,
                this.cartForm.value.pickupPoint,
                this.cartForm.value.comment)
                .subscribe(res => {

                        this.orderPlaced = true;

                        this.segment = 'order';
                        this.setSubmitButtonName();

                        if (rewritePaymentMethod) {

                            // czyszcze formularz i laduje ponownie wartosci domyslne.
                            this.cartForm.get('paymentMethod').setValue('');
                            this.cartForm.get('paymentMethod').updateValueAndValidity();
                            this.loadDefaultsOptions();

                        }
                        this.cartService.setSavedCart(this.cart.id, this.cartForm.value);


                        // this.closeMenu();
                        // this.cartService.resetActiveCarts();
                        this.whatNext(res.alert, false, null, res.orderId, res.closingTime);

                        if (res.labelUrl && !this.appService.isDesktop && !this.platform.is('ios') && !this.platform.is('android')) {
                            //drukuje etykiete jesli jesst dostepny plik etykiety
                            // dodałem req.withCredentials = 'true'; w printJs bo nie przekazuje w nagłówku cookie
                            printJS(environment.host + '/' + res.labelUrl);
                        }


                        this.cart.order.timePlaceOrder = this.datePipe.transform(new Date(), 'yyyy-MM-dd H:mm');
                        this.cart.order.orderId = res.orderId;
                        this.loading.dismiss();

                    },
                    (err) => {
                        if (err.status !== 401 && err.status !== 403 && err.status !== 500) {
                            if (!err.error || err.error === 'ERRORS.GENERAL_FAILURE') {
                                this.toast.show(this.translate.instant('APP.Wystapil-blad-podczas-skladania-zamowienia'), this.translate.instant('CART.Skontaktuj-sie-z-pomoca-techniczna'), 2000, 'top', 'danger');

                            } else {
                                this.toast.show(this.translate.instant('APP.Wystapil-blad-podczas-skladania-zamowienia'), this.translate.instant(err.error), 2000, 'top', 'danger');

                            }
                        } else if (err.status === 500) {
                            this.toast.show(this.translate.instant('APP.Wystapil-blad-podczas-skladania-zamowienia'), '(Error 500) ' + this.translate.instant('CART.Skontaktuj-sie-z-pomoca-techniczna'), 2000, 'top', 'danger');

                        }
                        this.loading.dismiss();
                    });


        }

    }

    setUnsavedCart() {

        this.cartService.setUnsavedCart(this.cart.id);
    }

    async whatNext(alertText?: string, isPublic?: boolean, isPaymentCancelled?: boolean, orderId?: number, closingTime?: Date) {

        const buttons = [];
        let isDisabledBtns = false;

        if (!isPublic) {
            if (this.toPayGross > 0 && (this.cartForm.value.paymentMethod === 'PAYMENT.P24_DELAYED' || this.cartForm.value.paymentMethod === 'PAYMENT.P24') && (this.platform.is('ios') || this.platform.is('android') || this.appService.isDesktop)) {
                const payBtn = {
                    text: this.translate.instant('PAYMENTS.Zaplac-teraz').toUpperCase(),
                    cssClass: 'alertBtnSuccess',
                    handler: () => {
                        this.closeMenu();

                        this.navCtrl.navigateRoot('payments/' + this.preferences.restaurantId);

                    }
                };
                isDisabledBtns = true;
                buttons.push(payBtn);
            }


            if (this.toPayGross > 0 && (this.cartForm.value.paymentMethod === 'PAYMENT.CONTACTLESS') && !this.appService.isDesktop) {
                const payBtn = {
                    text: this.translate.instant('PAYMENTS.Zaplac-teraz').toUpperCase(),
                    cssClass: 'alertBtnSuccess',
                    handler: () => {
                        this.closeMenu();
                        this.alertController.dismiss();
                        this.contactLessPaymentModal(orderId);
                    }
                };
                isDisabledBtns = true;
                buttons.push(payBtn);
            }
        }

        if (!isDisabledBtns) {
            const createBtn = {
                text: this.translate.instant('CART.UTWORZ-KOLEJNE'),
                cssClass: 'alertBtnSuccess',
                handler: () => {
                    this.closeMenu();
                    if (!isPublic) {
                        this.navCtrl.navigateBack('calendar');
                    }
                }
            };

            buttons.push(createBtn);
        }

        if (this.cartService.getUnsavedCarts().value) {
            const unsavedBtn = {
                text: this.translate.instant('CART.Zapisz-pozostale-koszyki').toUpperCase(),
                cssClass: 'alertBtnSmallDanger',
                handler: () => {

                    this.cartService.resetActiveCarts();
                    this.displayCart();
                }
            };
            buttons.push(unsavedBtn);
        }

        if (!isPublic) {


            const logoutBtn = {
                text: this.translate.instant('APP.Wyloguj-sie').toUpperCase(),
                cssClass: 'alertBtnGrey',
                handler: () => {
                    this.closeMenu();
                    this.authService.logout();
                }
            };
            buttons.push(logoutBtn);

            const showOrdersBtn = {
                text: this.translate.instant('CART.Pokaz-moje-zamowienie').toUpperCase(),
                cssClass: 'alertBtnInfo',

                handler: () => {
                    this.alertController.dismiss();


                    this.orderService.getBasketDetails(
                        this.cart.isLunchMenu,
                        this.user.id,
                        this.preferences.shiftId,
                        this.preferences.restaurantId,
                        this.cart.orderConditions.id,
                        this.preferences.menu.date, 'PL').subscribe(details => {

                            this.cart.order.orderId = details.orderId;

                            this.segment = 'order';
                            this.submitAttempt = false;
                            this.openMenu();


                        }
                        , error2 => {
                            if (error2.status !== 401 && error2.status !== 403 && error2.status !== 404) {
                                this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
                            }
                        }
                    );

                }
            };
            buttons.push(showOrdersBtn);
        } else {
            const closeBtn = {
                text: this.translate.instant('APP.Zamknij').toUpperCase(),
                cssClass: 'alertBtnGrey marginBottom10 ',
                handler: () => {

                }
            };
            buttons.push(closeBtn);
        }

        let closingMsg = this.translate.instant('CART.Twoje-zamowienie-zostalo-przyjete-dziekujemy');

        if (closingTime && this.cartForm.value.paymentMethod === 'PAYMENT.P24' && (this.appService.isDesktop || this.platform.is('ios') || this.platform.is('android'))) {

            const today: any = new Date();
            const closingDate: any = new Date(closingTime);

            // zmieniam czas bez uwzglednienia konwersji czasu strefy.
            closingDate.setMinutes(closingDate.getMinutes() + closingDate.getTimezoneOffset());


            const diffMs = (closingDate - today);
            const minutesToClose = Math.floor(diffMs / 1000 / 60);
            if (minutesToClose > 0 && minutesToClose <= 60) {
                closingMsg = this.translate.instant('ORDER.Oplac-zamowienie-w-ciagu-minutes-minut-aby-je-otrz', {minutes: minutesToClose});
            }

        }


        const alert = await this.alertController.create({
            header: (isPaymentCancelled) ? this.translate.instant('CART.Platnosc-anulowana') : closingMsg,
            message: (alertText) ? alertText : this.translate.instant('CART.Co-chcesz-teraz-zrobic'),
            cssClass: 'customAlert customAlertCart',
            backdropDismiss: false,
            buttons: buttons
        });

        await alert.present();

    }

    clearCart(disableAlert?: boolean) {


        this.cartService.clearCart(this.cart.id);

        //czyscimy zaznaczone checkboxy na c
        //TODO WH porownuje id menu z id koszyka this.singleMenuService.getSingleMenu().id === this.cart.id -> this.singleMenuService.getSingleMenu().orderConditions.id === this.cart.orderConditions.id && this.singleMenuService.getSingleMenu().isLunchMenu === this.cart.isLunchMenu

        if (this.singleMenuService.getSingleMenu().orderConditions.id === this.cart.orderConditions.id && this.singleMenuService.getSingleMenu().isLunchMenu === this.cart.isLunchMenu) {
            for (const c of this.singleMenuService.getSingleMenu().categories) {
                if (c.products) {
                    for (const [index, p] of c.products.entries()) {
                        p.isChecked = false;
                        p.qtyOrdered = null;
                    }
                }

                if (c.categories) {
                    for (const cSub of c.categories) {
                        if (cSub.products) {
                            for (const [indexSub, pSub] of cSub.products.entries()) {
                                pSub.isChecked = false;
                                pSub.qtyOrdered = null;

                            }
                        }

                    }
                }

            }
        }
        this.checkboxCategories();
        if (!disableAlert) {
            this.toast.show(this.translate.instant('CART.Koszyk-zostal-usuniety'), null, 2000, 'top');
        }

    }


    async removeCartAlert() {


        const alert = await this.alertController.create({
            header: this.translate.instant('CART.USUN-KOSZYK'),
            message: (this.discounts.funding) ? this.translate.instant('CART.Chcesz-usunac-zamowienie-ktore-posiada-dofinansowa') + '<br><br>' + this.translate.instant('CART.Aby-skorzystac-z-dofinansowania-do-innych-rachunko') : this.translate.instant('CART.Czy-na-pewno-chcesz-usunac-koszyk'),
            cssClass: 'customAlert ' + (this.discounts.funding) ? 'smallFontAlert' : '',
            buttons: [
                {
                    text: this.translate.instant('APP.Anuluj'),
                    role: 'cancel',
                    cssClass: 'alertBtnGrey',
                    handler: (obj) => {

                    }
                }, {
                    text: this.translate.instant('APP.Usun').toUpperCase(),
                    cssClass: 'alertBtnDanger',
                    handler: () => {

                        this.closeMenu();

                        if (this.cart.order && this.cart.order.orderId) {
                            this.deleteOrder();
                        } else {
                            this.clearCart();
                        }

                    }
                }
            ]
        });

        await
            alert.present();

    }

    checkboxCategories() {

        for (const c of this.singleMenuService.getSingleMenu().categories) {
            if (c.products) {
                for (const [index, p] of c.products.entries()) {

                    if (p.isChecked) {
                        c.isChecked = true;
                        break;
                    }
                    else {
                        c.isChecked = false;
                    }

                }
            }

            if (c.categories) {

                for (const cSub of c.categories) {

                    if (cSub.products) {
                        // TODO BUG - gdy usuwam z koszyka jeden z dwoch produktow w subkategorii, wtedy rodzic kategoria się oddznacza
                        for (const [indexSub, pSub] of cSub.products.entries()) {

                            if (pSub.isChecked) {
                                cSub.isChecked = true;
                                c.isChecked = true;
                                break;
                            }
                            else {
                                cSub.isChecked = false;
                                c.isChecked = false;
                            }


                        }

                    }
                }
            }

        }
    }


    displayCart() {

        this.cartService.displayCart();
    }

    segmentChanged(ev: any) {
        this.segment = ev.detail.value;
        this.setSubmitButtonName();
    }

    backToCart() {
        this.segment = 'order';
        this.setSubmitButtonName();
    }


    setActiveCart(cart: Cart) {

        this.backToCart();


        if (cart.orderConditions.pickupPoints) {
            this.pickupPoints = this.filterPickupPoints(cart.orderConditions.pickupPoints);
        }

        this.segment = 'order';
        this.submitAttempt = false;
        this.isCombination = false;
        this.cartService.setActiveCart(cart.id);
        for (const c of this.carts) {
            if (c.id === cart.id) {
                this.cart = c;
                this.checkOrderDetails();
                this.loadDefaultsOptions();
                // if (this.carts.length > 1) {
                this.getDiscounts();
                //  }
                break;
            }
        }
        this.getExpandedPanelHeader();
    }


    ngOnDestroy(): void {

        this.destroy$.next();
        this.destroy$.complete();
    }


    async invoiceNeeded($event) {
        this.setUnsavedCart();
        if ($event.detail.checked) {

            if (this.invoiceAddress) {
                this.cartForm.get('isInvoiceNeeded').setValue(true);
                this.cartForm.get('isInvoiceNeeded').updateValueAndValidity();
            } else {
                this.invoiceAddressWindowModal();
                this.cartForm.get('isInvoiceNeeded').setValue(false);
                this.cartForm.get('isInvoiceNeeded').updateValueAndValidity();
            }

        } else {
            this.cartForm.get('isInvoiceNeeded').setValue(false);
            this.cartForm.get('isInvoiceNeeded').updateValueAndValidity();
        }

    }


    public async invoiceAddressWindowModal() {
        const invoiceAddressWindow = await this.modalCtrl.create({
            component: InvoiceAddressPage,
            animated: true,
            componentProps: {
                userId: (this.user) ? this.user.id : null,
                invoiceAddresUserEdit: (this.invoiceAddress) ? this.invoiceAddress : null
            }

        });
        invoiceAddressWindow.onDidDismiss()
            .then((data) => {
                if (data['data']) {

                    this.setUnsavedCart();
                    this.invoiceAddress = data['data'];
                    this.cartForm.get('isInvoiceNeeded').setValue(true);
                    this.cartForm.get('isInvoiceNeeded').updateValueAndValidity();
                } else if (!data['data'] && !this.invoiceAddress) {

                    this.cartForm.get('isInvoiceNeeded').setValue(false);
                    this.cartForm.get('isInvoiceNeeded').updateValueAndValidity();
                }
            });

        await invoiceAddressWindow.present();

    }


    checkValidatorsPublic(checkDiscounts?) {


        this.cartForm.get('firstName').setValidators(Validators.compose([
            Validators.required
        ]));
        this.cartForm.get('lastName').setValidators(Validators.compose([
            Validators.required
        ]));
        this.cartForm.get('email').setValidators(Validators.compose([
            Validators.required, Validators.pattern(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/), Validators.email
        ]));
        this.cartForm.get('phone').setValidators(Validators.compose([
            Validators.required, Validators.pattern('[- +()0-9]+')
        ]));


        this.cartForm.get('firstName').updateValueAndValidity();
        this.cartForm.get('lastName').updateValueAndValidity();
        this.cartForm.get('email').updateValueAndValidity();
        this.cartForm.get('phone').updateValueAndValidity();


        if (checkDiscounts) {
            // restuje bledy

            this.submitAttempt = false;

            // przeliczam na nowo rabaty i ewentualny kosz dostawy
            this.getDiscounts(null, true);


        }


    }

    getDeliveryAddressFromStorage() {

        this.appService.currentPoStorage.pipe(
            takeUntil(this.destroy$)
        ).subscribe(res => {
            this.poStorage = res;
        });

    }


    updateConditionsMenuCart(orderConditionsId) {

        this.publicOrdersService.getOrderConditions(this.preferences.restaurants[0].id, orderConditionsId, this.preferences.menu.date).subscribe(res => {

            this.cartService.updateOrderConditions(res);
            this.singleMenuService.updateOrderConditions(res);

            // TODO problem gdy zlozysz zamowienie i wracasz do twworzenia nowego to w koszyku brakuje wszystich form dostaw ... lub zwiniesz koszyk i rozwiniesz ponownie accord
            // TODO problem gdy masz dwa punkty odbioru odkryte i wchodzisz z url id punktu to na liscie w koszyku nie zaznacza go.

            if (this.cart.orderConditions.pickupPoints) {
                this.pickupPoints = this.filterPickupPoints(this.cart.orderConditions.pickupPoints);
            }


            // TODO zrobić aby nie kasowalo ustawien w przypadku tego samego adresu
            this.cartForm.get('deliveryHour').setValue('');
            this.cartForm.get('paymentMethod').setValue('');
            this.cartForm.get('deliveryHour').updateValueAndValidity();
            this.cartForm.get('paymentMethod').updateValueAndValidity();

            // sprawdzam pola formularza i przeliczam rabaty oraz sprawdzam godziny
            this.checkValidatorsPublic(true);
            this.checkDeliveryHours();

        }, error2 => {
            this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
        });

    }


    checkDeliveryHours() {
        // TODO jest jakis problem przy dwuch koszykach ta funkcja nie jest wykonana, nie filtruje godzin oraz nie dodaje jak najszybciej.
        // sprawdzam czy jest okreslony czas czasu przez zlozeniem zamowienia na kontretna godzine z dostepnych godzin i filtruje dostepne godziny
        if (this.cart.orderConditions.timeBeforeOrderPlace && this.cart.isPublicMenu) {
            const dateNow = new Date();
            const arr = this.cart.orderConditions.timeBeforeOrderPlace.split(':');
            dateNow.setHours(dateNow.getHours() + parseInt(arr[0]), dateNow.getMinutes() + parseInt(arr[1]));

            // ustawiam format nowej godziny do przefiltrowania tablicy dostepnych godzin
            const time = ('0' + dateNow.getHours()).substr(-2) + ':' + ('0' + dateNow.getMinutes()).substr(-2);
            this.cart.orderConditions.deliveryHours = this.cart.orderConditions.deliveryHours.filter(a => a > time);

            if (this.cart.orderConditions.deliveryHours.length && this.cart.orderConditions.deliveryHours[0] !== this.translate.instant('APP.jak-najszybciej') && this.cart.isPublicMenu) {
                this.cart.orderConditions.deliveryHours.unshift(this.translate.instant('APP.jak-najszybciej'));
            }


        }
        if (this.cart.orderConditions.deliveryHours.length === 0) {
            this.toast.show(this.translate.instant('CART.Przyjmowanie-zamowien-na-dzis-zostalo-juz-zakonczo'), null, 3000, 'top');

        }
    }

    getMaxOrderHour() {

        const maxHour = new Date(this.preferences.menu.date);
        const arr = this.cart.orderConditions.maxOrderHour.split(':');
        const timeBefore = this.cart.orderConditions.timeBeforeOrderPlace.split(':');
        maxHour.setHours(parseInt(arr[0]), parseInt(arr[1]), 0);
        maxHour.setHours(maxHour.getHours() - parseInt(timeBefore[0]), maxHour.getMinutes() - parseInt(timeBefore[1]), 0);
        return maxHour;
    }

    checkMaxOrderHour() {

        const now = new Date();

        const result = now.getTime() < this.getMaxOrderHour().getTime();
        return result;
    }


    onPickupPointChange(pickupPointId) {

        this.setUnsavedCart();

        if (this.cart.isPublicMenu && pickupPointId) {
            this.checkPickupPoint(pickupPointId);
        }

    }

    checkPickupPoint(pickupPointId) {
        // TODO zrobic tez jak jest jeden pickup z automatu niech to laduje., oraz po powrocie z gdy wybieroe pickuppoin -> przejde na dostawe i wróce, wtedy mam zaznaczony pickupoint do przeladowania
        const pickupPoint = this.preferences.restaurants[0].pickupPoints.filter(i => i.id === pickupPointId);
        const condId = (pickupPoint[0].orderConditionsId) ? pickupPoint[0].orderConditionsId : this.preferences.menu.defaultOrderConditions.id;
        this.updateConditionsMenuCart(condId);


    }


    get submitButtonName(): string {
        return this._submitButtonName;
    }

    setSubmitButtonName() {


        if (this.segment === 'order') {
            this._submitButtonName = this.translate.instant('CART.Do-kasy');
        } else {
            (this.toPayGross > 0) ? this._submitButtonName = `${this.translate.instant('APP.Zamawiam-i-place')} (${this.currencyPipe.transform(this.toPayGross, 'PLN', 'code', null, 'pl-PL')})` : this._submitButtonName = this.translate.instant('APP.Zamawiam');
        }

    }


    public async bankListWindowModal(email: string, firstSecondName: string, orderId: number, toPayValue: number, isLunchMenu: boolean) {
        const bankListWindow = await this.modalCtrl.create({
            component: BankListPage,
            animated: true,
            backdropDismiss: false,
            componentProps: {
                email: email,
                firstSecondName: firstSecondName,
                orderId: orderId,
                toPayValue: toPayValue,
                isLunchMenu: isLunchMenu,
                restaurantId: this.preferences.restaurantId,
                lang: this.appService.selectedLang,
            }

        });
        bankListWindow.onDidDismiss()
            .then((data) => {
                this.whatNext(data['data'] ? this.translate.instant('APP.W-chwili-gdy-zaksiegujemy-Twoja-platnosc-online-ot') : this.translate.instant('APP.Platnosc-online-zostala-anulowana-Twoje-zamowienie'), true, (data['data']) ? false : true);
            });

        await bankListWindow.present();

    }

    onEmailChange() {
        // usuwam spacje z maila przed walidacja
        if (this.cartForm.value.email) {
            this.cartForm.get('email').setValue(this.cartForm.value.email.trim());
            this.cartForm.get('email').updateValueAndValidity();
        }
    }


    filterPersonalPickup(pickupPoints: OrderPickupPoint[]) {

        const list = pickupPoints.filter(i => i.isPersonalPickup);
        return (list.length > 0) ? list : null;
    }

    filterPickupPoints(pickupPoints: OrderPickupPoint[]) {

        let list = pickupPoints.filter(i => !i.isPersonalPickup);

        // filtruje rowniez po punktach które zostaly "odkryte" przez usera w qrcode
        if (this.cart.isPublicMenu) {
            if (this.poStorage.myPickupPoints && this.poStorage.myPickupPoints.length > 0) {
                list = list.filter(item => this.poStorage.myPickupPoints.some(f => f.pickupPointId === item.id && f.restaurantId === this.preferences.restaurantId));
            } else {
                return null;
            }
        }

        return (list.length > 0) ? list : null;
    }

    public async contactLessPaymentModal(orderId: number) {

        const contactLessPaymentModal = await this.modalCtrl.create({
            component: ContactlessPaymentPage,
            animated: true,
            backdropDismiss: false,
            componentProps: {
                userId: this.user.id,
                orderId: orderId,
                restaurantId: this.preferences.restaurantId,
                toPayValue: this.toPayGross,
                token: this.preferences.appToken,
                isLunchMenu: this.cart.isLunchMenu

            }

        });
        contactLessPaymentModal.onDidDismiss()
            .then((data) => {

            });

        await contactLessPaymentModal.present();
        this.closeMenu();

    }

    public async deliveryAddress() {
        const deliveryAddress = await this.modalCtrl.create({
            component: DeliveryAddressPage,
            animated: true,
            backdropDismiss: false,
            cssClass: 'deliveryAddressModal',
            componentProps: {
                edit: true
            }

        });

        await deliveryAddress.present();
    }

    onComment($event) {
        ($event.detail.checked) ? this.commentOn = true : this.commentOn = false;

    }

    async presentLoading() {

        this.loading = await this.loadingController.create({
            //  cssClass: '',
            mode: 'md',
            duration: 10000,
            message: this.translate.instant('APP.Prosze-czekac') + '...'
        });
        await this.loading.present();

    }
}
