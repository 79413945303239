import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuController, Platform} from '@ionic/angular';
import {AuthService} from '../../../_auth/auth.service';
import {ToastService} from '../../../_notification/toast.service';
import {MenuProduct} from '../../../../api';
import {AppService} from '../../../_app/app.service';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {Preferences} from '../../../_interfaces/preferences';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-menu-product',
    templateUrl: './menu-product.component.html',
    styleUrls: ['./menu-product.component.scss'],
})
export class MenuProductComponent implements OnInit, OnDestroy {


    public preferences: Preferences;
    public product: MenuProduct;
    public preferences$: Observable<Preferences>;
    private readonly destroy$: Subject<void> = new Subject();
    public segment: string;


    constructor(public platform: Platform,
                public appService: AppService,
                public sanitizer: DomSanitizer,
                public menuCtrl: MenuController,
                public toast: ToastService,
                public authService: AuthService) {


        this.appService.currentPreferences.pipe(takeUntil(this.destroy$)).subscribe((preferences: Preferences) => {
            if (preferences.menu) {
                this.product = preferences.menu.product;
                this.segment = 'ingredients';
            }

        });


    }

    ngOnInit() {


    }


    closeMenu() {
        this.menuCtrl.close('menuProduct');
        this.menuCtrl.enable(false, 'menuProduct');


    }


    segmentChanged(ev: any) {
        this.segment = ev.detail.value;
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }


}
