export * from './menu.service';
import { MenuService } from './menu.service';
export * from './order.service';
import { OrderService } from './order.service';
export * from './publicOrders.service';
import { PublicOrdersService } from './publicOrders.service';
export * from './restaurant.service';
import { RestaurantService } from './restaurant.service';
export * from './restaurantMenu.service';
import { RestaurantMenuService } from './restaurantMenu.service';
export * from './userAdvertisements.service';
import { UserAdvertisementsService } from './userAdvertisements.service';
export * from './userAuth.service';
import { UserAuthService } from './userAuth.service';
export * from './userCalendar.service';
import { UserCalendarService } from './userCalendar.service';
export * from './userComplaint.service';
import { UserComplaintService } from './userComplaint.service';
export * from './userMessages.service';
import { UserMessagesService } from './userMessages.service';
export * from './userPayment.service';
import { UserPaymentService } from './userPayment.service';
export * from './userProfile.service';
import { UserProfileService } from './userProfile.service';
export * from './userRegister.service';
import { UserRegisterService } from './userRegister.service';
export * from './userReports.service';
import { UserReportsService } from './userReports.service';
export * from './userTools.service';
import { UserToolsService } from './userTools.service';
export * from './welcome.service';
import { WelcomeService } from './welcome.service';
export const APIS = [MenuService, OrderService, PublicOrdersService, RestaurantService, RestaurantMenuService, UserAdvertisementsService, UserAuthService, UserCalendarService, UserComplaintService, UserMessagesService, UserPaymentService, UserProfileService, UserRegisterService, UserReportsService, UserToolsService, WelcomeService];
