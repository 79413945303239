/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PaymentOrderInfo } from './paymentOrderInfo';

/**
 * payment
 */
export interface Payment { 
    id?: number;
    orderInfo?: Array<PaymentOrderInfo>;
    paymentDate?: Date;
    valuePaid?: number;
    restaurantId?: number;
    restaurantName?: string;
    payer?: string;
    status?: Payment.StatusEnum;
    returnQty?: number;
    returnValue?: number;
}
export namespace Payment {
    export type StatusEnum = 'PAID' | 'NOT_DONE';
    export const StatusEnum = {
        PAID: 'PAID' as StatusEnum,
        NOTDONE: 'NOT_DONE' as StatusEnum
    };
}