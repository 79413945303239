/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Client } from '../model/client';
import { OrderConditionsBase } from '../model/orderConditionsBase';
import { TreeNode } from '../model/treeNode';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RestaurantService {

    protected basePath = 'https://virtserver.swaggerhub.com/e-Lunch.pl/kiosk/1.0.1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * get restaurant&#x27;s category tree node list
     * 
     * @param userId 
     * @param language 
     * @param nodeId 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCategoryTree(userId: number, language: string, nodeId?: number, type?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TreeNode>>;
    public getCategoryTree(userId: number, language: string, nodeId?: number, type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TreeNode>>>;
    public getCategoryTree(userId: number, language: string, nodeId?: number, type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TreeNode>>>;
    public getCategoryTree(userId: number, language: string, nodeId?: number, type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getCategoryTree.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getCategoryTree.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (nodeId !== undefined && nodeId !== null) {
            queryParameters = queryParameters.set('nodeId', <any>nodeId);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TreeNode>>('get',`${this.basePath}/user/restaurant/categoryTree`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get restaurant&#x27;s clients
     * 
     * @param userId 
     * @param restaurantId 
     * @param isLunchMenu 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRestaurantClients(userId: number, restaurantId: number, isLunchMenu: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<Client>>;
    public getRestaurantClients(userId: number, restaurantId: number, isLunchMenu: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Client>>>;
    public getRestaurantClients(userId: number, restaurantId: number, isLunchMenu: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Client>>>;
    public getRestaurantClients(userId: number, restaurantId: number, isLunchMenu: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getRestaurantClients.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getRestaurantClients.');
        }

        if (isLunchMenu === null || isLunchMenu === undefined) {
            throw new Error('Required parameter isLunchMenu was null or undefined when calling getRestaurantClients.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (isLunchMenu !== undefined && isLunchMenu !== null) {
            queryParameters = queryParameters.set('isLunchMenu', <any>isLunchMenu);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Client>>('get',`${this.basePath}/user/restaurant/clients`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get restaurant&#x27;s order conditions list
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRestaurantOrderConditions(userId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<OrderConditionsBase>>;
    public getRestaurantOrderConditions(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrderConditionsBase>>>;
    public getRestaurantOrderConditions(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrderConditionsBase>>>;
    public getRestaurantOrderConditions(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getRestaurantOrderConditions.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["JSESSIONID"]) {
            queryParameters = queryParameters.set('JSESSIONID', this.configuration.apiKeys["JSESSIONID"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OrderConditionsBase>>('get',`${this.basePath}/user/restaurant/orderConditions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
