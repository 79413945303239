import {Injectable} from '@angular/core';
import {Restaurant} from '../../api';
import {reject} from 'q';

declare var google: any;

@Injectable({
    providedIn: 'root'
})
export class GoogleMapsService {
    private GoogleGeoCode: any;

    constructor() {

        this.GoogleGeoCode = new google.maps.Geocoder();
    }


    containsLocation(coord, restaurant: Restaurant): Promise<any> {

        return new Promise((resolve, reject) => {
            let result = null;
            if (restaurant.deliveryAreas) {

                restaurant.deliveryAreas.forEach((area) => {
                    const cords = [];
                    area.coordinates.forEach((cord) => {
                        cords.push({lat: cord.latitude, lng: cord.longitude});
                    });
                    const polygon = new google.maps.Polygon({paths: cords});
                    if (google.maps.geometry.poly.containsLocation(coord, polygon)) {
                        return result = area;
                    }
                });
                (result) ? resolve(result) : reject(this.getPlacesServiceStatus('NO_DELIVERY_AVAILABLE'));
            } else {
                reject(this.getPlacesServiceStatus('NO_DELIVERY_AVAILABLE'));
            }
        });

    }


    geoCodeAddress(street?: string, buildingNumber?: string, zipCode?: string, city?: string): Promise<any> {

        return new Promise((resolve, reject) => {
            this.GoogleGeoCode.geocode(
                {
                    address: street + ' ' + buildingNumber + ' ' + zipCode + ' ' + city
                },
                (results, status) => {
                    if (status === 'OK') {
                        resolve(results);
                    } else {
                        //  reject(new Error(this.getPlacesServiceStatus(status)));
                        reject(this.getPlacesServiceStatus(status));
                    }
                }
            );
        });

    }

    checkIfAddressInDeliveryArea(restaurant: Restaurant, street?: string, buildingNumber?: string, zipCode?: string, city?: string): Promise<any> {

        return new Promise((resolve, reject) => {
            this.geoCodeAddress(street, buildingNumber, zipCode, city).then(place => {

                this.containsLocation(place[0].geometry.location, restaurant).then(result => {
                    resolve(result);
                }).catch(errors => {
                    reject(errors);
                });


            }).catch(error => {
                reject(error);
            });
        });
    }


    getPlacesServiceStatus(status?) {

        switch (status) {

            case 'ZERO_RESULTS':
                return 'Hmm... nie możemy znaleźć tego adresu. Spróbujesz ponownie ?';
                break;

            case 'UNKNOWN_ERROR':
                return 'Wystąpił błąd podczas szukania adresu. Skontaktuj sie z pomocą techniczną.';
                break;

            case 'REQUEST_DENIED':
                return 'Wystąpił błąd połączenia z serwerem API. Skontaktuj sie z pomocą techniczną.';
                break;

            case 'REQUEST_DENIED':
                return 'Wystąpił błąd połączenia z serwerem API. Skontaktuj sie z pomocą techniczną.';
                break;

            case 'OVER_QUERY_LIMIT':
                return 'Wystąpił błąd, przekroczone limity API. Skontaktuj sie z pomocą techniczną.';
                break;

            case 'NOT_FOUND':
                return 'Hmm... nie możemy znaleźć tego adresu. Spróbujesz ponownie ?';
                break;

            case 'INVALID_REQUEST':
                return 'Wystąpił błąd zapytania. Skontaktuj się z pomocą techniczną.';
                break;

            case 'ERORR':
                return 'Wystąpił błąd podczas szukania adresu. Skontaktuj sie z pomocą techniczną.';
                break;

            case 'NO_DELIVERY_AVAILABLE':
                return 'Niestety nie dowozimy pod podany adres.';
                break;

            default:
                return 'Hmm... nie możemy znaleźć tego adresu. Spróbujesz ponownie ?';
                break;

        }
    }


}
