import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {HelpPage} from './help/help.page';
import {AppService} from '../_app/app.service';
import {App, User} from '../../api';
import {Observable, Observer, Subject} from 'rxjs';
import {tap, takeUntil, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HelpService {

    private app: App;
    public app$: Observable<App>;
    private readonly destroy$: Subject<void> = new Subject();

    constructor(public appService: AppService, public modalCtrl: ModalController) {

        this.app$ = this.appService.currentApp.pipe(tap(app => this.app = app));

        this.appService.currentApp
            .pipe(take(1), takeUntil(this.destroy$))
            .subscribe((app: App) => {
                this.app = app;
            });


    }


    public async displayHelp() {
        const help = await this.modalCtrl.create({
            component: HelpPage,
            animated: true
        });

        await help.present();
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }


}
