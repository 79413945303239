/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type VAT = 0 | 5 | 8 | 23;

export const VAT = {
    NUMBER_0: 0 as VAT,
    NUMBER_5: 5 as VAT,
    NUMBER_8: 8 as VAT,
    NUMBER_23: 23 as VAT
};