/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ContactlessPaymentStatus = 'CONTACTLESS_PAYMENT_STATUS.OK' | 'CONTACTLESS_PAYMENT_STATUS.DECLINED' | 'CONTACTLESS_PAYMENT_STATUS.PENDING' | 'CONTACTLESS_PAYMENT_STATUS.PROCESSING' | 'CONTACTLESS_PAYMENT_STATUS.ERROR' | 'CONTACTLESS_PAYMENT_STATUS.TIMEOUT' | 'CONTACTLESS_PAYMENT_STATUS.CANCELLED' | 'CONTACTLESS_PAYMENT_STATUS.OTHER';

export const ContactlessPaymentStatus = {
    OK: 'CONTACTLESS_PAYMENT_STATUS.OK' as ContactlessPaymentStatus,
    DECLINED: 'CONTACTLESS_PAYMENT_STATUS.DECLINED' as ContactlessPaymentStatus,
    PENDING: 'CONTACTLESS_PAYMENT_STATUS.PENDING' as ContactlessPaymentStatus,
    PROCESSING: 'CONTACTLESS_PAYMENT_STATUS.PROCESSING' as ContactlessPaymentStatus,
    ERROR: 'CONTACTLESS_PAYMENT_STATUS.ERROR' as ContactlessPaymentStatus,
    TIMEOUT: 'CONTACTLESS_PAYMENT_STATUS.TIMEOUT' as ContactlessPaymentStatus,
    CANCELLED: 'CONTACTLESS_PAYMENT_STATUS.CANCELLED' as ContactlessPaymentStatus,
    OTHER: 'CONTACTLESS_PAYMENT_STATUS.OTHER' as ContactlessPaymentStatus
};