import {Component, OnInit, ViewChild, Inject, LOCALE_ID, OnDestroy} from '@angular/core';
import {CalendarComponent} from 'ionic2-calendar/calendar';
import {DatePipe, formatDate} from '@angular/common';
import {AlertController, MenuController, NavController, Platform} from '@ionic/angular';
import {AuthService} from '../../_auth/auth.service';
import {ToastService} from '../../_notification/toast.service';
import {distinctUntilChanged, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {Day, MenuService, OrderService, User, UserCalendarService} from '../../../api';
import {Observable, Subject, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Preferences} from '../../_interfaces/preferences';
import {AppService} from '../../_app/app.service';
import {OrderMenuService} from '../../_order/order-menu.service';
import {TranslateService} from '@ngx-translate/core';
import {ProgressBarService} from '../../_progressbar/progress-bar.service';
import {EventService} from '../../_app/event.service';


@Component({
    selector: 'app-full-calendar',
    templateUrl: './full-calendar.component.html',
    styleUrls: ['./full-calendar.component.scss'],
})
export class FullCalendarComponent implements OnInit, OnDestroy {

    public user$: Observable<User>;
    private user: User;
    private readonly destroy$: Subject<void> = new Subject();

    public preferences: Preferences;
    public preferences$: Observable<Preferences>;
    public shiftId;
    public restaurantId;
    private subParams: Subscription;
    public selectedLng: string;
    public segment: string = 'week';
    event = {
        title: '',
        desc: '',
        isEnabled: false,
        isOrdered: false,
        isOrderedOther: false,
        startTime: '',
        endTime: '',
        allDay: false,
        ordersToPay: 0
    };
    minDate = new Date().toISOString();

    eventSource = [];
    viewTitle;

    calendar = {
        mode: 'month',

        currentDate: new Date(),
    };
    @ViewChild(CalendarComponent, null) myCal: CalendarComponent;

    private deletedOrder: Subscription;

    constructor(private eventService: EventService,
                private progressBar: ProgressBarService,
                public platform: Platform,
                private translate: TranslateService,
                public navCtrl: NavController,
                public userMenu: MenuService,
                public orderMenuService: OrderMenuService,
                private route: ActivatedRoute,
                public orderService: OrderService,
                public router: Router,
                public appService: AppService,
                public datePipe: DatePipe,
                public userCalendar: UserCalendarService,
                public toast: ToastService,
                public authService: AuthService,
                private alertCtrl: AlertController,
                @Inject(LOCALE_ID) private locale: string) {

        this.progressBar.setActive(true);

        this.subParams = this.route.params.subscribe(params => {
            this.today();
        });


        this.selectedLng = this.appService.selectedLang;

        this.authService.currentUser
            .pipe(
                take(1),
                tap((user: User) => this.user = user),
                switchMap(() => this.preferences$ = this.appService.currentPreferences.pipe(
                    distinctUntilChanged((prev, current) => {

                        return prev.restaurantId === current.restaurantId;
                    }),
                    tap((preferences: Preferences) => this.preferences = preferences), takeUntil(this.destroy$))),

                takeUntil(this.destroy$)
            )
            .subscribe(preferences => {

                this.preferences = preferences;
                this.calendar.currentDate = new Date();

            });

    }


    getLevelClass = (date): string => {
        const current = new Date();
        // wyrównuje czas dnia z kalendarza do obecnego aby przy porównaniu nie było różnicy w czasie. + 1 sekunda

        date.date.setHours(current.getHours(), current.getMinutes(), current.getSeconds() + 1);


        if (!date.events[0].isEnabled || date.date < current) {
            return 'isDisabled';
        } else if (date.events[0].isOrdered && date.events[0].ordersToPay===0) {
            return 'isOrdered';
        }
        else if (date.events[0].isOrdered && date.events[0].ordersToPay > 0) {
            return 'isOrderToPay';
        }
        return '';

    }


    ngOnInit() {

        this.resetEvent();

        // subskrybuje event usuniecia zamowienia aby przeladowac dane
        this.deletedOrder = this.eventService.refreshAfterDeletedOrderSource$.subscribe(data => {
            this.today();
        });




    }

    resetEvent() {
        this.event = {
            title: '',
            desc: '',
            isEnabled: false,
            isOrdered: false,
            isOrderedOther: false,
            startTime: new Date().toISOString(),
            endTime: new Date().toISOString(),
            allDay: false,
            ordersToPay: 0
        };
    }


    onTimeSelected(ev) {


        const current = new Date();


        // wyrównuje czas dnia z kalendarza do obecnego aby przy porównaniu nie było różnicy w czasie. + 1 sekunda

        ev.selectedTime.setHours(current.getHours(), current.getMinutes(), current.getSeconds() + 1);

        // KIOSK & MOBILE & DESKTOP: dzień przeszły, nie ma zamówień
        if (ev.selectedTime < current && !ev.events[0].isOrdered) {

            this.toast.show(this.translate.instant('CALENDAR.Nie-masz-zadnych-zamowien-na-dzien-day', {day: this.datePipe.transform(ev.selectedTime, 'EEEE d MMMM', null, this.appService.getSelectedLngIsoCode())}), this.translate.instant('CALENDAR.Nie-mozesz-rowniez-skladac-zamowien-na-dzien-przes'), 2000, 'top');

        }
        // KIOSK & MOBILE & DESKTOP: nie ma menu
        else if (!ev.events[0].isEnabled) {

            this.toast.show(this.translate.instant('CALENDAR.Brak-menu-na-dzien-day-przepraszamy', {day: this.datePipe.transform(ev.selectedTime, 'EEEE d MMMM', null, this.appService.getSelectedLngIsoCode())}), null, 2000, 'top');
        }
        // KIOSK & MOBILE & DESKTOP: dzień przeszły, są zamówienia
        else if (ev.selectedTime < current && ev.events[0].isOrdered) {

            this.orderService.getOrder(
                this.user.id,
                this.preferences.language,
                null,
                this.preferences.shiftId,
                this.preferences.restaurantId,
                this.datePipe.transform(ev.selectedTime, 'yyyy-MM-dd')
            ).subscribe(res => {
                    if (res) {

                        this.orderMenuService.setOrders(res);
                        this.orderMenuService.displayOrder();
                    }
                }, error2 => {
                    if (error2.status !== 401 && error2.status !== 403) {
                        this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
                    }
                }
            );

        }
        // KIOSK & MOBILE & DESKTOP: dzień PRZYszły, jest menu
        else if (ev.selectedTime >= current && ev.events[0].isEnabled) {


            this.userMenu.getMenu(this.user.id, this.preferences.shiftId, this.preferences.restaurantId, this.datePipe.transform(ev.selectedTime, 'yyyy-MM-dd'), this.preferences.language,this.preferences.appToken).subscribe(res => {


                if (ev.events[0].isOrdered) {


                    this.orderService.getOrder(
                        this.user.id,
                        this.preferences.language,
                        null,
                        this.preferences.shiftId,
                        this.preferences.restaurantId,
                        this.datePipe.transform(ev.selectedTime, 'yyyy-MM-dd')
                    ).subscribe(res => {
                            if (res) {
                                //  let isEditable = false;

                                if (!this.platform.is('ios') && !this.platform.is('android') && !this.appService.isDesktop) {
                                    // KIOSK: są zamówienia i jest menu ale nie moze juz edytować
                                 //   this.toast.show(this.translate.instant('CALENDAR.Nie-mozesz-juz-skladac-zamowien-na-ten-dzien'), this.translate.instant('CALENDAR.Masz-juz-zapisane-zamowienia'), 2000, 'top');
                                } else {
                                    //  MOBILE & DESKTOP: są zamówienia i jest menu i  moze edytować
                                    //    isEditable = true;

                                }

                                this.orderMenuService.setOrders(res);
                                //  this.orderMenuService.setOrders(res, isEditable);
                                this.orderMenuService.displayOrder();
                            }
                        }, error2 => {
                            if (error2.status !== 401 && error2.status !== 403) {
                                this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
                            }
                        }
                    );

                } else {
                    //  KIOSK & MOBILE & DESKTOP: nie ma zamowien i jest menu i  moze edytować , tworzyc
                    const copyPreferences = JSON.parse(JSON.stringify(this.preferences));
                    copyPreferences.menu = {
                        date: this.datePipe.transform(ev.selectedTime, 'yyyy-MM-dd')
                    };
                    this.appService.setPreferences(copyPreferences);
                    this.navCtrl.navigateForward('menu');

                }

            }, error2 => {


                if (ev.events[0].isOrdered) {
                    this.orderService.getOrder(
                        this.user.id,
                        this.preferences.language,
                        null,
                        this.preferences.shiftId,
                        this.preferences.restaurantId,
                        this.datePipe.transform(ev.selectedTime, 'yyyy-MM-dd')
                    ).subscribe(res => {
                            if (res) {
                                // KIOSK & MOBILE & DESKTOP: są zamówienia ale menu nie ma dostępnego

                                this.toast.show(this.translate.instant('CALENDAR.Nie-mozesz-juz-skladac-zamowien-na-ten-dzien'), this.translate.instant('CALENDAR.Masz-juz-zapisane-zamowienia'), 2000, 'top');
                                this.orderMenuService.setOrders(res);
                                this.orderMenuService.displayOrder();
                            }
                        }, error3 => {
                            if (error3.status !== 401 && error3.status !== 403) {
                                this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
                            }
                        }
                    );
                } else {

                    if (error2.status !== 401 && error2.status !== 403) {
                        // KIOSK & MOBILE & DESKTOP: nie ma zamowien i nie moze skladac juz zamowien
                        this.toast.show(this.translate.instant('CALENDAR.Nie-masz-zadnych-zamowien-na-dzien-day', {day: this.datePipe.transform(ev.selectedTime, 'EEEE d MMMM', null, this.appService.getSelectedLngIsoCode())}), this.translate.instant('CALENDAR.Nie-mozesz-juz-skladac-zamowien-na-ten-dzien'), 2000, 'top');
                    }
                }
            });


        }
    }

    onViewTitleChanged(title) {
        this.viewTitle = title;
    }

    next() {

        // https://github.com/twinssbc/Ionic2-Calendar/issues/481
        this.progressBar.setActive(true);
        this.calendar.currentDate.setDate(1); // ustawiamy poczatek na 1 dzien mc (bo moze byc problem z lutym 28dni)
        this.calendar.currentDate.setMonth(this.calendar.currentDate.getMonth() + 1);
        this.calendar.currentDate = new Date(this.calendar.currentDate);

        //  const swiper = document.querySelector('.swiper-container')['swiper'];
        //    swiper.slideNext();
    }

    back() {
        this.progressBar.setActive(true);
        this.calendar.currentDate.setDate(1);
        this.calendar.currentDate.setMonth(this.calendar.currentDate.getMonth() - 1);
        this.calendar.currentDate = new Date(this.calendar.currentDate);

        //   const swiper = document.querySelector('.swiper-container')['swiper'];
        //  swiper.slidePrev();
    }


    today() {
        this.progressBar.setActive(true);
        this.calendar.currentDate = new Date();
    }


    reloadSource(event) {

        const monday = this.datePipe.transform(event.startTime, 'yyyy-MM-dd');
        const diffTime = Math.abs(event.endTime - event.startTime);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


        this.userCalendar.getUserCalendar(this.user.id, monday, this.preferences.shiftId, this.preferences.restaurantId, diffDays).subscribe(days => {

                this.eventSource = [];

                days.forEach(day => {


                    const d = new Date(day.date);
                    // potrzebne ustawienie godziny aby załapał event na kalendarzu
                    d.setHours(6);
                    const loadDay: any = {};
                    loadDay.title = 'menu';

                    loadDay.isEnabled = day.isEnabled;
                    loadDay.isOrdered = day.isOrdered;
                    loadDay.isOrderedOther = day.isOrderedOther;
                    loadDay.ordersToPay = day.ordersToPay;
                    loadDay.allDay = true;
                    loadDay.startTime = d;
                    loadDay.endTime = d;
                    this.eventSource.push(loadDay);


                });


                this.myCal.loadEvents();

                this.resetEvent();
                this.progressBar.setActive(false);

            }, error2 => {
                this.progressBar.setActive(false);
                if (error2.status !== 401 && error2.status !== 403) {
                    this.toast.show(this.translate.instant('ERRORS.Wystapil-blad-podczas-pobierania-danych-z-serwera'), null, 2000, 'top', 'danger');
                }
            }
        );


    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.subParams.unsubscribe();
        this.deletedOrder.unsubscribe();
    }


}
