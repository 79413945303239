/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PaginationSortingParametersFilters } from './paginationSortingParametersFilters';

export interface PaginationSortingParameters { 
    first?: number;
    rows?: number;
    sortField?: string;
    sortOrder?: PaginationSortingParameters.SortOrderEnum;
    filters?: Array<PaginationSortingParametersFilters>;
}
export namespace PaginationSortingParameters {
    export type SortOrderEnum = 'ASC' | 'DESC';
    export const SortOrderEnum = {
        ASC: 'ASC' as SortOrderEnum,
        DESC: 'DESC' as SortOrderEnum
    };
}