import {NgModule} from '@angular/core';
import {IonicRatingModule} from 'ionic4-rating/dist';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MenuProductComponent} from './menu-product/menu-product.component';
import {MenuCartComponent} from './menu-cart/menu-cart.component';
import {MenuOrderComponent} from './menu-order/menu-order.component';
import {MAT_RADIO_DEFAULT_OPTIONS, MatExpansionModule, MatIconModule, MatRadioButton, MatRadioModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { PipesModule } from 'w-ng5';
import {CreateEditProductComponent} from '../../restaurantPanel/products/_components/create-edit-product/create-edit-product.component';
import {FileUploadModule, OverlayPanelModule, SplitButtonModule, TabMenuModule, TreeModule} from 'primeng';

@NgModule({
    imports: [
        MatExpansionModule,
        MatIconModule,
        IonicRatingModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        IonicModule,
        TranslateModule,
        PipesModule,
        MatRadioModule,
        SplitButtonModule,
        TabMenuModule,
        OverlayPanelModule,
        TreeModule,
        FileUploadModule
    ],
    // providers: [{
    //     provide: MAT_RADIO_DEFAULT_OPTIONS,
    //     useValue: { color: 'warn' },
    // }],
    declarations: [MenuProductComponent, MenuCartComponent, MenuOrderComponent, CreateEditProductComponent],
    exports: [MenuProductComponent, MenuCartComponent, MenuOrderComponent,CreateEditProductComponent]
})

export class ComponentsMenuModule {
}


