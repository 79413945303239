import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {AppLanguage, User, UserAuthService} from '../../api';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {MenuController, NavController, Platform} from '@ionic/angular';
import {AppService} from '../_app/app.service';
import {ToastService} from '../_notification/toast.service';
import {ReplaySubject} from 'rxjs';
import {Preferences} from '../_interfaces/preferences';
import {takeUntil, take, map, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CartService} from '../_cart/cart.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';
import {UserIdleService} from 'angular-user-idle';
import {AppVersion} from '@ionic-native/app-version/ngx';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public currentUser: Observable<User>;
    private authState = new BehaviorSubject(null);
    private preferences: Preferences;
    private readonly destroy$: Subject<void> = new Subject();

    private versionNumber: string;

    constructor(private userIdle: UserIdleService,
                private translate: TranslateService,
                private cartSevice: CartService,
                private menuCtrl: MenuController,
                private appService: AppService,
                private appVersion: AppVersion,
                private userAuth: UserAuthService,
                private storage: Storage,
                private navCtrl: NavController,
                private platform: Platform,
                private toast: ToastService) {

        this.currentUser = this.authState.asObservable().pipe(response => response);

        this.platform.ready().then(async () => {
            this.setAuthState();
            this.appService.currentPreferences.pipe(takeUntil(this.destroy$)).subscribe((preferences: Preferences) => {
                this.preferences = preferences;

            });


            if (this.platform.is('cordova')) {
                this.appVersion.getVersionNumber().then(value => {
                    this.versionNumber = value;
                }).catch(err => {
                    this.versionNumber = 'unknown';
                });
            }


        });
    }


    async setAuthState() {
        this.storage.get('USER').then(async (res) => {
            if (res) {
                await this.authState.next(res);
            }
        });
    }

    public setUser(user) {
        this.storage.remove('USER');
        this.storage.set('USER', user);
        this.authState.next(user);
    }


    public login(login: string, password: string, lang: AppLanguage): Observable<User> {

        let version = ' Code: ' + environment.codeVersion;

        if (this.platform.is('cordova')) {
            let system = '';
            if (this.platform.is('android')) {
                system = 'Android';
            } else if (this.platform.is('ios')) {
                system = 'iOS';
            }
            version = system + ' MobileApp v. ' + this.versionNumber + version;
        } else if (this.appService.isDesktop) {
            version = 'Desktop -' + version;
        } else if (!this.appService.isDesktop) {
            version = 'Kiosk -' + version;
        }


        return this.userAuth.userLogin(login, password, lang, version).pipe(
            tap(async (res: User) => {
                if (res && res.token) {

                    await this.storage.set('USER', res);
                    this.authState.next(res);
                }
            })
        );
    }


    public loginByBiometric(UUID: string, lang: AppLanguage): Observable<User> {

        let version = ' Code: ' + environment.codeVersion;

        if (this.platform.is('cordova')) {
            let system = '';
            if (this.platform.is('android')) {
                system = 'Android';
            } else if (this.platform.is('ios')) {
                system = 'iOS';
            }
            version = system + ' MobileApp v. ' + this.versionNumber + version;
        } else if (this.appService.isDesktop) {
            version = 'Desktop -' + version;
        } else if (!this.appService.isDesktop) {
            version = 'Kiosk -' + version;
        }


        return this.userAuth.userLoginByUUID(UUID, lang, version).pipe(
            tap(async (res: User) => {
                if (res && res.token) {
                    await this.storage.set('USER', res);
                    this.authState.next(res);
                }
            })
        );
    }


    public loginByCard(cardNumber: string, token: string,
                       lang: AppLanguage): Observable<User> {

        return this.userAuth.userLoginByCard(cardNumber, lang, token).pipe(
            tap(async (res: User) => {

                if (res && res.token) {

                    // w przypadku gdy ustawione sa id lokalizacji (dla kiosku z tokenem)
                    if (this.preferences.ids && this.preferences.ids !== 'undefined') {
                        const ids = this.preferences.ids.split(',').map(Number);
                        res.shifts = res.shifts.filter(i => ids.includes(i.id));
                    }

                    await this.storage.set('USER', res);
                    this.authState.next(res);
                }
            })
        );
    }


    async logout(unauthorized?: boolean) {

        this.userIdle.stopTimer();
        this.userIdle.stopWatching();
        await this.currentUser.pipe(take(1), takeUntil(this.destroy$)).subscribe((user: User) => {

            this.userAuth.userLogout(user.id).subscribe();
            if (unauthorized) {
                this.toast.show(this.translate.instant('ERRORS.Zostales-wylogowany-Czas-Twojej-sesji-zakonczyl-si'));
            } else {
                this.toast.show(this.translate.instant('ALERT_SUCCESS.Zostales-poprawnie-wylogowany-do-zobaczenia'));
            }
            this.storage.remove('USER').then(o => {
                this.storage.remove('app').then(o2 => {
                    this.storage.remove('preferences').then(o3 => {

                        if (unauthorized) {
                            this.clearUserSession();
                        } else {
                            this.storage.remove('keys').then(o4 => {
                                this.appService.keysState.next(null);
                                this.clearUserSession();
                            });
                        }
                    });
                });

            });
            return;

        });
    }

    clearUserSession() {
        this.authState.next(null);
        this.cartSevice.removeCarts();
        this.cartSevice.setUnsavedCarts(false);


        this.menuCtrl.enable(false, 'mainMenu');
        this.menuCtrl.enable(false, 'menuCart');
        this.menuCtrl.enable(false, 'menuProduct');
        this.menuCtrl.enable(false, 'menuOrder');
        this.menuCtrl.enable(false, 'createEditProduct');

        this.menuCtrl.close('mainMenu');
        this.menuCtrl.close('menuCart');
        this.menuCtrl.close('menuProduct');
        this.menuCtrl.close('menuOrder');
        this.menuCtrl.close('createEditProduct');

        // resetuje jezyk na domyslny oprocz mobile i desktop
        if (!this.platform.is('ios') && !this.platform.is('android') && !this.appService.isDesktop) {
            this.appService.setLanguage(environment.defaultLanguage);
        }


        if (this.preferences.appToken) {
            this.navCtrl.navigateRoot(['welcome/' + this.preferences.appToken + '/' + this.preferences.ids]);
        } else {
            this.navCtrl.navigateRoot(['welcome']);
        }
    }


    hasPermissions(permissions: string[]): boolean {

        if (!this.authState.value || !permissions.includes(this.authState.value.permissions)) {
            return false;
        }
        return true;
    }

    hasCompanyTypes(companyTypes: string[]): boolean {

        if (!this.authState.value || !companyTypes.includes(this.authState.value.companyType)) {
            return false;
        }
        return true;
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }


}
