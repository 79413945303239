/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type SetPublicOrderStatusErrorResponse = 'ERRORS.GENERAL_FAILURE' | 'ERRORS.STATUS_ERROR' | 'ERRORS.REFUND_ERROR';

export const SetPublicOrderStatusErrorResponse = {
    GENERALFAILURE: 'ERRORS.GENERAL_FAILURE' as SetPublicOrderStatusErrorResponse,
    STATUSERROR: 'ERRORS.STATUS_ERROR' as SetPublicOrderStatusErrorResponse,
    REFUNDERROR: 'ERRORS.REFUND_ERROR' as SetPublicOrderStatusErrorResponse
};