import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {DeliveryAddressPage} from './delivery-address.page';
import {TranslateModule} from '@ngx-translate/core';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MatAutocomplete, MatAutocompleteModule, MatDatepickerModule, MatDialogActions, MatFormFieldControl, MatFormFieldModule, MatIconModule,
    MatInputModule,
    MatLabel, MatNativeDateModule, MatOption,
    MatOptionModule
} from '@angular/material';
import {CustomDateAdapter} from '../../_app/custom-date-adapter.service';

const routes: Routes = [
    {
        path: '',
        component: DeliveryAddressPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatIconModule,
        MatOptionModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        RouterModule.forChild(routes)
    ],

    declarations: [DeliveryAddressPage]
})
export class DeliveryAddressPageModule {
}