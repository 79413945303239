import {Injectable, OnDestroy} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {Subject} from 'rxjs';
import {AuthService} from './auth.service';
import {map, take} from 'rxjs/operators';
import {ToastService} from '../_notification/toast.service';

@Injectable({
    providedIn: 'root'
})
export class GuardService implements CanActivate, OnDestroy {

    private readonly destroy$: Subject<void> = new Subject();

    constructor(private router: Router,
                private toast: ToastService,
                private authService: AuthService) {

    }


    canActivate(route: ActivatedRouteSnapshot) {

        // TODO właczyc guarda ale jest jakis problem gdy go wlacze poniewaz nie przechodzi do agreements po zalogowaniu gdy ma cos odznaczone

        const permissions = route.data.permissions;
        const companyTypes = route.data.companyTypes;

        return this.authService.currentUser.pipe(
            take(1),
            map(user => {

                if (user) {

                    if (!this.authService.hasPermissions(permissions) || !this.authService.hasCompanyTypes(companyTypes)) {
                        this.toast.show('ERRORS.Nie-posiadasz-uprawnien-do-tego-modulu-aplikacji', null, 2000, 'top', 'danger');
                        return false;
                    }

                    return true;
                } else {
                    return this.router.parseUrl('/login');
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
