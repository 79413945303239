import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertController, MenuController, ModalController, NavController, Platform} from '@ionic/angular';
import {OrderMenuService} from '../../../_order/order-menu.service';
import {Order, OrderService, User} from '../../../../api';
import {Preferences} from '../../../_interfaces/preferences';
import {Observable, Subject} from 'rxjs';
import {AppService} from '../../../_app/app.service';
import {take, takeUntil, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../_notification/toast.service';
import {AuthService} from '../../../_auth/auth.service';
import {DatePipe} from '@angular/common';

import {EventService} from '../../../_app/event.service';
import {ContactlessPaymentPage} from '../contactless-payment/contactless-payment.page';
import {MatAccordion} from '@angular/material';


@Component({
    selector: 'app-menu-order',
    templateUrl: './menu-order.component.html',
    styleUrls: ['./menu-order.component.scss'],
})
export class MenuOrderComponent implements OnInit {

    public order: Order;
    public orders: Order[];
    public panelInfoSegment: string;
    public preferences: Preferences;
    public preferences$: Observable<Preferences>;
    public activeOrder: number;
    public selectedLng: string;
    public bookingDate: Date;
    public isPaymentReturnAvailable: boolean;
    public infoPanel = false;

    // public isEditable: boolean;


    private user: User;
    public user$: Observable<User>;

    private readonly destroy$: Subject<void> = new Subject();
    @ViewChild(MatAccordion, {static: false}) accordion: MatAccordion;

    constructor(public navCtrl: NavController,
                public platform: Platform,
                public appService: AppService,
                public toast: ToastService,
                public authService: AuthService,
                public datePipe: DatePipe,
                private modalCtrl: ModalController,
                private eventService: EventService,
                public orderService: OrderService,
                private translate: TranslateService,
                public alertController: AlertController,
                public menuCtrl: MenuController,
                public orderMenuService: OrderMenuService) {


    }

    ngOnInit() {
    }

    loadOrder() {
        this.isPaymentReturnAvailable = false;
        this.panelInfoSegment = 'panelInfoOrder';
        this.orders = this.orderMenuService.getOrder();
        this.selectedLng = this.appService.getSelectedLngIsoCode();
        this.activeOrder = this.orderMenuService.getActiveOrder();
        //    this.isEditable = this.orderMenuService.isEditable;
        this.appService.currentPreferences
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.preferences = res;
            });
        this.authService.currentUser.pipe(
            take(1),
            takeUntil(this.destroy$)).subscribe(res => {
            this.user = res;
        });
        let orderSet = false;
        for (const c of this.orders) {
            if (c.id === this.activeOrder) {
                this.order = c;
                orderSet = true;
                break;
            }
        }
        if (!orderSet) {
            this.order = this.orders[0];
        }
        this.bookingDate = new Date(this.order.bookingDate);


        // sprawdzam czy może jeszcze zarządać zwrotu środków i usunąć zamówienie
        const now = new Date();
        now.setMinutes(now.getMinutes() + 5); // zwiększam o 5 min które jest potrzebne na ewentualny zwrot przez pośrednika tpay  / przelewy24
        if (now < new Date(this.order.bookingClosingTime) && this.order.valuePaid > 0 && this.order.paymentDetails.status === 'PAID' && (this.order.paymentMethod === 'PAYMENT.P24' || this.order.paymentMethod === 'PAYMENT.P24_DELAYED')) {
            this.isPaymentReturnAvailable = true;

        }

    }
    getExpandedPanelHeader() {
        if (this.accordion) {
            if ((this.accordion._headers.find(h => h._isExpanded()))) {
                return true;
            }
        }
        return false;
    }



    closeMenu() {

        this.menuCtrl.close('menuOrder');
        this.menuCtrl.enable(false, 'menuOrder');
        this.destroy$.next();
        this.destroy$.complete();

        this.orderMenuService.setActiveOrder(null);

    }

    displayOrder() {

        this.orderMenuService.displayOrder();
    }

    setActiveOrder(order: Order) {
        this.infoPanel = false;
        this.panelInfoSegment = 'panelInfoOrder';
        this.orderMenuService.setActiveOrder(order.id);
        for (const c of this.orders) {
            if (c.id === order.id) {
                this.order = c;
                break;
            }
        }
    }

    payOrder() {
        this.navCtrl.navigateRoot('payments/' + this.preferences.restaurantId);
        this.menuCtrl.close('menuOrder');
    }

    editOrder() {


        const copyPreferences = JSON.parse(JSON.stringify(this.preferences));
        copyPreferences.menu = {
            date: this.order.bookingDate,
            editOrderId: this.order.id
        };
        this.appService.setPreferences(copyPreferences);


        this.menuCtrl.close('menuOrder');
        this.menuCtrl.enable(false, 'menuOrder');
        this.navCtrl.navigateForward('menu');
    }

    displayInfoPanel() {

        (this.infoPanel) ? this.infoPanel = false : this.infoPanel = true;
        this.panelInfoSegment = 'panelInfoOrder';
    }


    async deleteOrder() {

        let paymentReturnMsg = '';
        if (this.isPaymentReturnAvailable) {
            paymentReturnMsg = '<br><br> Usunięcie spowoduje zwrot środków na Twoje konto z którego dokonywałeś płatności.<br> Zwrot realizowany jest do dwóch dni roboczych.';
        }


        const alert = await this.alertController.create({
            header: this.translate.instant('CART.USUN-KOSZYK'),
            message: (this.order.discount.funding) ? this.translate.instant('CART.Chcesz-usunac-zamowienie-ktore-posiada-dofinansowa') + '<br><br>' + this.translate.instant('CART.Aby-skorzystac-z-dofinansowania-do-innych-rachunko') + paymentReturnMsg : this.translate.instant('CART.Czy-na-pewno-chcesz-usunac-koszyk') + paymentReturnMsg,
            cssClass: 'customAlert ' + (this.order.discount.funding) ? 'smallFontAlert' : '',
            buttons: [
                {
                    text: this.translate.instant('APP.Anuluj'),
                    role: 'cancel',
                    cssClass: 'alertBtnGrey',
                    handler: (obj) => {

                    }
                }, {
                    text: this.translate.instant('APP.Usun').toUpperCase(),
                    cssClass: 'alertBtnDanger',
                    handler: () => {

                        // trzeba dokonac zwrotu srodków
                        if (this.isPaymentReturnAvailable) {
                            this.orderService.paymentReturn(
                                this.user.id,
                                this.order.id,
                                this.order.isLunchMenu,
                                this.order.paymentDetails.paymentId
                            ).subscribe(res => {

                                this.closeMenu();
                                // publikuje event aby przeladowac dane wszedzie tam gdzie jest subkrybcja eventu !
                                this.eventService.publishRefreshAfterDeletedOrder();

                                if (res === 'SUCCESS.REFUNDED_AND_DELETED') {
                                    this.toast.show(this.translate.instant('ORDER.Rezerwacja-zostala-usunieta'), this.translate.instant(res), 4000, 'top', 'success');
                                }
                                else {
                                    this.toast.show(this.translate.instant('ORDER.Wystapil-blad-podczas-usuwania-rezerwacji'), this.translate.instant(res), 4000, 'top');
                                }
                            }, error2 => {

                                if (error2.status !== 401 && error2.status !== 403 && error2.status !== 500) {
                                    if (!error2.error || error2.error === 'ERRORS.GENERAL_FAILURE') {
                                        this.toast.show(this.translate.instant('ORDER.Wystapil-blad-podczas-usuwania-rezerwacji'), this.translate.instant('CALENDAR.Przepraszamy-skontaktuj-sie-z-pomoca-techniczna'), 2000, 'top', 'danger');
                                    } else {
                                        this.toast.show(this.translate.instant('ORDER.Wystapil-blad-podczas-usuwania-rezerwacji'), this.translate.instant(error2.error), 2000, 'top', 'danger');

                                    }
                                } else if (error2.status === 500) {
                                    this.toast.show('(Error 500) ' + this.translate.instant('CART.Skontaktuj-sie-z-pomoca-techniczna'), null, 2000, 'top', 'danger');
                                }

                            });

                        } else {
                            this.orderService.deleteOrder(
                                this.order.isLunchMenu,
                                this.user.id,
                                this.order.shiftId,
                                this.order.restaurantId,
                                this.order.orderConditionsId,
                                this.datePipe.transform(this.order.bookingDate, 'yyyy-MM-dd')
                            ).subscribe(res => {

                                this.closeMenu();

                                // publikuje event aby przeladowac dane wszedzie tam gdzie jest subkrybcja eventu
                                this.eventService.publishRefreshAfterDeletedOrder();
                                this.toast.show(this.translate.instant('ORDER.Rezerwacja-zostala-usunieta'), null, 2000, 'top');
                            }, error2 => {
                                if (error2.status !== 401 && error2.status !== 403 && error2.status !== 500) {

                                    if (!error2.error || error2.error === 'ERRORS.GENERAL_FAILURE') {
                                        this.toast.show(this.translate.instant('ORDER.Wystapil-blad-podczas-usuwania-rezerwacji'), this.translate.instant('CALENDAR.Przepraszamy-skontaktuj-sie-z-pomoca-techniczna'), 2000, 'top', 'danger');
                                    } else {
                                        this.toast.show(this.translate.instant('ORDER.Wystapil-blad-podczas-usuwania-rezerwacji'), this.translate.instant(error2.error), 2000, 'top', 'danger');
                                    }

                                } else if (error2.status === 500) {
                                    this.toast.show('(Error 500) ' + this.translate.instant('CART.Skontaktuj-sie-z-pomoca-techniczna'), null, 2000, 'top', 'danger');
                                }
                            });
                        }


                    }
                }
            ]
        });

        await
            alert.present();

    }


    segmentChanged(ev: any) {
        this.panelInfoSegment = ev.detail.value;
    }


    public async contactLessPaymentModal() {

        const contactLessPaymentModal = await this.modalCtrl.create({
            component: ContactlessPaymentPage,
            animated: true,
            backdropDismiss: false,
            componentProps: {
                userId: this.user.id,
                orderId: this.order.id,
                restaurantId: this.order.restaurantId,
                toPayValue: this.order.valueToPay,
                token: this.preferences.appToken,
                isLunchMenu: this.order.isLunchMenu

            }

        });
        contactLessPaymentModal.onDidDismiss()
            .then((data) => {

            });

        await contactLessPaymentModal.present();
        this.closeMenu();
    }


}
