/**
 * Kiosk
 * Kiosk e-Lunch.pl API - ZAMÓWIENIA PUBLICZNE - nowa wersja 1.0.1
 *
 * OpenAPI spec version: 1.0.1
 * Contact: dev@e-Lunch.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PayOrderErrorResponse = 'ERRORS.GENERAL_FAILURE' | 'ERRORS.REGISTER_ERROR' | 'ERRORS.NO_EMAIL_ERROR' | 'ERRORS.AMOUNT_CHECK_ERROR';

export const PayOrderErrorResponse = {
    GENERALFAILURE: 'ERRORS.GENERAL_FAILURE' as PayOrderErrorResponse,
    REGISTERERROR: 'ERRORS.REGISTER_ERROR' as PayOrderErrorResponse,
    NOEMAILERROR: 'ERRORS.NO_EMAIL_ERROR' as PayOrderErrorResponse,
    AMOUNTCHECKERROR: 'ERRORS.AMOUNT_CHECK_ERROR' as PayOrderErrorResponse
};